import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
} from "../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    backgroundColor: semanticColors.background,
    borderRadius: radius.ms,
    display: "flex",
    gap: spacing.m,
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
  },
  description: {
    color: semanticColors.neutralsMedium,
    marginBottom: `calc(${spacing.xxs} + 1px)`,
  },
  info: {
    alignItems: "flex-end",
    display: "flex",
    flexGrow: 1,
    gap: spacing.s,
    minWidth: spacing.none,
  },
});

export type CounterProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  count?: number | string;
  description?: string;
  rightActions?: KeyedElement | KeyedElement[];
};

export const Counter = forwardRef<any, CounterProps>(
  (
    {
      asChild = false,
      children,
      count = 0,
      description,
      rightActions,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="info" {...stylex.props(styles.info)}>
          <Text size="s" type="headline">
            {count}
          </Text>
          <Text
            overflow="ellipsis"
            size="l"
            type="detail"
            styleXArray={[styles.description]}
          >
            {description}
          </Text>
        </div>,
        rightActions as JSX.Element,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...deepMergeStyleXProps({
          object1: props,
          object2: stylex.props(styles.determinedElement),
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

Counter.displayName = "Counter";
