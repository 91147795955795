"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { I18nMessageClient } from "@packages/i18n";
import type { GetDateByRangeProps } from "@packages/sdk";
import { getDateByRange, useRequestCommunitiesAdminGraph } from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useSearchParamState } from "../../../../../hooks";
import { useErrorNotification } from "../../../../../hooks/useErrorNotification";
import { LineChart, Text } from "../../../../_base";
import { TabGroup, TabGroupItem } from "../../../other";

const prayersCompletedRangeOptions: {
  eventTimeframe: string;
  message: string;
  value: GetDateByRangeProps["range"] | "all";
}[] = [
  {
    eventTimeframe: "1W",
    message: "dashboard_date_filter_1_w",
    value: "weekly",
  },
  {
    eventTimeframe: "1M",
    message: "dashboard_date_filter_1_m",
    value: "monthly",
  },
  {
    eventTimeframe: "3M",
    message: "dashboard_date_filter_3_m",
    value: "quarterly",
  },
  {
    eventTimeframe: "1Y",
    message: "dashboard_date_filter_1_y",
    value: "yearly",
  },
  {
    eventTimeframe: "All",
    message: "dashboard_date_filter_all",
    value: "all",
  },
];

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    height: 240,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export type LineChartPrayersCompletedProps = { id: number };

export const LineChartPrayersCompleted = ({
  id,
}: LineChartPrayersCompletedProps) => {
  const analytics = useAnalytics();
  const [
    prayersCompletedRangeSearchParam,
    setPrayersCompletedRangeSearchParam,
  ] = useSearchParamState({
    defaultValue: "weekly",
    key: "prayersCompletedRange",
  });

  const { query: queryCommunitiesAdminGraphWeekly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "weekly" }),
    });

  const { query: queryCommunitiesAdminGraphMonthly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "monthly" }),
    });

  const { query: queryCommunitiesAdminGraphQuarterly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "quarterly" }),
    });

  const { query: queryCommunitiesAdminGraphYearly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "yearly" }),
    });

  const { query: queryCommunitiesAdminGraphAll } =
    useRequestCommunitiesAdminGraph({ id });

  const getQueryCommunitiesAdminGraph = () => {
    switch (prayersCompletedRangeSearchParam) {
      case "weekly":
        return queryCommunitiesAdminGraphWeekly;
      case "monthly":
        return queryCommunitiesAdminGraphMonthly;
      case "quarterly":
        return queryCommunitiesAdminGraphQuarterly;
      case "yearly":
        return queryCommunitiesAdminGraphYearly;
      default:
        return queryCommunitiesAdminGraphAll;
    }
  };

  const handleRangeOnClick = ({ timeframe }: { timeframe: string }) =>
    analytics.track({
      event: HallowAnalyticsEvent.TappedDashboardChartTimeframe,
      properties: {
        timeframe,
      },
    });

  const queryCommunitiesAdminGraph = getQueryCommunitiesAdminGraph();

  useErrorNotification({
    isError: queryCommunitiesAdminGraph.isError,
  });

  return (
    <div {...stylex.props(styles.base)}>
      <div {...stylex.props(styles.top)}>
        <Text size="xl" type="title">
          <I18nMessageClient message="parish_admin_dashboard_prayers_completed" />
        </Text>
        <TabGroup
          setParentState={setPrayersCompletedRangeSearchParam}
          value={prayersCompletedRangeSearchParam}
          type="single"
        >
          {prayersCompletedRangeOptions.map((prayersCompletedRangeOption) => (
            <TabGroupItem
              key={prayersCompletedRangeOption.value}
              onClick={() =>
                handleRangeOnClick({
                  timeframe: prayersCompletedRangeOption.eventTimeframe,
                })
              }
              parentState={prayersCompletedRangeSearchParam}
              value={prayersCompletedRangeOption.value}
            >
              <I18nMessageClient
                message={prayersCompletedRangeOption.message}
              />
            </TabGroupItem>
          ))}
        </TabGroup>
      </div>
      <LineChart
        data={queryCommunitiesAdminGraph.data?.data}
        loading={queryCommunitiesAdminGraph.isLoading}
      />
    </div>
  );
};

LineChartPrayersCompleted.displayName = "LineChartPrayersCompleted";
