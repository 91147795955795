"use client";

import type { MutableRefObject } from "react";
import { useRef } from "react";

import type { CAROUSEL_GRID_SIZES } from "../constants";
import type { CarouselGridSize } from "../types";
import { useIntersectionObserverRefState } from "./useIntersectionObserverRefState";

export type CarouselApi = {
  canScrollNext: boolean;
  canScrollPrevious: boolean;
  endIsVisible: boolean;
  hoverButtons: boolean;
  pageOffset: boolean;
  refEnd: MutableRefObject<HTMLDivElement>;
  refStart: MutableRefObject<HTMLDivElement>;
  refParent: MutableRefObject<HTMLDivElement>;
  scrollNext: () => void;
  scrollPrevious: () => void;
  size: keyof typeof CAROUSEL_GRID_SIZES | CarouselGridSize;
  startIsVisible: boolean;
};

export type UseCarouselProps = {
  hoverButtons?: boolean;
  pageOffset?: boolean;
  size?: CarouselApi["size"];
};

export const useCarousel = ({
  hoverButtons = true,
  pageOffset = true,
  size = "m",
}: UseCarouselProps): CarouselApi => {
  const refEnd = useRef<HTMLDivElement>(null);
  const refStart = useRef<HTMLDivElement>(null);
  const refParent = useRef<HTMLDivElement>(null);

  const [endIsVisible] = useIntersectionObserverRefState({
    deps: [pageOffset, size],
    ref: refEnd,
  });
  const [startIsVisible] = useIntersectionObserverRefState({
    deps: [pageOffset, size],
    ref: refStart,
  });

  const canScrollPrevious = !startIsVisible;
  const canScrollNext = !endIsVisible;

  const scrollNext = () => {
    refParent?.current?.scrollTo({
      left: refParent.current.scrollLeft + refParent.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollPrevious = () => {
    refParent?.current?.scrollTo({
      left: refParent.current.scrollLeft - refParent.current.clientWidth,
      behavior: "smooth",
    });
  };

  return {
    canScrollNext,
    canScrollPrevious,
    endIsVisible,
    hoverButtons,
    pageOffset,
    refEnd,
    refStart,
    refParent,
    scrollNext,
    scrollPrevious,
    size,
    startIsVisible,
  };
};
