import stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import { forwardRef, useContext } from "react";

import {
  colors,
  numericPercentages,
  numericPixels,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import { Text } from "../../_core";
import type { IconElement } from "../../icons";
import { ChevronRightIcon } from "../../icons";
import { ModalTabContext } from "../ModalTabContainer";

const Element: HallowElement = "div";
const IS_DESKTOP = "@media screen and (min-width: 768px)";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.onOverlayLow,
      ":active": semanticColors.onOverlayLow,
    },
    borderRadius: spacing.m,
    cursor: "pointer",
    display: "flex",
    gap: spacing.m,
    minWidth: {
      [IS_DESKTOP]: numericPixels[300],
      default: numericPercentages[100],
    },
    padding: spacing.m,
    width: numericPercentages[100],
  },
  div: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
  },
  label: {
    color: semanticColors.onOverlayDarkMed,
    textTransform: "uppercase",
  },
});

export type ModalTabProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof Element>>
> & {
  label?: string;
  showChevron?: boolean;
  startIcon?: IconElement;
  value: string;
};

export const ModalTab = forwardRef<HTMLDivElement, ModalTabProps>(
  (
    {
      asChild = false,
      children,
      label,
      showChevron = true,
      startIcon,
      styleXArray,
      value,
      onClick,
      ...props
    },
    ref,
  ) => {
    const tabs = useContext(ModalTabContext);

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement: Element,
    });

    const changeTabs: MouseEventHandler<HTMLDivElement> = (e) => {
      tabs.setValue(value);
      if (onClick) onClick(e);
    };

    return (
      <DeterminedElement
        onClick={changeTabs}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
        ref={ref}
      >
        {startIcon && startIcon}
        <div {...stylex.props(styles.div)}>
          {label && (
            <Text size="l" styleXArray={[styles.label]} type="detail">
              {label}
            </Text>
          )}
          <Text size="m" type="title">
            {children}
          </Text>
        </div>
        {showChevron && <ChevronRightIcon />}
      </DeterminedElement>
    );
  },
);

ModalTab.displayName = "ModalTab";
