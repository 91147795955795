import { I18nMessageClient } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
} from "../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import { Avatar, type AvatarProps } from "../Avatar";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  createdBy: {
    color: semanticColors.neutralsMedium,
  },
  determinedElement: {
    alignItems: "center",
    display: "flex",
    gap: spacing.ms,
    width: spacing.full,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    minWidth: spacing.none,
  },
});

export type AuthorProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  avatarProps?: AvatarProps;
  name?: string;
  rightActions?: KeyedElement | KeyedElement[];
};

export const Author = forwardRef<any, AuthorProps>(
  (
    { asChild = false, avatarProps, children, name, rightActions, ...props },
    ref,
  ) => {
    const isDesktop = useIsDesktopViewport();

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <Avatar key="avatar" size={isDesktop ? 64 : 40} {...avatarProps} />,
        <div key="info" {...stylex.props(styles.info)}>
          <Text
            overflow="ellipsis"
            size={"l"}
            type="detail"
            styleXArray={[styles.createdBy]}
          >
            <I18nMessageClient message="web_created_by" />
          </Text>
          <Text overflow="ellipsis" size={"s"} type="title">
            {name}
          </Text>
        </div>,
        rightActions as JSX.Element,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...deepMergeStyleXProps({
          object1: props,
          object2: stylex.props(styles.determinedElement),
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

Author.displayName = "Author";
