"use client";

import { useAnalytics } from "@packages/analytics/src/lib/useAnalytics";
import { useTranslations } from "@packages/i18n";
import type { FlaggableType, FlagRecord } from "@packages/sdk";
import { useRequestFlagRecord } from "@packages/sdk";
import { Close as RadixUIDialogClose } from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import {
  Button,
  CloseIcon,
  Divider,
  ExportIcon,
  IconButton,
  LockClosedIcon,
  Text,
} from "../../../_base";
import { ButtonLink } from "../../../refactor";
import { TextArea } from "../../forms";
import { FlagRecordReasonRadioInput } from "../FlagRecordReasonRadioInput";
import { FlagRecordSuccess } from "../FlagRecordSuccess";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    width: spacing.full,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: spacing.full,
  },
  footer: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "end",
  },
  footerMobile: {
    background: semanticColors.background,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
    left: 0,
    paddingBottom: spacing.ms,
    paddingHorizontal: spacing.ml,
    position: "fixed",
    width: spacing.full,
  },
  formStepContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    width: { [cssMediaMinWidthDesktop]: "576px" },
  },
  commentSublabel: {
    alignItems: "center",
    display: "inline-flex",
    gap: spacing.s,
  },
  inactive: {
    display: "none",
  },
  buttonContainer: {
    display: "flex",
  },
});

enum FlagRecordFormStep {
  Reason,
  Comment,
  Success,
}

type FlagRecordFormProps = {
  flaggableId: number;
  flaggableType: FlaggableType;
};

export const FlagRecordForm = ({
  flaggableId,
  flaggableType,
}: FlagRecordFormProps) => {
  const analytics = useAnalytics();
  const t = useTranslations();
  const isDesktop = useIsDesktopViewport();
  const [step, setStep] = useState<FlagRecordFormStep>(
    FlagRecordFormStep.Reason,
  );

  const { mutationPost: flagRecordMutation } = useRequestFlagRecord({
    onSuccess: () => setStep(FlagRecordFormStep.Success),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FlagRecord>();

  useEffect(() => {
    if (step === FlagRecordFormStep.Reason) {
      analytics.track({
        event: "Viewed Screen",
        properties: {
          screen_name: "report_reason",
          type: "campaign",
        },
      });
    } else if (step === FlagRecordFormStep.Comment) {
      analytics.track({
        event: "Viewed Screen",
        properties: {
          screen_name: "report_comment",
          type: "campaign",
        },
      });
    }
  }, [step]);

  const onSubmit = (data: FlagRecord) => {
    flagRecordMutation.mutate({
      data: {
        flaggable_id: flaggableId,
        flaggable_type: flaggableType,
        ...data,
      },
    });
  };

  return (
    <form {...stylex.props(styles.form)} onSubmit={handleSubmit(onSubmit)}>
      {step !== FlagRecordFormStep.Success && (
        <div {...stylex.props(styles.header)}>
          <Text type="headline" size="m">
            {t("community_flag_submit_report")}
          </Text>
          {isDesktop && (
            <RadixUIDialogClose asChild>
              <IconButton icon={<CloseIcon />} variant="transparentPrimary" />
            </RadixUIDialogClose>
          )}
        </div>
      )}
      <div
        {...stylex.props(
          styles.formStepContainer,
          step === FlagRecordFormStep.Success && styles.inactive,
        )}
      >
        {step === FlagRecordFormStep.Reason && (
          <>
            <Text type="body" size="m">
              {t("community_flag_familiar_guidelines")}
            </Text>
            <div {...stylex.props(styles.buttonContainer)}>
              <ButtonLink
                size="s"
                linkProps={{
                  href: "https://hallow.com/community-guidelines/",
                  target: "_blank",
                }}
                buttonProps={{ endIcon: <ExportIcon /> }}
              >
                {t("dialog_report_guidelines_button")}
              </ButtonLink>
            </div>
            <Divider />
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <FlagRecordReasonRadioInput
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
            {errors.reason && (
              <span>{t("input_validation_error_required")}</span>
            )}
            <div
              {...stylex.props(isDesktop ? styles.footer : styles.footerMobile)}
            >
              <Button
                onClick={() => setStep(FlagRecordFormStep.Comment)}
                disabled={!watch("reason")}
                size={"l"}
                isFullWidth={!isDesktop}
              >
                {t("community_flag_continue")}
              </Button>
            </div>
          </>
        )}
        {step === FlagRecordFormStep.Comment && (
          <>
            <Text type="body" size="m">
              {t("community_flag_more_detail")}
            </Text>
            <TextArea
              maxLength={255}
              placeholder={t("enter_thoughts_here")}
              rows={4}
              sublabel={
                <div {...stylex.props(styles.commentSublabel)}>
                  <LockClosedIcon.Filled />
                  <Text>{t("community_flag_shared_with_support")}</Text>
                </div>
              }
              {...register("comment")}
            />
            {errors.comment && <p>{errors.comment.message}</p>}
            <div
              {...stylex.props(isDesktop ? styles.footer : styles.footerMobile)}
            >
              <Button
                onClick={() => setStep(FlagRecordFormStep.Reason)}
                variant="neutral"
                size={"l"}
                isFullWidth={!isDesktop}
              >
                {t("accessibility_title_go_back")}
              </Button>
              <Button
                type="submit"
                disabled={flagRecordMutation.isPending}
                size={"l"}
                isFullWidth={!isDesktop}
              >
                {t("community_flag_submit_report_action")}
              </Button>
            </div>
          </>
        )}
      </div>

      {step === FlagRecordFormStep.Success && <FlagRecordSuccess />}
    </form>
  );
};
