"use client";

import {
  redirectToAuth,
  useRequestCampaignUsers,
  useSession,
} from "@packages/sdk";
import { usePathname, useRouter } from "next/navigation";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

import type { HallowElement, HallowElementProps } from "../../../../../types";

const Element: HallowElement = "button";

export type CampaignJoinLeaveButtonProps = HallowElementProps<
  typeof Element
> & {
  campaignId: number;
  campaignName?: string;
  joined: boolean;
};

export const CampaignJoinLeaveButton = forwardRef<
  any,
  CampaignJoinLeaveButtonProps
>(({ campaignId, campaignName, children, joined, onClick, ...props }, ref) => {
  const pathname = usePathname();
  const router = useRouter();

  const { mutationDelete, mutationPost } = useRequestCampaignUsers({
    id: campaignId,
  });
  const { status } = useSession();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (status === "authenticated") {
      if (onClick) onClick(event);

      if (joined) return mutationDelete.mutateAsync();
      else {
        mutationPost.mutate();
        if (pathname !== `/campaigns/${campaignId}`)
          return router.push(`/campaigns/${campaignId}`);
        return;
      }
    }

    if (onClick) onClick(event);
    return redirectToAuth(
      "/onboarding/campaigns",
      campaignName ? { campaign_name: campaignName } : {},
    );
  };

  return (
    <button onClick={handleClick} ref={ref} {...props}>
      {children}
    </button>
  );
});

CampaignJoinLeaveButton.displayName = "CampaignJoinLeaveButton";
