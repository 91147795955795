"use client";

import type { ScrollAreaProps } from "../../../components";
import { ScrollArea } from "../../../components";
import { useScroll } from "./useScroll";

const styles = {
  base: {
    height: "100vh",
    maxHeight: "100vh",
    maxWidth: "100vw",
    width: "100vw",
  },
};

export type ScrollContextContainerProps = ScrollAreaProps;

export const ScrollContextContainer = ({
  children,
  viewportProps,
  ...props
}: ScrollContextContainerProps) => {
  const { viewportRef } = useScroll();

  return (
    <ScrollArea
      viewportProps={{
        ref: viewportRef as ScrollAreaProps["viewportProps"]["ref"],
        ...viewportProps,
      }}
      styleXArray={[styles.base]}
      {...props}
    >
      {children}
    </ScrollArea>
  );
};
