"use client";

import { useRouter } from "next/navigation";

import type { IconButtonProps, IconElement } from "../../../_base";
import { ArrowLeftIcon } from "../../../_base";
import { IconButton } from "../../../_base";

export type BackButtonProps = Omit<IconButtonProps, "icon"> & {
  icon?: IconElement;
};

export const BackButton = ({
  icon = <ArrowLeftIcon />,
  onClick,
  ...props
}: BackButtonProps) => {
  const router = useRouter();
  return (
    <IconButton
      icon={icon}
      onClick={(e) => {
        if (onClick) onClick(e);
        router.back();
      }}
      {...props}
    />
  );
};
