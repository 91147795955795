"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
} from "../../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { HallowImage } from "../../../../blocks";
import type { MaskProps } from "../../../_core";
import { Mask, Tag, Text } from "../../../_core";
import { CheckmarkIcon } from "../../../icons";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  container: {
    height: spacing.full,
    position: "relative",
    width: spacing.full,
  },
  containerContainer: (props: { color: string }) => ({
    background: props.color,
    borderRadius: radius.m,
    height: spacing.full,
    position: "absolute",
    width: spacing.full,
  }),
  determinedElement: {
    borderRadius: radius.m,
    paddingBottom: "127.21712538%",
    position: "relative",
  },
  illustrationImg: {
    "-webkit-user-drag": "none",
    borderRadius: spacing.s,
    height: spacing.xxxl,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    userSelect: "none",
    width: spacing.xxxl,
  },
  imgContainer: {
    paddingBottom: "86.23853211%",
    position: "relative",
  },
  img: (props: { color: string }) => ({
    "-webkit-user-drag": "none",
    borderColor: props.color,
    borderStyle: "solid",
    borderTopLeftRadius: spacing.m,
    borderTopRightRadius: spacing.m,
    borderWidth: 8,
    height: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    userSelect: "none",
    width: spacing.full,
  }),
  infoContainer: {
    bottom: spacing.none,
    color: semanticColors.onColorHighest,
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    paddingBottom: spacing.l,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    position: "absolute",
  },
  infoTopContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  mask: {
    bottom: -1,
    position: "absolute",
  },
  tagJoined: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    maxWidth: 24,
  },
  tagsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
});

export type CampaignLargeMobileProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  activeState?: boolean;
  color: string;
  description: string;
  focusState?: boolean;
  hoverState?: boolean;
  illustrationImgSrc?: string;
  imgSrc?: Images;
  joined?: boolean;
  tags: string[];
  title: string;
};

export const CampaignLargeMobile = forwardRef<any, CampaignLargeMobileProps>(
  (
    {
      activeState = true,
      asChild = false,
      children,
      color,
      description,
      focusState = true,
      hoverState = true,
      illustrationImgSrc,
      imgSrc,
      joined,
      tags,
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div
          key="container"
          {...stylex.props(styles.containerContainer({ color }))}
        >
          <div {...stylex.props(styles.container)}>
            <div {...stylex.props(styles.imgContainer)}>
              <HallowImage
                alt={title}
                src={imgSrc}
                size={"l"}
                {...stylex.props(styles.img({ color }))}
              />
              <Mask
                color={color as MaskProps["color"]}
                direction="to top"
                styleXArray={[styles.mask]}
              />
            </div>
            <div {...stylex.props(styles.infoContainer)}>
              <div {...stylex.props(styles.infoTopContainer)}>
                <img
                  alt={title}
                  src={illustrationImgSrc}
                  {...stylex.props(styles.illustrationImg)}
                />
                <Text overflow="ellipsis" size="xs" type="title">
                  {title}
                </Text>
                <Text lineClamp={2} size="l" type="title">
                  {description}
                </Text>
              </div>
              <div {...stylex.props(styles.tagsContainer)}>
                {tags.map((tag) => (
                  <Tag key={tag} variant="lighten">
                    {tag}
                  </Tag>
                ))}
                {joined && (
                  <Tag variant="lighten" styleXArray={[styles.tagJoined]}>
                    <CheckmarkIcon.SimpleOn size={12} />
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...deepMergeStyleXProps({
          object1: stylex.props(
            styles.determinedElement,
            activeState ? stylesOpacity.active : null,
            activeState ? stylesOpacity.base : null,
            stylesOutline.base,
            focusState ? stylesOutline.focus : null,
            hoverState ? stylesOutline.hover : null,
            activeState && hoverState ? stylesScale.base : null,
            activeState && hoverState ? stylesScale.m : null,
          ),
          object2: props,
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

CampaignLargeMobile.displayName = "CampaignLargeMobile";
