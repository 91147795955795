"use client";

import * as RadixUIToggle from "@radix-ui/react-toggle";
import * as stylex from "@stylexjs/stylex";
import { type ComponentProps, useEffect, useState } from "react";

import {
  colors,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
  shades,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";

const styles = stylex.create({
  base: {
    borderRadius: radius.full,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    boxSizing: "border-box",
    cursor: "pointer",
    transition: "padding 100ms ease-in",
  },
  disabled: {
    cursor: "not-allowed",
    opacity: numericValues["0.5"],
  },
});

const stylesCircle = stylex.create({
  base: {
    backgroundColor: semanticColors.onColorHighest,
    borderColor: semanticColors.higherContrastVeryLow,
    borderRadius: radius.full,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    boxSizing: "border-box",
  },
});

const stylesPressed = stylex.create({
  false: {
    backgroundColor: semanticColors.neutralsLowest,
    borderColor: semanticColors.higherContrastLowest,
  },
  true: {
    backgroundColor: semanticColors.accentMedium,
    borderColor: semanticColors.onColorMedLow,
  },
});

const stylesPressedCircle = stylex.create({
  false: {},
  true: {
    boxShadow: `${numericValues[0]} ${numericPixels[2]} ${numericPixels[4]} ${numericValues[0]} ${shades.shade25}`,
  },
});

const stylesPressedDisabled = stylex.create({
  false: {
    backgroundColor: colors.transparent,
  },
});

const stylesPressedSize = stylex.create({
  falseS: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingRight: `calc(${spacing.m} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
  trueS: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.m} - ${spacing.t})`,
    paddingRight: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
  falseM: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingRight: `calc(${spacing.l} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
  trueM: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.l} - ${spacing.t})`,
    paddingRight: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
  falseL: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingRight: `calc(${spacing.xl} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
  trueL: {
    paddingBottom: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingLeft: `calc(${spacing.xl} - ${spacing.t})`,
    paddingRight: `calc(${spacing.xxs} - ${spacing.t})`,
    paddingTop: `calc(${spacing.xxs} - ${spacing.t})`,
  },
});

const stylesSizeCircle = stylex.create({
  s: {
    height: spacing.m,
    width: spacing.m,
  },
  m: {
    height: spacing.l,
    width: spacing.l,
  },
  l: {
    height: spacing.xl,
    width: spacing.xl,
  },
});

export type ToggleProps = WithStylexArray<
  ComponentProps<typeof RadixUIToggle.Root>
> & {
  size?: "s" | "m" | "l";
};

export const Toggle = ({
  disabled = false,
  pressed = false,
  size = "m",
  styleXArray = [],
  ...props
}: ToggleProps) => {
  const [internalState, setInternalState] = useState(pressed);

  const toggleInternalState = () => setInternalState(!internalState);

  useEffect(() => {
    setInternalState(pressed);
  }, [pressed]);

  return (
    <RadixUIToggle.Root
      disabled={disabled}
      onPressedChange={toggleInternalState}
      pressed={internalState}
      {...props}
      {...stylex.props(
        styles.base,
        disabled ? styles.disabled : null,
        stylesPressed[internalState.toString()],
        stylesPressedSize[`${internalState.toString()}${size.toUpperCase()}`],
        disabled && !internalState ? stylesPressedDisabled.false : null,
        styleXArray,
      )}
    >
      <div
        {...stylex.props(
          stylesCircle.base,
          stylesPressedCircle[internalState.toString()],
          stylesSizeCircle[size],
        )}
      />
    </RadixUIToggle.Root>
  );
};
