export const getDeviceScreen = () => {
  let screenHeight, screenWidth;
  if (typeof screen === "undefined") {
    screenHeight = window.innerHeight;
    screenWidth = window.innerWidth;
  } else {
    screenHeight = screen.availHeight;
    screenWidth = screen.availWidth;
  }

  return {
    formatted: `${screenWidth}x${screenHeight}`,
    screenHeight,
    screenWidth,
  };
};
