"use client";

import { usePathname } from "next/navigation";

import type { ScrollContextContainerProps } from "./ScrollContextContainer";
import { ScrollContextContainer } from "./ScrollContextContainer";
import { ScrollContextProvider } from "./ScrollContextProvider";

export type ScrollProps = ScrollContextContainerProps & {};

export const Scroll = ({ children, ...props }: ScrollProps) => {
  const pathname = usePathname();

  return (
    <ScrollContextProvider>
      <ScrollContextContainer {...props}>{children}</ScrollContextContainer>
    </ScrollContextProvider>
  );
};
