"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useRef } from "react";
import type { DropOptions } from "react-aria";
import { useDrop } from "react-aria";

import {
  numericPercentages,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { MaskProps } from "../../../../components";
import { CloseIcon, ScrollArea, Text } from "../../../../components";
import { useDragDropTouch } from "../../../../hooks";
import { stylexDynamics } from "../../../../lib";
import type {
  HallowElementProps,
  StyleXArray,
  WithStylexArray,
} from "../../../../types";

const IS_DESKTOP = "@media (min-width: 768px)";

const container = stylex.create({
  default: {
    display: "grid",
    gridTemplateAreas: '"current" "queue" "controls"',
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content minmax(0, 1fr) min-content",
    height: numericPercentages[100],
    maxWidth: "640px",
    overflow: "hidden",
    width: "calc(100vw - 48px)", // 24px per side on the media player
  },
  scrollArea: {
    height: numericPercentages[100],
  },
});

const title = stylex.create({
  default: {
    color: semanticColors.onColorVeryHigh,
    margin: 0,
    padding: spacing.s,
    position: "sticky",
    textTransform: "uppercase",
    top: 0,
    zIndex: 1,
  },
});

const list = stylex.create({
  default: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    width: {
      [IS_DESKTOP]: numericPercentages[100],
      default: "calc(100vw - 48px)",
    },
    zIndex: 0,
  },
  endList: {
    paddingBottom: "calc(100% - 96px)", // 84px = height of one queue item, 12px = generous gap
  },
  container: {
    position: "relative",
    width: {
      [IS_DESKTOP]: numericPercentages[100],
      default: "calc(100vw - 48px)",
    },
  },
  emptyText: {
    color: semanticColors.onColorVeryHigh,
    display: "flex",
    height: "60vh",
    placeContent: "center",
    placeItems: "center",
    width: numericPercentages[100],
  },
});

const deleter = stylex.create({
  default: {
    display: "flex",
    height: numericPercentages[100],
    left: 0,
    placeContent: "center",
    placeItems: "center",
    position: "absolute",
    top: 0,
    width: "10%",
  },
});

const controls = stylex.create({
  default: {
    columnGap: spacing.s,
    display: "grid",
    gridArea: "controls",
    gridAutoColumns: "min-content",
    gridTemplateAreas: '"back play forward"',
    gridTemplateRows: "1fr",
    placeContent: "center",
    placeItems: "center",
  },
});

type QueueSection = {
  title: string;
  items: ReactNode[];
};

export type UIQueueProps = HallowElementProps<"div"> &
  WithStylexArray<{
    past: QueueSection;
    future: QueueSection;
    current: ReactNode;
    onDelete?: DropOptions["onDrop"];
    isDragging?: boolean;
    titleBgStyleXArray?: StyleXArray;
    masksColor?: MaskProps["color"];
  }>;

export const UIQueue = forwardRef<HTMLDivElement, UIQueueProps>(
  (
    {
      past,
      current,
      future,
      isDragging,
      onDelete,
      styleXArray,
      children,
      titleBgStyleXArray,
      masksColor,
      ...props
    },
    ref,
  ) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const midRef = useRef<HTMLHeadingElement>(null);
    const deleteRef = useRef<HTMLDivElement>(null);
    const { dropProps } = useDrop({ onDrop: onDelete, ref: deleteRef });
    const t = useTranslations();
    useDragDropTouch();

    const handleDragScroll: MouseEventHandler = useCallback(
      (e) => {
        const box = e.currentTarget.getBoundingClientRect();

        if (e.clientY > box.bottom - Math.min(100, box.height / 10)) {
          requestAnimationFrame(() => {
            scrollRef.current?.scrollBy({
              top: Math.min(25, box.height / 40),
              behavior: "instant",
            });
          });
        } else if (e.clientY < box.top + Math.min(50, box.height / 20)) {
          requestAnimationFrame(() => {
            scrollRef.current?.scrollBy({
              top: -Math.min(25, box.height / 40),
              behavior: "instant",
            });
          });
        }
      },
      [scrollRef?.current],
    );

    useEffect(() => {
      requestAnimationFrame(() => {
        if (scrollRef.current)
          scrollRef.current.scrollTop = midRef.current?.offsetTop ?? 0;
      });
    }, [scrollRef.current, midRef.current]);

    return (
      <div
        ref={ref}
        {...stylex.props(container.default, styleXArray)}
        {...props}
      >
        <ul {...stylex.props(list.default)}>{current}</ul>
        <div {...stylex.props(list.container)} onDragOver={handleDragScroll}>
          <ScrollArea
            viewportProps={{ ref: scrollRef }}
            horizontal={false}
            masksSize="xs"
            maskBottom
            masksColor={masksColor}
            styleXArray={[container.scrollArea]}
          >
            {past.items?.length ? (
              <>
                <Text
                  as={"h2"}
                  type={"title"}
                  size={"s"}
                  styleXArray={[title.default, titleBgStyleXArray]}
                >
                  {past.title}
                </Text>
                <ul {...stylex.props(list.default)}>{past.items}</ul>
              </>
            ) : null}
            <Text
              ref={midRef}
              as={"h2"}
              type={"title"}
              size={"s"}
              styleXArray={[title.default, titleBgStyleXArray]}
            >
              {future.title}
            </Text>
            {future.items?.length ? (
              <ul {...stylex.props(list.default, list.endList)}>
                {future.items}
              </ul>
            ) : (
              <Text
                as={"div"}
                type={"body"}
                size={"l"}
                styleXArray={[list.emptyText]}
              >
                {t("queue_empty_state_web")}
              </Text>
            )}
          </ScrollArea>
          {isDragging ? (
            <div
              ref={deleteRef}
              {...stylex.props(
                deleter.default,
                stylexDynamics.backgroundGradient({
                  direction: "to left",
                  red: 180,
                  green: 30,
                  blue: 30,
                }),
                stylexDynamics.mask({
                  directions: ["to top", "to bottom"],
                  spreads: [50, 50],
                }),
              )}
              {...dropProps}
            >
              <CloseIcon />
            </div>
          ) : null}
        </div>
        <div {...stylex.props(controls.default)}>{children}</div>
      </div>
    );
  },
);

UIQueue.displayName = "UIQueue";
