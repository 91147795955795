"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef, useEffect, useMemo, useRef } from "react";

import { themes } from "../../global/stylex/themes.stylex";
import { semanticColors } from "../../global/stylex/vars.stylex";
import { useLocalRef } from "../hooks";
import { END_OF_BROWSER_ASYNC_QUEUE } from "../lib";
import type {
  HallowElement,
  HallowElementProps,
  Theme,
  WithAs,
  WithAsChild,
  WithStylexArray,
} from "../types";
import { determineElementFromAsChild } from "../utils";

const Element: HallowElement = "div";

const styles = stylex.create({
  body: {
    background: semanticColors.background,
    color: semanticColors.primary,
    padding: 0,
  },
  overrideBackgroundColor: (backgroundColor) => ({
    backgroundColor,
  }),
});

export type ThemeContainerProps = WithAsChild<
  WithAs<WithStylexArray<HallowElementProps<typeof Element>>, "div" | "body">
> & {
  overrideBackgroundColor?: string;
  theme: Theme;
  dynamic?: boolean;
};

export const ThemeContainer = forwardRef<HTMLDivElement, ThemeContainerProps>(
  (
    {
      asChild = false,
      as = Element,
      children,
      overrideBackgroundColor,
      styleXArray,
      theme,
      dynamic = false,
      ...props
    },
    ref,
  ) => {
    const elementRef = useLocalRef(ref);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const style = useMemo<ReturnType<typeof stylex.props>>(
      () =>
        stylex.props(
          themes[theme],
          styles.body,
          overrideBackgroundColor
            ? styles.overrideBackgroundColor(overrideBackgroundColor)
            : null,
          styleXArray,
        ),
      [theme, overrideBackgroundColor, styleXArray],
    );
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement: as,
    });

    const assignStyle = (s: typeof style) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (elementRef.current) {
        for (const [prop, val] of Object.entries(s)) {
          elementRef.current[prop] = val;
        }
      } else {
        timeoutRef.current = setTimeout(
          () => assignStyle(s),
          END_OF_BROWSER_ASYNC_QUEUE,
        );
      }
    };

    useEffect(() => {
      if (dynamic) assignStyle(style);
    }, [style, dynamic]);

    return (
      <DeterminedElement
        ref={elementRef}
        {...(dynamic ? {} : style)}
        {...props}
      >
        {children}
      </DeterminedElement>
    );
  },
);

ThemeContainer.displayName = "ThemeContainer";
