"use client";

import { useStylex } from "../../../../../hooks";
import type { HallowElement, HallowElementProps } from "../../../../../types";

const hallowElement: HallowElement = "svg";

export type DotProps = HallowElementProps<typeof hallowElement>;

export const Dot = ({ x, y, ...props }: DotProps) => {
  const { getStylexColorVarValue } = useStylex();

  const computedX = x ? (typeof x === "string" ? parseInt(x) : x) - 6 : 0;
  const computedY = y ? (typeof y === "string" ? parseInt(y) : y) - 6 : 0;

  return (
    <svg
      height={12}
      width={12}
      x={computedX}
      xmlns="http://www.w3.org/2000/svg"
      y={computedY}
      {...props}
    >
      <circle
        cx={6}
        cy={6}
        fill={getStylexColorVarValue({ color: "background" })}
        r={6}
      />
      <circle
        cx={6}
        cy={6}
        fill={getStylexColorVarValue({ color: "accentMedium" })}
        r={4}
      />
    </svg>
  );
};

Dot.displayName = "Dot";
