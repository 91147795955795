"use client";

import { useTranslations } from "@packages/i18n";
import { getMinutesAndSeconds } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useMemo } from "react";

import { semanticColors } from "../../../../../global/stylex/vars.stylex";
import { Text } from "../../../../components";
import type { WithStylexArray } from "../../../../types";

export type TimeProps = WithStylexArray<{
  timeS: number;
  negative?: boolean;
  size?: "s" | "m" | "l" | "xl";
}>;

const styles = stylex.create({
  number: {
    color: semanticColors.onColorVeryHigh,
    fontFeatureSettings: "tnum",
    fontVariantNumeric: "tabular-nums",
  },
});

const getTextSize = (size: TimeProps["size"]) => {
  switch (size) {
    case "l":
      return "s";
    case "xl":
      return "s";
    case "m":
      return "l";
    case "s":
    default:
      return "m";
  }
};

const getTextType = (size: TimeProps["size"]) => {
  switch (size) {
    case "l":
      return "body";
    case "xl":
      return "display";
    case "s":
    case "m":
    default:
      return "detail";
  }
};

export const Time = ({
  timeS,
  negative = false,
  size = "m",
  styleXArray,
}: TimeProps) => {
  const t = useTranslations();

  const displayTime = useMemo(() => {
    const [minutes, seconds] = getMinutesAndSeconds({ time: timeS });

    if (negative) {
      return t("web_audio_player_time_remaining", {
        "0": minutes,
        "1": seconds,
      });
    }

    return t("web_audio_player_time_elapsed", { "0": minutes, "1": seconds });
  }, [timeS]);

  const textSize = getTextSize(size);
  const textType = getTextType(size);

  return (
    <Text
      size={textSize}
      type={textType}
      styleXArray={[styles.number, styleXArray]}
    >
      {displayTime}
    </Text>
  );
};
