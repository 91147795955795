"use client";

import { useParams, usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

import { useAnalytics } from "../lib";
import { saveUrlQueries } from "../utils/saveUrlQueries";

// TODO: Add all screens with 'Viewed Screen' event here
// TODO: Move to separate file
export const getScreen = (pathname, params = null) => {
  if (/^\/campaigns\/\d+/.test(pathname))
    return {
      screenName: "campaign_detail",
      properties: {
        campaign: params?.id,
      },
    };
  if (/^\/pages\/campaigns/.test(pathname)) {
    return {
      screenName: "page_details",
      // TODO: temp hard coded, should be value from the backend (currently 83)
      // Figure out how to add data from trustedendpoint to this funtion
      // move to server-side file/function and use trusted endpoints?
      properties: {
        page_id: -1,
        page_name: params?.page,
      },
    };
  }
  return {};
};

export function RouteChangeListener() {
  const analytics = useAnalytics();
  const params = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const screen = getScreen(pathname, params);

    if (screen?.screenName) {
      analytics.track({
        event: "Viewed Screen",
        properties: {
          screen_name: screen.screenName,
          ...screen.properties,
        },
      });
    }
  }, [pathname]);

  useEffect(() => {
    saveUrlQueries(searchParams);
  }, [searchParams]);

  return <></>;
}
