"use client";

import { usePlayer } from "@packages/media";
import { cloneElement, forwardRef } from "react";

import type { ButtonProps, IconElement } from "../../../../../components";
import { Button, IconButton } from "../../../../../components";
import type { WithStylexArray } from "../../../../../types";

export type MediaButtonProps = Omit<ButtonProps, "size" | "color"> &
  WithStylexArray<{
    largeIcon: IconElement;
    smallIcon?: IconElement;
    size?: "s" | "l" | null;
  }>;

export const MediaButton = forwardRef<HTMLButtonElement, MediaButtonProps>(
  (
    {
      largeIcon,
      smallIcon = largeIcon,
      styleXArray,
      onClick,
      size: buttonSize = null,
      ...props
    },
    ref,
  ) => {
    const player = usePlayer();

    if (
      (player?.display === "expanded" && buttonSize !== "s") ||
      buttonSize === "l"
    ) {
      return (
        <Button
          variant={"transparentWhite"}
          onClick={onClick}
          styleXArray={styleXArray}
          ref={ref}
          {...props}
        >
          {cloneElement(largeIcon, { size: 80 })}
        </Button>
      );
    }

    return (
      <IconButton
        icon={smallIcon}
        onClick={onClick}
        variant={"transparentWhite"}
        styleXArray={styleXArray}
        ref={ref}
        {...props}
      />
    );
  },
);

MediaButton.displayName = "MediaButton";
