"use client";

import type { Boolean, LSFontFamily, LSTheme } from "@packages/sdk";
import { genericBooleanSchema } from "@packages/sdk";
import { lsFontFamilySchema } from "@packages/sdk";
import { getValidatedCookiesHlwConsent, lsThemeSchema } from "@packages/sdk";
import { getValidatedLocalStorageValue } from "@packages/sdk/src/lib/utils/getValidatedLocalStorageValue";
import {
  OVERRIDE_FONT_STYLE_KEY,
  OVERRIDE_LETTER_SPACING_KEY,
  OVERRIDE_WORD_SPACING_KEY,
  PREFERRED_THEME_KEY,
} from "@packages/ui";
import { useContext, useEffect, useState } from "react";
import * as device from "react-device-detect";

import {
  Consent,
  deviceDetectToDeviceType,
  getDeviceScreen,
  getDeviceUser,
  getScreen,
} from "..";
import { consentContext } from "../components/ConsentProvider/ConsentProvider";
import { alignAnonymousids } from "./alignAnonymousIds";
import { analytics } from "./segment";

declare global {
  interface Window {
    analytics: any; // add analytics to access Segment globally
    hallowAnonymousUserId: string; // add hallowAnonymousUserId to pass anon id across subdomains
  }
}

export type AnalyticsEvent = {
  event: string;
  properties?: any;
  options?: any;
};

export type AnalyticsHook = {
  track: (event: AnalyticsEvent) => Promise<void>;
  logout: () => void;
  consent: number[] | null;
};

// TODO: Hook works with analytics loaded
// TODO: Hook works with analytics NOT loaded

export const useAnalytics = (): AnalyticsHook => {
  const [segmentInitialized, setSegmentInitialized] = useState<boolean>(false);
  const [eventQueue, setEventQueue] = useState<Array<AnalyticsEvent>>([]);
  const { consent } = useContext(consentContext);

  const searchParams = new URLSearchParams();
  const consentCookie = getValidatedCookiesHlwConsent();

  useEffect(() => {
    checkSegmentInit();
  }, [analytics]);

  const eventProperties = () => {
    const { screenHeight, screenWidth } = getDeviceScreen();

    return {
      browser: getDeviceUser(),
      device_type: deviceDetectToDeviceType(device.deviceType),
      mobile: device.isMobile,
      platform: "web",
      screen_height: screenHeight,
      screen_name: getScreen(
        typeof window !== undefined ? window.location.pathname : "",
      )?.screenName,
      screen_width: screenWidth,
      slug: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      utm_campaign:
        searchParams.get("utm_campaign") ??
        sessionStorage.getItem("utm_campaign") ??
        null,
      utm_medium:
        searchParams.get("utm_medium") ??
        sessionStorage.getItem("utm_medium") ??
        null,
      utm_source:
        searchParams.get("utm_source") ??
        sessionStorage.getItem("utm_source") ??
        null,
      ...getAccessibilitySettings(),
    };
  };

  const getAccessibilitySettings = () => {
    const theme = getValidatedLocalStorageValue<LSTheme>({
      key: PREFERRED_THEME_KEY,
      schema: lsThemeSchema,
      defaultValue: "match",
    });
    const fontStyle = getValidatedLocalStorageValue<LSFontFamily>({
      key: OVERRIDE_FONT_STYLE_KEY,
      schema: lsFontFamilySchema,
      defaultValue: "normal",
    });
    const wordSpacing = getValidatedLocalStorageValue<Boolean>({
      key: OVERRIDE_WORD_SPACING_KEY,
      schema: genericBooleanSchema,
      defaultValue: false,
    });
    const letterSpacing = getValidatedLocalStorageValue<Boolean>({
      key: OVERRIDE_LETTER_SPACING_KEY,
      schema: genericBooleanSchema,
      defaultValue: false,
    });

    return {
      theme,
      font_style: fontStyle,
      letter_spacing: letterSpacing ? "expanded" : "default",
      word_spacing: wordSpacing ? "expanded" : "default",
    };
  };

  const checkSegmentInit = () => {
    if (!segmentInitialized) {
      if (!analytics) return false;

      window.analytics = analytics;
      analytics.setAnonymousId(alignAnonymousids());

      setSegmentInitialized(true);

      eventQueue.forEach((event) => {
        setEventQueue([eventQueue.shift()]);
        track(event);
      });
    }

    return true;
  };

  const track = async (event: AnalyticsEvent) => {
    if (
      !consentCookie ||
      (typeof consentCookie?.includes === "function" &&
        consentCookie?.includes(Consent.ANALYTICS))
    ) {
      const isInit = checkSegmentInit();

      const fullEvent = {
        event: event.event,
        properties: {
          ...eventProperties(),
          ...event.properties,
        },
      };

      if (isInit) analytics.track(fullEvent.event, fullEvent.properties);
      else setEventQueue([...eventQueue, fullEvent]);
    }
  };

  const logout = () => {
    if (!window?.analytics) return;
    window.analytics.reset();
  };

  return { track, logout, consent };
};
