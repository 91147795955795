import {
  getValidatedCookiesAnonSegmentId,
  getValidatedCookiesLdGuestKey,
  uuid,
} from "@packages/sdk";

export const alignAnonymousids = () => {
  if (typeof window !== undefined && window?.hallowAnonymousUserId)
    return window.hallowAnonymousUserId;

  const anonUserIdCookie = getValidatedCookiesAnonSegmentId();
  const lDGuestKeyCookie = getValidatedCookiesLdGuestKey();
  const sessionStorageId =
    typeof sessionStorage !== "undefined"
      ? sessionStorage.getItem("LDGuestKey")
      : null;

  const anonId = (
    anonUserIdCookie ??
    lDGuestKeyCookie ??
    sessionStorageId ??
    uuid()
  ).replaceAll('"', "");

  window.hallowAnonymousUserId = anonId;
  return anonId;
};
