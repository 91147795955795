"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import type { HallowElement, HallowElementProps } from "../../../../types";
import { deepMergeStyleXProps } from "../../../../utils";
import {
  FacebookIcon,
  IMessageIcon,
  InstagramIcon,
  LinkIcon,
  MailIcon,
  MoreIcon,
  WhatsAppIcon,
  XIcon,
} from "../../../_base";
import type { SocialIconButtonProps } from "../SocialIconButton";
import { SocialIconButton } from "../SocialIconButton";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  socialContainer: {
    "-webkit-overflow-scrolling": "touch",
    display: "flex",
    gap: {
      [cssMediaMinWidthTablet]: spacing.m,
      default: spacing.s,
    },
    overflowX: "auto",
    overflowY: "visible",
    scrollBehavior: "smooth",
    scrollPaddingLeft: {
      [cssMediaMinWidthTablet]: spacing.l,
      default: spacing.m,
    },
    scrollPaddingRight: {
      [cssMediaMinWidthTablet]: spacing.l,
      default: spacing.m,
    },
    scrollSnapType: "x mandatory",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  socialContainerItem: {
    scrollSnapAlign: "start",
  },
});

export type ShareButtonsProps = HallowElementProps<typeof hallowElement> & {
  description?: string;
  imgSrc?: string;
  link?: string;
  title: string;
  type: string;
};

export const ShareButtons = ({
  description,
  imgSrc,
  link,
  title,
  type,
  ...props
}: ShareButtonsProps) => {
  const t = useTranslations();

  const isDesktop = useIsDesktopViewport();

  const url = link ?? window.location.href;

  const copyShareUrl = () => navigator.clipboard.writeText(url);

  const emailShareUrl = () =>
    `mailto:?subject=${t("web_share_email_subject")}&body=${encodeURIComponent(`${title} ${url}`)}`;

  const openShareSheet = async (filesArray?: File[]) => {
    const shareData: ShareData = {
      title: title,
      url: url,
    };

    if (filesArray) shareData.files = filesArray;
    else shareData.text = description;

    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator.share(shareData).catch(console.error);
    }
  };

  const facebookShareUrl = () =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&src=sdkpreparse%22%20class=%22fb-xfbml-parse-ignore`;

  const iMessageShareUrl = () =>
    `sms://?body=${encodeURIComponent(`${title} ${url}`)}`;

  const instagramShareUrl = async () => {
    if (imgSrc) {
      const fetchedImage = await fetch(imgSrc);
      const blobImage = await fetchedImage.blob();
      const filesArray = [
        new File([blobImage], title, {
          type: "image/png",
          lastModified: Date.now(),
        }),
      ];

      openShareSheet(filesArray);
    } else {
      openShareSheet();
    }
  };

  const messengerShareUrl = () => "";
  // `fb-messenger://share/?link=${encodeURIComponent(url)}` // for mobile, need Facebook app id
  // `http://www.facebook.com/dialog/send?app_id=${appId}&link=${url}&redirect_uri=${url}` // for desktop, need Facebook app id

  const whatsAppShareUrl = () =>
    `https://wa.me/?text=${encodeURIComponent(`${title} ${url}`)}`;

  const xShareUrl = () =>
    `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}&via=HallowApp`;

  const desktopSocials: SocialIconButtonProps[] = [
    {
      action: "facebook_post",
      title: "Facebook",
      icon: <FacebookIcon />,
      url: facebookShareUrl(),
    },
    {
      action: "instagram",
      title: t("instagram_share_messages"),
      icon: <IMessageIcon />,
      url: iMessageShareUrl(),
    },
    {
      action: "twitter",
      title: "X",
      icon: <XIcon />,
      url: xShareUrl(),
    },
    {
      action: "whats_app",
      title: "WhatsApp",
      icon: <WhatsAppIcon />,
      url: whatsAppShareUrl(),
    },
    {
      action: "copy",
      title: t("herald_dashboard_copy_link_button"),
      icon: <LinkIcon />,
      isExternalIcon: false,
      onClick: () => copyShareUrl(),
    },
    {
      action: "email",
      title: t("email_input_toolbar_title"),
      icon: <MailIcon />,
      isExternalIcon: false,
      url: emailShareUrl(),
    },
  ];

  const mobileSocials: SocialIconButtonProps[] = [
    {
      action: "instagram",
      title: t("instagram_share_stories"),
      icon: <InstagramIcon />,
      onClick: () => instagramShareUrl(),
    },
    {
      action: "facebook_post",
      title: "Facebook",
      icon: <FacebookIcon />,
      url: facebookShareUrl(),
    },
    {
      action: "sms",
      title: t("instagram_share_messages"),
      icon: <IMessageIcon />,
      url: iMessageShareUrl(),
    },
    {
      action: "twitter",
      title: "X",
      icon: <XIcon />,
      url: xShareUrl(),
    },
    {
      action: "whats_app",
      title: "WhatsApp",
      icon: <WhatsAppIcon />,
      url: whatsAppShareUrl(),
    },
    {
      action: "copy",
      title: t("herald_dashboard_copy_link_button"),
      icon: <LinkIcon />,
      isExternalIcon: false,
      onClick: () => copyShareUrl(),
    },
    {
      action: "more_menu",
      title: t("expandable_text_more"),
      icon: <MoreIcon />,
      isExternalIcon: false,
      onClick: () => openShareSheet(),
    },
  ];

  return (
    <div
      {...deepMergeStyleXProps({
        object1: stylex.props(styles.socialContainer),
        object2: props,
      })}
    >
      {(isDesktop ? desktopSocials : mobileSocials).map((social) => (
        <div key={social.title} {...stylex.props(styles.socialContainerItem)}>
          <SocialIconButton
            action={social.action}
            title={social.title}
            icon={social.icon}
            isExternalIcon={social.isExternalIcon}
            onClick={social?.onClick}
            type={type}
            url={social?.url}
          />
        </div>
      ))}
    </div>
  );
};

ShareButtons.displayName = "ShareButtons";
