import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { StackProps } from "../../../../blocks";
import { Container, Stack } from "../../../../blocks";
import { Text } from "../../../_core";

const styles = stylex.create({
  stack: {
    gap: spacing.none,
  },
});

export type SectionsProps = StackProps & {
  title?: string;
};

export const Sections = forwardRef<any, SectionsProps>(
  ({ children, styleXArray, title, ...props }, ref) => (
    <Stack ref={ref} styleXArray={[styles.stack, styleXArray]} {...props}>
      {title && (
        <Container paddingX paddingY>
          <Text as="h1" overflow="ellipsis" size="s" type="display">
            {title}
          </Text>
        </Container>
      )}
      {children}
    </Stack>
  ),
);

Sections.displayName = "Sections";
