"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";

import { colors, spacing } from "../../../../../global/stylex/vars.stylex";
import { Text } from "../Text";

const styles = stylex.create({
  button: {
    backgroundColor: colors.transparent,
    border: "none",
    color: "inherit",
    cursor: "pointer",
    margin: spacing.none,
    padding: spacing.none,
  },
});

export const FooterCookies = () => {
  const t = useTranslations();
  return (
    <Text asChild size="xl" type="detail">
      <button
        {...stylex.props(styles.button)}
        onClick={() =>
          (window.location.href = `${window.location.href.split("#")[0]}#cookie-manager`)
        }
      >
        {t("web_manage_cookies")}
      </button>
    </Text>
  );
};
