import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { CardProps } from "../../../../refactor";
import { Card } from "../../../../refactor";
import type { TriviaScoreProps } from "../TriviaScore";
import { TriviaScore } from "../TriviaScore";

const styles = stylex.create({
  card: {
    paddingBottom: "96px",
    paddingTop: `calc(${spacing.full} - ${spacing.xs})`,
  },
  imgContainer: (props: { color: TriviaMediumProps["color"] }) => ({
    alignItems: "center",
    backgroundColor: props.color,
    display: "flex",
    justifyContent: "center",
  }),
  infoContainer: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  mask: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  triviaScore: {
    paddingBottom: spacing.m,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
});

export type TriviaMediumProps = CardProps &
  Pick<TriviaScoreProps, "questions" | "summary">;

export const TriviaMedium = ({
  centerActions,
  color,
  description,
  imgContainerStyleXArray,
  imgSrc,
  infoContainerStyleXArray,
  maskProps,
  questions,
  styleXArray,
  summary,
  title,
  ...props
}: TriviaMediumProps) => {
  const t = useTranslations();
  return (
    <Card
      centerActions={questions && summary ? null : centerActions}
      color={color}
      description={description ?? t("trivia_sub_title")}
      imgContainerProps={{
        children:
          questions && summary ? (
            <TriviaScore
              color={color}
              questions={questions}
              styleXArray={[styles.triviaScore]}
              summary={summary}
            />
          ) : null,
      }}
      imgContainerStyleXArray={[
        styles.imgContainer({ color }),
        imgContainerStyleXArray,
      ]}
      imgSrc={questions && summary ? null : imgSrc}
      infoContainerStyleXArray={[
        styles.infoContainer,
        infoContainerStyleXArray,
      ]}
      maskProps={{
        ...maskProps,
        styleXArray: [styles.mask, maskProps?.styleXArray],
      }}
      rightActions={questions && summary ? centerActions : null}
      styleXArray={[styles.card, styleXArray]}
      title={title ?? t("trivia_section_title")}
      {...props}
    />
  );
};
