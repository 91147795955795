import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

const hallowElement: HallowElement = "ul";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
});

export type ListProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
>;

export const List = forwardRef<any, ListProps>(
  ({ asChild = false, children, styleXArray, ...props }, ref) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
      >
        {children}
      </DeterminedElement>
    );
  },
);

List.displayName = "List";
