"use client";

import { useTranslations } from "@packages/i18n";
import type { Images, Prayer } from "@packages/sdk";
import stylex from "@stylexjs/stylex";
import type { MouseEvent } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useFavoritePrayerAction } from "../../../../../hooks";
import type { WithStylexArray } from "../../../../../types";
import { AddToQueue } from "../../../../blocks";
import { IconButton } from "../../../_core";
import { MoreIcon, StarIcon } from "../../../icons";
import { DropdownMenu, DropdownMenuItem } from "../../../modals";
import ContentMoreMenuHeader from "./ContentMoreMenuHeader";

const styles = stylex.create({
  dropdownMenuContent: { paddingTop: spacing.l },
});

type PrayerMoreMenuProps = WithStylexArray<{
  prayer: Pick<
    Prayer,
    "id" | "is_favorite" | "title" | "label_desc" | "lengths" | "has_access"
  >;
  imageSrc?: Images;
}>;

export const PrayerMoreMenu = ({ prayer, imageSrc }: PrayerMoreMenuProps) => {
  const updateFavorite = useFavoritePrayerAction({
    id: prayer.id,
    has_access: prayer.has_access,
  });
  const t = useTranslations();

  const onClickFavorite = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    updateFavorite();
  };

  return (
    <DropdownMenu
      trigger={
        <IconButton
          icon={<MoreIcon />}
          onClick={(e) => {
            // The stopImmediatePropagation call prevents next top loader from firing unnecessarily
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
          }}
          variant="transparentPrimary"
        />
      }
      align="end"
      contentStyleXArray={[styles.dropdownMenuContent]}
      modal={false}
    >
      <ContentMoreMenuHeader
        metadata={prayer.lengths}
        imageSrc={imageSrc}
        subtitle={prayer.label_desc}
        title={prayer.title}
      />
      <DropdownMenuItem
        onClick={onClickFavorite}
        startIcon={prayer.is_favorite ? <StarIcon.Off /> : <StarIcon.Outline />}
      >
        {prayer.is_favorite
          ? t("dialog_prayer_options_unfavorite")
          : t("dialog_prayer_options_favorite")}
      </DropdownMenuItem>
      <AddToQueue id={prayer.id} type={"prayer"} />
    </DropdownMenu>
  );
};
