"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { differenceInDays } from "date-fns";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useCountdown } from "../../../../../hooks";
import type { CardProps } from "../../../../refactor";
import { Card } from "../../../../refactor";

const styles = stylex.create({
  card: {
    paddingBottom: "96px",
    paddingTop: `calc(${spacing.full} - ${spacing.xs})`,
  },
  infoContainer: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  mask: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
});

export type ChallengeMediumProps = CardProps & {
  availableAt?: string;
  endsAt?: string;
};

export const ChallengeMedium = ({
  centerActions,
  availableAt,
  description,
  endsAt,
  imgSrc,
  infoContainerStyleXArray,
  maskProps,
  styleXArray,
  tags = [],
  title,
  ...props
}: ChallengeMediumProps) => {
  const t = useTranslations();

  const countdown = useCountdown({
    targetDate: availableAt,
    format: "default",
  });
  let dateTag: null | string = null;

  if (availableAt && endsAt) {
    const date = new Date();
    const ended = date > new Date(endsAt);
    const started = date > new Date(availableAt);

    dateTag = ended
      ? t("challenge_tile_event_over")
      : started
        ? t("challenge_tile_days_left_label", {
            challenge_tile_days_left_label: differenceInDays(
              new Date(endsAt),
              date,
            ),
          })
        : t("challenge_tile_starts_in", {
            0: countdown,
          });
  }

  return (
    <Card
      description={description}
      imgSrc={imgSrc}
      infoContainerStyleXArray={[
        styles.infoContainer,
        infoContainerStyleXArray,
      ]}
      maskProps={{
        ...maskProps,
        styleXArray: [styles.mask, maskProps?.styleXArray],
      }}
      styleXArray={[styles.card, styleXArray]}
      centerActions={centerActions}
      tags={dateTag ? [...tags, dateTag] : tags}
      title={title}
      {...props}
    />
  );
};
