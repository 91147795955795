import * as stylex from "@stylexjs/stylex";
import type { HTMLAttributes } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";

const styles = stylex.create({
  aside: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    left: 0,
    maxHeight: `calc(100vh - ${spacing.xxxl})`,
    overflowX: "hidden",
    overflowY: "auto",
    padding: spacing.m,
    position: "sticky",
    top: 64,
    width: spacing.full,
  },
  asideClosed: {
    maxWidth: 72,
    minWidth: 72,
  },
  asideExpanded: {
    maxWidth: spacing.sidebar,
    minWidth: spacing.sidebar,
  },
});

export type SidebarProps = WithStylexArray<
  HTMLAttributes<HTMLDivElement> & {
    state?: "closed" | "expanded";
  }
>;

export const Sidebar = ({
  children,
  state = "expanded",
  styleXArray = [],
  ...props
}: SidebarProps) => {
  return (
    <aside
      {...stylex.props(
        styles.aside,
        state === "closed" ? styles.asideClosed : styles.asideExpanded,
        styleXArray,
      )}
      {...props}
    >
      {children}
    </aside>
  );
};
