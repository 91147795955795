"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import type {
  ChangeEventHandler,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useFocus } from "react-aria";

import {
  colors,
  numericValues,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type StyleXArray,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { CloseIcon } from "../../icons";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Text } from "../Text";

const hallowElement: HallowElement = "input";

const styles = stylex.create({
  asChild: {
    backgroundColor: colors.transparent,
    flexGrow: 1,
  },
  base: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  buttonInvisible: {
    visibility: "hidden",
  },
  buttonVisible: {
    visibility: "visible",
  },
  disabled: {
    opacity: numericValues["0.5"],
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
  div: {
    alignItems: "center",
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.full,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    color: semanticColors.neutralsMedium,
    cursor: "text",
    display: "flex",
    gap: spacing.xxs,
    height: spacing.xxl,
    paddingLeft: spacing.ms,
    paddingRight: spacing.ms,
    width: spacing.full,
  },
  divIsFocused: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.primary,
  },
  iconButton: {
    height: spacing.xxl,
    minWidth: spacing.xxl,
    width: spacing.xxl,
  },
  input: {
    backgroundColor: colors.transparent,
    border: "none",
    color: semanticColors.primary,
    flexGrow: 1,
    height: spacing.full,
    outline: "none",
    padding: spacing.none,
  },
});

export type InputProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  clearable?: boolean;
  closeable?: boolean;
  icon?: IconElement;
  inputStyleXArray?: StyleXArray;
  loseFocusOnOutsideClick?: boolean;
  onFocusChange?: (isFocused: boolean) => void;
  stateSetter?: Dispatch<SetStateAction<any>>;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      asChild = false,
      children,
      clearable,
      closeable,
      disabled,
      icon,
      inputStyleXArray = [],
      loseFocusOnOutsideClick = true,
      onChange,
      onFocusChange,
      stateSetter,
      styleXArray = [],
      value,
      ...props
    },
    ref,
  ) => {
    const internalInputRef = (ref ??
      useRef<HTMLInputElement>()) as MutableRefObject<HTMLInputElement>;

    const [isFocused, setIsFocused] = useState(false);
    const [internalState, setInternalState] = useState(value ?? "");

    const { focusProps } = useFocus({
      onFocusChange: (isFocused) => {
        if (isFocused || (!isFocused && loseFocusOnOutsideClick)) {
          setIsFocused(isFocused);
          if (onFocusChange) onFocusChange(isFocused);
        }
      },
    });

    const t = useTranslations();

    const handleInternalStateChange: ChangeEventHandler<HTMLInputElement> = (
      event,
    ) => {
      event.preventDefault();
      setInternalState(event.target.value);
      if (stateSetter) stateSetter(event.target.value);
      if (onChange) onChange(event);
    };

    const handleClearable = () => {
      handleInternalStateEmpty();
      setTimeout(() => setInternalInputRefFocus(), 1);
    };

    const handleCloseable = () => {
      handleInternalStateEmpty();
      setInternalInputRefBlur();
    };

    const handleInternalStateEmpty = () => {
      setInternalState("");
      if (stateSetter) stateSetter("");
    };

    const setInternalInputRefBlur = () => {
      internalInputRef.current.blur();
      setIsFocused(false);
      if (onFocusChange) onFocusChange(false);
    };

    const setInternalInputRefFocus = () => {
      if (!disabled) internalInputRef.current.focus();
    };

    useEffect(() => {
      setInternalState(value ?? "");
    }, [value]);

    if (asChild) {
      const DeterminedElement = determineElementFromAsChild({
        asChild,
        hallowElement,
      });
      const DeterminedChildren = determineChildrenInject({
        afterChildrenInject: null,
        beforeChildrenInject: [icon ? icon : null],
        children: children as JSX.Element,
      });
      return (
        <DeterminedElement
          ref={ref}
          {...stylex.props(
            styles.asChild,
            styles.div,
            disabled ? [styles.disabled, styles.disabledCursor] : null,
            styleXArray,
            inputStyleXArray,
          )}
        >
          {DeterminedChildren}
        </DeterminedElement>
      );
    }

    return (
      <div
        ref={ref}
        {...stylex.props(
          styles.base,
          disabled ? [styles.disabled, styles.disabledCursor] : null,
          styleXArray,
        )}
      >
        {closeable && isFocused && (
          <IconButton
            onClick={() => handleCloseable()}
            icon={<CloseIcon />}
            styleXArray={[styles.iconButton]}
            variant="neutral"
          />
        )}
        <div
          onClick={setInternalInputRefFocus}
          {...stylex.props(
            styles.div,
            disabled ? styles.disabledCursor : null,
            isFocused ? styles.divIsFocused : null,
          )}
        >
          {icon && icon}
          <Text asChild overflow="ellipsis" size="m" type="body">
            <input
              disabled={disabled}
              onChange={handleInternalStateChange}
              ref={internalInputRef}
              size={1}
              value={internalState}
              {...focusProps}
              {...props}
              {...stylex.props(
                styles.input,
                disabled ? styles.disabledCursor : null,
                inputStyleXArray,
              )}
            />
          </Text>
          {clearable && (
            <Button
              onMouseDown={handleClearable}
              size="xs"
              styleXArray={[
                internalState && internalState !== ""
                  ? styles.buttonVisible
                  : styles.buttonInvisible,
              ]}
              variant="transparentNeutral"
            >
              {t("web_words_clear")}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";
