"use client";

import { useTranslations } from "@packages/i18n";
import type { FlaggedReason } from "@packages/sdk";
import * as RadioGroup from "@radix-ui/react-radio-group";
import stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { FLAG_RECORD_REASON_OPTIONS } from "../../../../lib";
import { Text } from "../../../_base";
import { FlagRecordReasonRadioInputItem } from "../FlagRecordReasonRadioInputItem";

const styles = stylex.create({
  root: { display: "flex", flexDirection: "column", gap: spacing.xxs },
  header: { color: semanticColors.neutralsMedium },
});

export const FlagRecordReasonRadioInput = ({
  value,
  onValueChange,
}: {
  value: FlaggedReason;
  onValueChange: (reason: FlaggedReason) => void;
}) => {
  const t = useTranslations();

  return (
    <>
      <Text type="title" size="m" styleXArray={[styles.header]}>
        {t("community_flag_select_reason")}
      </Text>
      <RadioGroup.Root
        value={value}
        onValueChange={onValueChange}
        {...stylex.props(styles.root)}
      >
        {FLAG_RECORD_REASON_OPTIONS.map((option) => (
          <FlagRecordReasonRadioInputItem
            label={t(option.labelLokaliseKey)}
            sublabel={t(option.sublabelLokaliseKey)}
            value={option.value}
            checked={option.value === value}
            key={`flagRecordReason-${option.value}`}
          />
        ))}
      </RadioGroup.Root>
    </>
  );
};
