"use client";

import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: semanticColors.background,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.m,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    display: "flex",
    flexDirection: "column",
    paddingBottom: spacing.s,
    paddingLeft: spacing.ms,
    paddingRight: spacing.ms,
    paddingTop: spacing.s,
    width: "fit-content",
  },
  label: {
    color: semanticColors.neutralsHigher,
  },
  value: {
    color: semanticColors.accentMedium,
  },
});

import {
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../../global/stylex/vars.stylex";
import type { HallowElement, HallowElementProps } from "../../../../../types";
import { Text } from "../../../_core";

const hallowElement: HallowElement = "div";

export type TooltipProps = HallowElementProps<typeof hallowElement> & {
  label: string;
  value: string;
};

export const Tooltip = forwardRef<any, TooltipProps>(
  ({ label, value, ...props }, ref) => (
    <div {...stylex.props(styles.base)} {...props}>
      <Text size="l" styleXArray={[styles.label]} type="detail">
        {label}
      </Text>
      <Text size="xl" styleXArray={[styles.value]} type="detail">
        {value}
      </Text>
    </div>
  ),
);

Tooltip.displayName = "Tooltip";
