import * as stylex from "@stylexjs/stylex";
import { forwardRef, Fragment } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { ChevronRightIcon } from "../../icons";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: semanticColors.background,
      ":hover": semanticColors.neutralsLowest,
      ":focus": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderRadius: radius.m,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    justifyContent: "space-between",
    opacity: {
      default: numericValues[1],
      ":focus": numericValues[0.6],
      ":active": numericValues[0.6],
    },
    padding: spacing.m,
    textAlign: "left",
    width: numericPercentages[100],
  },
  disabled: {
    backgroundColor: semanticColors.background,
    cursor: "not-allowed",
    opacity: numericValues[0.5],
  },
  text: {
    flexGrow: 1,
    width: spacing.t,
  },
});

export type ListRecentSearchProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  disabled?: boolean;
  icon?: IconElement;
  title: string;
};

export const ListRecentSearch = forwardRef<any, ListRecentSearchProps>(
  (
    {
      asChild = false,
      children,
      disabled,
      icon = <ChevronRightIcon />,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <Text
          overflow="ellipsis"
          key="text"
          size="m"
          type="body"
          styleXArray={[styles.text]}
        >
          {title}
        </Text>,
        <Fragment key="icon">{icon}</Fragment>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...{ disabled, ...props }}
        {...stylex.props(
          styles.base,
          disabled ? styles.disabled : null,
          styleXArray,
        )}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListRecentSearch.displayName = "ListRecentSearch";
