"use client";

import * as RadixUiAccordion from "@radix-ui/react-accordion";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import type { WithStylexArray } from "../../../types";

const styles = stylex.create({
  accordion: {},
});

type AccordionProps = WithStylexArray<
  ComponentProps<typeof RadixUiAccordion.Root> & {}
>;

export const Accordion = ({ children, styleXArray }: AccordionProps) => {
  return (
    <RadixUiAccordion.Root
      collapsible
      {...stylex.props(styles.accordion, styleXArray)}
      type="single"
    >
      {children}
    </RadixUiAccordion.Root>
  );
};
