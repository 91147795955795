"use client";

import * as RadixUiDropdownMenu from "@radix-ui/react-dropdown-menu";
import stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";
import { forwardRef } from "react";

import {
  colors,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { type WithStylexArray } from "../../../../types";
import { determineChildrenInject } from "../../../../utils";
import { Text } from "../../_core";
import { CheckmarkIcon, type IconElement } from "../../icons";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.onOverlayLow,
      ":focus": semanticColors.onOverlayLow,
      ":active": semanticColors.onOverlayLow,
    },
    borderRadius: radius.m,
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":focus": semanticColors.primary,
      ":active": semanticColors.neutralsMedHigh,
    },
    columnGap: spacing.m,
    cursor: "pointer",
    display: "inline-flex",
    height: `max(${numericPixels[56]}, min-content)`,
    maxWidth: spacing.full,
    minWidth: numericPixels[300],
    outline: "none",
    overflow: "hidden",
    padding: spacing.m,
    width: spacing.full,
  },
  selected: {
    backgroundColor: semanticColors.neutralsLowest,
  },
  checkIcon: {
    lineHeight: 0,
    marginLeft: "auto",
  },
  startIcon: {
    lineHeight: 0,
    marginRight: spacing.s,
  },
});

export type DropdownMenuItemProps = WithStylexArray<
  ComponentProps<typeof RadixUiDropdownMenu.Item>
> & {
  selected?: boolean;
  startIcon?: IconElement;
  hideCheckOnSelected?: boolean;
};

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>(
  (
    {
      onSelect,
      children,
      selected = false,
      styleXArray = [],
      startIcon,
      hideCheckOnSelected,
      ...props
    },
    ref,
  ) => {
    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        selected && !hideCheckOnSelected ? (
          <span key="checkmarkSimpleOn" {...stylex.props(styles.checkIcon)}>
            <CheckmarkIcon.SimpleOn />
          </span>
        ) : null,
      ],
      beforeChildrenInject: [
        startIcon ? (
          <span key="startIcon" {...stylex.props(styles.startIcon)}>
            {startIcon}
          </span>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <Text
        key={`dropdown-menu-item-${children}`}
        asChild
        size="m"
        type="title"
      >
        <RadixUiDropdownMenu.Item
          key={`dropdown-menu-item-${children}`}
          ref={ref}
          onSelect={onSelect}
          {...props}
          {...stylex.props(
            styles.base,
            selected ? styles.selected : null,
            styleXArray,
          )}
        >
          {DeterminedChildren}
        </RadixUiDropdownMenu.Item>
      </Text>
    );
  },
);

DropdownMenuItem.displayName = "DropdownMenuItem";
