"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useCallback, useEffect, useState } from "react";

import {
  numericPercentages,
  numericPixels,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Button,
  FastForwardIcon,
  Plus10Icon,
  Transition,
} from "../../../../../components";
import { TransitionRoot } from "../../../../../providers";
import type { StyleXArray, WithButtonWrapper } from "../../../../../types";
import { MediaButton } from "../MediaButton";

export type ForwardButtonProps = WithButtonWrapper<{
  canSkipIntro: boolean;
  skipStyleXArray?: StyleXArray;
  forwardStyleXArray?: StyleXArray;
}>;

const styles = stylex.create({
  container: {
    display: "flex",
    placeItems: "center",
    width: numericPixels[40],
  },
  containerEXPANDED: {
    width: numericPixels[80],
  },
  skipExiting: {
    opacity: 0,
    position: "absolute",
    top: numericPercentages[50],
    transform: "translateY(-50%)",
  },
});

export const ForwardButton = ({
  canSkipIntro,
  Wrapper = ({ children }) => <>{children}</>,
  forwardStyleXArray = [],
  skipStyleXArray = [],
}: ForwardButtonProps) => {
  const [value, setValue] = useState<"skipIntro" | "forward">(
    canSkipIntro ? "skipIntro" : "forward",
  );
  const t = useTranslations();
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();

  useEffect(() => {
    setValue(canSkipIntro ? "skipIntro" : "forward");
  }, [canSkipIntro]);

  const handleForward = useCallback(
    (e) => {
      e.stopPropagation();
      player?.skipForward();
    },
    [player],
  );

  const handleSkipIntro = useCallback(
    (e) => {
      e.stopPropagation();
      player?.skipIntro();
    },
    [player],
  );

  return (
    <TransitionRoot
      value={value}
      defaultValue={canSkipIntro ? "skipIntro" : "forward"}
      styleXArray={[
        styles.container,
        styles[`container${player.display.toUpperCase()}`],
      ]}
    >
      <Transition
        value={"skipIntro"}
        exitingStyleXArray={[styles.skipExiting]}
        exitedStyleXArray={[styles.skipExiting]}
      >
        <Wrapper asChild>
          <Button
            variant={"lighten"}
            onClick={handleSkipIntro}
            styleXArray={[skipStyleXArray]}
            size={"s"}
          >
            {t("audio_player_skip_intro")}
          </Button>
        </Wrapper>
      </Transition>
      <Transition value={"forward"}>
        <Wrapper asChild>
          <MediaButton
            largeIcon={
              currentItem?.selected_audio?.show_music_controls ? (
                <FastForwardIcon.XL />
              ) : (
                <Plus10Icon.XL />
              )
            }
            smallIcon={
              currentItem?.selected_audio?.show_music_controls ? (
                <FastForwardIcon.Small />
              ) : (
                <Plus10Icon.Medium />
              )
            }
            styleXArray={forwardStyleXArray}
            onClick={handleForward}
            tabIndex={0}
          />
        </Wrapper>
      </Transition>
    </TransitionRoot>
  );
};
