"use client";

import { useModal } from "@ebay/nice-modal-react";
import type { FlaggableType } from "@packages/sdk";
import { redirectToAuth, useSession } from "@packages/sdk";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

import type { HallowElement, HallowElementProps } from "../../../../types";
import { FlagRecordModal } from "../FlagRecordModal";

const Element: HallowElement = "button";

export type FlagRecordButtonProps = HallowElementProps<typeof Element> & {
  flaggableId: number;
  flaggableType: FlaggableType;
};

export const FlagRecordButton = forwardRef<any, FlagRecordButtonProps>(
  ({ flaggableId, flaggableType, onClick, children, ...props }, ref) => {
    const flagRecordModal = useModal(FlagRecordModal);
    const { status } = useSession();

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (onClick) onClick(event);
      if (status === "authenticated") {
        flagRecordModal.show({
          flaggableId,
          flaggableType,
        });
      } else {
        return redirectToAuth();
      }
    };

    return (
      <button onClick={handleClick} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);

FlagRecordButton.displayName = "FlagRecordButton";
