import * as stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { CardProps } from "../../../../refactor";
import { Card } from "../../../../refactor";

const styles = stylex.create({
  card: {
    paddingTop: "110%",
  },
  infoContainer: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
  },
  mask: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
});

export type NextUpMediumProps = CardProps;

export const NextUpMedium = ({
  centerActions,
  description,
  imgSrc,
  infoContainerStyleXArray,
  maskProps,
  rightActions,
  styleXArray,
  tag,
  title,
  titleProps,
  ...props
}: NextUpMediumProps) => (
  <Card
    alternativeTitle={title}
    description={description}
    imgSrc={imgSrc}
    infoContainerStyleXArray={[styles.infoContainer, infoContainerStyleXArray]}
    maskProps={{
      ...maskProps,
      styleXArray: [styles.mask, maskProps?.styleXArray],
    }}
    centerActions={centerActions}
    rightActions={rightActions}
    styleXArray={[styles.card, styleXArray]}
    tag={tag}
    {...props}
  />
);
