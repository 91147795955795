"use client";

import { useFormatter, useTranslations } from "@packages/i18n";
import stylex from "@stylexjs/stylex";
import { forwardRef, useEffect, useState } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import { Text } from "../../../_base";

/**
 * MAX_INCREMENT: Maxium increase
 * MIN_INCREMENT: Minimum increase
 * MAX_TIMER_DELAY: Maxium timer delay (ms)
 * MIN_TIMER_DELAY: Minimum timer delay (ms)
 */
const MAX_INCREMENT = 7;
const MIN_INCREMENT = 1;
const MAX_TIMER_DELAY = 7000;
const MIN_TIMER_DELAY = 1000;

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  determinedElement: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
    width: spacing.full,
  },
});

const getRandomTimer = () => {
  return Math.floor(Math.random() * MAX_TIMER_DELAY) + MIN_TIMER_DELAY;
};

const hallowElement: HallowElement = "div";

export type PrayerCounterProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & { initialCount: number };

export const PrayerCounter = forwardRef<any, PrayerCounterProps>(
  ({ asChild = false, children, initialCount, styleXArray, ...props }, ref) => {
    const t = useTranslations();

    const [count, setCount] = useState<number>(initialCount);
    const format = useFormatter();

    const updateCount = () => {
      const increment =
        Math.floor(Math.random() * MAX_INCREMENT) + MIN_INCREMENT;
      setCount((prevCount) => prevCount + increment);
    };

    useEffect(() => {
      const timer = setTimeout(updateCount, getRandomTimer());
      return () => clearTimeout(timer);
    }, [count]);

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="container" {...stylex.props(styles.container)}>
          <Text type="display" size="s">
            {format.number(count)}
          </Text>
          <Text type="body" size="m">
            {t("prayers_prayed_with_hallow")}
          </Text>
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.determinedElement, styleXArray)}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

PrayerCounter.displayName = "PrayerCounter";
