"use client";

import { Close as DialogClose } from "@radix-ui/react-dialog";
import { Button as ToolbarButton } from "@radix-ui/react-toolbar";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

import {
  colors,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
} from "../../../../../../global/stylex/vars.stylex";
import { ChevronDownIcon, IconButton } from "../../../../../components";

const styles = stylex.create({
  collapse: {
    backgroundColor: {
      default: semanticColors.onColorHigh,
      ":hover": semanticColors.onColorVeryHigh,
    },
    border: numericValues[0],
    borderRadius: radius.full,
    display: "flex",
    height: numericPixels[40],
    placeContent: "center",
    placeItems: "center",
    width: numericPixels[40],
  },
  icon: {
    display: "flex",
    flexShrink: 0,
  },
});

export type CollapseButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const CollapseButton = forwardRef<
  HTMLButtonElement,
  CollapseButtonProps
>(({ onClick }, ref) => {
  return (
    <ToolbarButton asChild>
      <DialogClose asChild>
        <IconButton
          ref={ref}
          icon={
            <ChevronDownIcon
              fill={colors.white}
              {...stylex.props(styles.icon)}
            />
          }
          {...stylex.props(styles.collapse)}
          onClick={onClick}
          tabIndex={0}
        />
      </DialogClose>
    </ToolbarButton>
  );
});

CollapseButton.displayName = "CollapseButton";
