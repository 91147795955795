import type { DeeplinkSchema } from "@packages/sdk";
import { getURLFromDeeplink } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

import {
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import { Button, ChevronRightIcon, Text } from "../../../_base";
import { HallowImage } from "../HallowImage";

const style = stylex.create({
  button: {
    alignItems: "center",
    borderRadius: radius.ms,
    columnGap: spacing.m,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    height: numericPixels[80],
    justifyContent: "flex-start",
    paddingBottom: spacing.s,
    paddingLeft: spacing.s,
    paddingRight: spacing.l,
    paddingTop: spacing.s,
  },
  buttonBg: (bgColor: string) => ({
    background: bgColor,
  }),
  textOverride: {
    color: semanticColors.neutralsHighest,
  },
  descriptionOverride: {
    color: semanticColors.neutralsVeryHigh,
  },
  image: {
    borderRadius: radius.s,
    objectFit: "cover",
    objectPosition: "center",
  },
  textContainer: {
    borderRadius: radius.s,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: spacing.full,
  },
  description: {
    color: semanticColors.onColorVeryHigh,
  },
});

export type RecommendationsDeeplinkProps = WithStylexArray<DeeplinkSchema>;

export const RecommendationDeeplink = ({
  id,
  color_hex,
  deeplink,
  title,
  image_url,
  description,
  styleXArray,
}: RecommendationsDeeplinkProps) => {
  let override = false;
  if (color_hex === "#000000") {
    override = true;
    color_hex = semanticColors.neutralsLowest;
  }

  if (!deeplink) return null;

  return (
    <Button
      key={`deeplink_${id}`}
      endIcon={
        <ChevronRightIcon
          {...stylex.props(override ? style.textOverride : null)}
        />
      }
      variant={"color"}
      styleXArray={[style.button, style.buttonBg(color_hex), styleXArray]}
      asChild
    >
      <Link href={getURLFromDeeplink({ deeplink }) ?? ""}>
        <HallowImage
          src={{ small: image_url }}
          size={"s"}
          width={64}
          height={64}
          alt={title}
          {...stylex.props(style.image)}
        />
        <div
          {...stylex.props(
            style.textContainer,
            override ? style.textOverride : null,
          )}
        >
          <Text type={"title"} size={"m"}>
            {title}
          </Text>
          <Text
            type={"detail"}
            size={"xl"}
            styleXArray={[
              style.description,
              override ? style.descriptionOverride : null,
            ]}
          >
            {description}
          </Text>
        </div>
      </Link>
    </Button>
  );
};
