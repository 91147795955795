import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { CarouselApi } from "../../../../../hooks";
import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../../types";
import { CarouselItem } from "../CarouselItem";

const cssMediaMinWidthMS = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    minHeight: spacing.t,
    width: {
      [cssMediaMinWidthMS]: spacing.xl,
      default: spacing.m,
    },
  },
});

export type CarouselPageOffsetProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
> & {
  carouselApi: CarouselApi;
};

export const CarouselPageOffset = forwardRef<
  HTMLDivElement,
  CarouselPageOffsetProps
>(({ carouselApi, styleXArray, ...props }, ref) => (
  <CarouselItem
    carouselApi={carouselApi}
    ref={ref}
    styleXArray={[styles.base, styleXArray]}
    {...props}
  />
));

CarouselPageOffset.displayName = "CarouselPageOffset";
