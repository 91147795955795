"use client";

import type { MediaSizes } from "@packages/media";
import { useCurrentQueueItem } from "@packages/media";
import * as stylex from "@stylexjs/stylex";

import {
  numericPercentages,
  numericValues,
  semanticColors,
} from "../../../../../../global/stylex/vars.stylex";
import type { TextProps } from "../../../../../components";
import { MediaMoreMenu, Text } from "../../../../../components";
import type { WithStylexArray } from "../../../../../types";

export type MediaInfoProps = WithStylexArray<{
  size: MediaSizes;
  showMenu?: boolean;
}>;

const styles = stylex.create({
  container: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 1,
    flexWrap: "nowrap",
    overflow: "hidden",
    rowGap: numericValues[0],
    width: numericPercentages[100],
  },
  outer: {
    alignItems: "center",
    color: semanticColors.onColorHighest,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    maxWidth: numericPercentages[100],
    overflow: "hidden",
    width: "calc(100vw - 200px)",
  },
  desc: {
    color: semanticColors.onColorVeryHigh,
  },
  collapsed: {
    maxWidth: "340px",
    width: "100%",
  },
});

const getDescriptionProps = (
  size: MediaInfoProps["size"],
): {
  type: TextProps["type"];
  size: TextProps["size"];
} => {
  switch (size) {
    case "collapsed-mobile":
      return { type: "detail", size: "l" };
    case "collapsed-desktop":
      return { type: "title", size: "xs" };
    case "expanded-desktop":
    case "expanded-mobile":
      return { type: "detail", size: "xl" };
    case "fullscreen-desktop":
    case "fullscreen-mobile":
    case "hidden-mobile":
    case "hidden-desktop":
    default:
      return { type: "body", size: "s" };
  }
};

const getTitleProps = (
  size: MediaInfoProps["size"],
): {
  type: TextProps["type"];
  size: TextProps["size"];
} => {
  switch (size) {
    case "collapsed-mobile":
    case "collapsed-desktop":
      return { type: "title", size: "m" };
    case "expanded-mobile":
    case "expanded-desktop":
      return { type: "title", size: "l" };
    case "fullscreen-desktop":
    case "fullscreen-mobile":
    case "hidden-mobile":
    case "hidden-desktop":
    default:
      return { type: "headline", size: "s" };
  }
};

export const MediaInfo = ({ size, styleXArray, showMenu }: MediaInfoProps) => {
  const { currentItem } = useCurrentQueueItem();
  const title = currentItem?.prayer?.title ?? "";
  const description = currentItem?.prayer?.label_desc ?? "";
  const descriptionProps = getDescriptionProps(size);
  const titleProps = getTitleProps(size);

  return (
    <div
      {...stylex.props(
        styles.outer,
        size === "collapsed-desktop" ? styles.collapsed : null,
        styleXArray,
      )}
    >
      <div {...stylex.props(styles.container)}>
        <Text overflow="ellipsis" title={title} {...(titleProps as TextProps)}>
          {title}
        </Text>
        <Text
          overflow="ellipsis"
          title={description}
          {...(descriptionProps as TextProps)}
        >
          {description}
        </Text>
      </div>
      {showMenu ? (
        <MediaMoreMenu
          prayer={currentItem?.prayer}
          imageSrc={currentItem?.prayer?.images}
        />
      ) : null}{" "}
    </div>
  );
};
