"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";
import { useHover } from "react-aria";

import {
  colors,
  semanticColors,
  semanticColorsRaw,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { CSSColor } from "../../../../utils";
import { colorMix } from "../../../../utils";
import type { IconButtonProps } from "../IconButton";
import { IconButton } from "../IconButton";
import { Text } from "../Text";

const styles = stylex.create({
  button: {
    "-webkit-tap-highlight-color": "transparent",
    alignItems: "center",
    background: colors.transparent,
    border: "none",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    padding: spacing.none,
    width: spacing.xxxl,
  },
  disabled: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
  iconButtonDisabled: {
    backgroundColor: {
      default: colors.transparent,
      ":focus-visible": colors.transparent,
      ":hover": colors.transparent,
      ":active": colors.transparent,
    },
    cursor: "not-allowed",
  },
});

const stylesVariantButton = stylex.create({
  transparentPrimary: () => ({
    color: {
      default: semanticColors.neutralsVeryHigh,
      ":focus-visible:enabled": semanticColors.neutralsMedium,
      ":hover:enabled": semanticColors.neutralsVeryHigh,
      ":active:enabled": semanticColors.neutralsMedium,
    },
  }),
  transparentWhite: () => ({
    color: {
      default: semanticColors.onColorVeryHigh,
      ":focus-visible:enabled": semanticColors.onColorVeryHigh,
      ":hover:enabled": semanticColors.onColorHighest,
      ":active:enabled": semanticColors.onColorVeryHigh,
    },
  }),
  color: (color) => ({
    color: {
      default: color,
      ":hover:enabled": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
      ":focus-visible:enabled": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
      ":active:enabled": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
    },
  }),
  neutral: () => ({
    color: {
      default: semanticColors.primary,
      ":focus-visible:enabled": semanticColors.neutralsMedHigh,
      ":hover:enabled": semanticColors.primary,
      ":active:enabled": semanticColors.neutralsMedHigh,
    },
  }),
});

const stylesVariantIconButton = stylex.create({
  transparentPrimary: {
    backgroundColor: {
      default: colors.transparent,
      ":active:enabled": semanticColors.neutralsLowest,
    },
    color: {
      default: semanticColors.neutralsVeryHigh,
      ":active:enabled": semanticColors.neutralsMedLow,
    },
  },
  transparentWhite: {
    backgroundColor: {
      default: colors.transparent,
      ":active:enabled": semanticColors.fixedLightenMedLow,
    },
    color: {
      default: semanticColors.onColorVeryHigh,
      ":active:enabled": semanticColors.onColorVeryHigh,
    },
  },
});

const stylesVariantIconButtonHover = stylex.create({
  color: (color) => ({
    backgroundColor: colorMix({
      adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
      source: color,
    }),
  }),
  transparentPrimary: () => ({
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.neutralsVeryHigh,
  }),
  transparentWhite: () => ({
    backgroundColor: semanticColors.fixedLightenMedLow,
    color: semanticColors.onColorHighest,
  }),
  neutral: () => ({
    backgroundColor: semanticColors.neutralsVeryLow,
    color: semanticColors.primary,
  }),
});

export type QuickActionButtonProps = IconButtonProps & {
  variant?: "color" | "neutral" | "transparentPrimary" | "transparentWhite";
  size?: "m" | "l";
};

const OVERRIDE_BUTTON_VARIANTS = ["transparentPrimary", "transparentWhite"];

export const QuickAction = forwardRef<any, QuickActionButtonProps>(
  (
    {
      children,
      disabled = false,
      variant = "transparentPrimary",
      size = "m",
      icon,
      color = semanticColorsRaw.accentMedium.default,
      ...props
    },
    ref,
  ) => {
    const { hoverProps, isHovered } = useHover({});
    const overrideButtonVariant = OVERRIDE_BUTTON_VARIANTS.includes(variant);

    return (
      <Text asChild size={size === "l" ? "m" : "xs"} type="title">
        <button
          disabled={disabled}
          ref={ref}
          {...hoverProps}
          {...props}
          {...stylex.props(
            styles.button,
            stylesVariantButton[variant](color),
            disabled ? styles.disabled : null,
          )}
        >
          <IconButton
            asChild
            icon={icon}
            variant={overrideButtonVariant ? undefined : variant}
            size={size}
            color={variant === "color" && color}
            disabled={overrideButtonVariant ? undefined : disabled}
            styleXArray={[
              overrideButtonVariant && stylesVariantIconButton[variant],
              overrideButtonVariant && disabled
                ? styles.iconButtonDisabled
                : null,
              !disabled && isHovered
                ? stylesVariantIconButtonHover[variant](color)
                : null,
            ]}
          >
            <span />
          </IconButton>
          {children}
        </button>
      </Text>
    );
  },
);

QuickAction.displayName = "QuickAction";
