import { DialogClose } from "@radix-ui/react-dialog";
import type { ForwardedRef, ReactNode, SyntheticEvent } from "react";
import { forwardRef } from "react";

import { useDynamicViewport } from "../../../../hooks";
import { VIEWPORT_TABLET } from "../../../../lib";
import {
  DropdownMenuItem,
  type DropdownMenuItemProps,
} from "../DropdownMenuItem";
import { ModalItem, type ModalItemProps } from "../ModalItem";

export type ModalDropdownMenuItemProps = {
  children?: ReactNode;
  dropdownMenuItemProps?: DropdownMenuItemProps;
  modalItemProps?: ModalItemProps;
  selected?: boolean;
  onSelect?: (e: Event | SyntheticEvent<HTMLButtonElement>) => void;
  minDesktopWidth?: number;
  options?: {
    closeModalOnSelect?: boolean;
  };
};

export const ModalDropdownMenuItem = forwardRef<
  HTMLButtonElement | HTMLDivElement,
  ModalDropdownMenuItemProps
>(
  (
    {
      children,
      dropdownMenuItemProps = {},
      modalItemProps = {},
      selected,
      onSelect,
      minDesktopWidth = VIEWPORT_TABLET,
      options = {},
    },
    ref,
  ) => {
    const { closeModalOnSelect = true } = options;

    const isDesktopViewport = useDynamicViewport({
      minimumWidth: minDesktopWidth,
      defaultState: null,
    });

    if (isDesktopViewport === null) return null;

    if (isDesktopViewport)
      return (
        <DropdownMenuItem
          ref={ref as ForwardedRef<HTMLDivElement>}
          selected={selected}
          onSelect={onSelect}
          {...dropdownMenuItemProps}
        >
          {dropdownMenuItemProps.children || children}
        </DropdownMenuItem>
      );

    const ModalItemComponent = (
      <ModalItem
        ref={ref as ForwardedRef<HTMLButtonElement>}
        selected={selected}
        onSelect={onSelect}
        {...modalItemProps}
      >
        {modalItemProps.children || children}
      </ModalItem>
    );

    if (closeModalOnSelect) {
      return <DialogClose asChild>{ModalItemComponent}</DialogClose>;
    } else {
      return ModalItemComponent;
    }
  },
);

ModalDropdownMenuItem.displayName = "ModalDropdownMenuItem";
