import { forwardRef } from "react";

import type { HallowElementProps } from "../../../../types";

export type VideoProps = HallowElementProps<"video">;

export const Video = forwardRef<HTMLVideoElement, VideoProps>(
  ({ children, ...props }, ref) => {
    return (
      <video ref={ref} {...props}>
        {children}
      </video>
    );
  },
);

Video.displayName = "Video";
