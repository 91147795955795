"use client";

import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";
import { createContext, useContext, useEffect, useState } from "react";

import type { WithAsChild, WithStylexArray } from "../../types";
import { determineElementFromAsChild } from "../../utils";

const styles = stylex.create({
  container: {
    position: "relative",
  },
});

export type TransitionContextShape = {
  entering: string;
  entered: string;
  exiting: string;
  exited: string;
  transitionMs: number;
};

export const TransitionContext = createContext<TransitionContextShape>({
  entering: "",
  entered: "",
  exiting: "",
  exited: "",
  transitionMs: 250,
});

export const useTransition = () => useContext(TransitionContext);

export type TransitionProviderProps = Omit<
  ComponentProps<typeof TransitionContext.Provider>,
  "value"
> &
  WithAsChild<
    WithStylexArray<{
      defaultValue: string;
      transitionMs?: number;
      value: string;
    }>
  >;

const hallowElement = "div";

export const TransitionRoot = ({
  children,
  defaultValue,
  value,
  transitionMs = 250,
  asChild,
  styleXArray,
  ...props
}: TransitionProviderProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  const [entering, setEntering] = useState<string>("");
  const [entered, setEntered] = useState<string>(defaultValue);
  const [exiting, setExiting] = useState<string>("");
  const [exited, setExited] = useState<string>("");

  useEffect(() => {
    if (value !== entered) {
      setExiting(entered);
      setEntering(value);
      setEntered("");
      setExited("");
      setTimeout(() => {
        setExited(entered);
        setEntered(value);
        setEntering("");
        setExiting("");
        setTimeout(() => {
          setExited("");
        }, transitionMs);
      }, 10);
    }
  }, [value]);

  return (
    <TransitionContext.Provider
      value={{ entering, entered, exiting, exited, transitionMs }}
      {...props}
    >
      <DeterminedElement {...stylex.props(styles.container, styleXArray)}>
        {children}
      </DeterminedElement>
    </TransitionContext.Provider>
  );
};

TransitionRoot.displayName = "TransitionRoot";
