import { useMedia } from "react-use";

export type UseDynamicViewportProps = {
  minimumWidth: number;
  defaultState?: boolean;
};

export const useDynamicViewport = ({
  minimumWidth,
  defaultState = false,
}: UseDynamicViewportProps) =>
  useMedia(`(min-width: ${minimumWidth}px)`, defaultState);
