"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import type { spacingMasksRaw } from "../../../../../global/stylex/vars.stylex";
import {
  colors,
  semanticColors,
  spacing,
  spacingMasks,
} from "../../../../../global/stylex/vars.stylex";
import type { GetStylexColorVarValueProps } from "../../../../hooks";
import { stylexDynamics } from "../../../../lib";
import type {
  GradientDirection,
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  horizontal: {
    width: spacing.full,
  },
  background: (color: MaskProps["color"]) => ({
    background: semanticColors[color] ?? colors[color] ?? color,
  }),
  vertical: {
    height: spacing.full,
  },
});

const stylesHorizontalSize = stylex.create({
  xs: {
    height: spacingMasks.xs,
  },
  s: {
    height: spacingMasks.s,
  },
  m: {
    height: spacingMasks.m,
  },
  l: {
    height: spacingMasks.l,
  },
  xl: {
    height: spacingMasks.xl,
  },
});

const stylesVerticalSize = stylex.create({
  xs: {
    width: spacingMasks.xs,
  },
  s: {
    width: spacingMasks.s,
  },
  m: {
    width: spacingMasks.m,
  },
  l: {
    width: spacingMasks.l,
  },
  xl: {
    width: spacingMasks.xl,
  },
});

export type MaskProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  color?: GetStylexColorVarValueProps["color"];
  direction?: GradientDirection;
  orientation?: "horizontal" | "vertical";
  size?: keyof typeof spacingMasksRaw;
};

export const Mask = forwardRef<any, MaskProps>(
  (
    {
      asChild = false,
      color = "background",
      direction = "to bottom",
      orientation = "horizontal",
      size = "m",
      styleXArray,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles[orientation],
          orientation === "horizontal"
            ? stylesHorizontalSize[size]
            : stylesVerticalSize[size],
          styles.background(color),
          stylexDynamics.fullMask(direction),
          styleXArray,
        )}
      />
    );
  },
);

Mask.displayName = "Mask";
