"use client";

import * as stylex from "@stylexjs/stylex";
import type { PropsWithChildren } from "react";

import { useTransition } from "../../../../providers";
import type {
  StyleXArray,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

export type TransitionProps = PropsWithChildren<
  WithStylexArray<
    WithAsChild<{
      value: string;
      childUsesStyleXArray?: boolean;
      enteringStyleXArray?: StyleXArray;
      enteredStyleXArray?: StyleXArray;
      exitingStyleXArray?: StyleXArray;
      exitedStyleXArray?: StyleXArray;
    }>
  >
>;

const styles = stylex.create({
  base: {
    display: "block",
    left: 0,
    position: "absolute",
    top: 0,
    transition: "opacity",
    transitionTimingFunction: "ease-in-out",
  },
  speed: (duration: number) => ({
    transitionDuration: `${duration}ms`,
  }),
  enteredBase: {
    position: "relative",
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
});

const hallowElement = "div";

export const Transition = ({
  children,
  value,
  styleXArray = [],
  enteringStyleXArray = [styles.entering],
  enteredStyleXArray = [styles.entered],
  exitingStyleXArray = [styles.exiting],
  exitedStyleXArray = [styles.exited],
  asChild = false,
  childUsesStyleXArray = false,
}: TransitionProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  const { entering, entered, exiting, exited, transitionMs } = useTransition();

  if (![entering, entered, exiting, exited].includes(value)) return null;

  const appliedStyles = [
    styles.base,
    styles.speed(transitionMs),
    styleXArray,
    entering === value ? enteringStyleXArray : null,
    entered === value ? [styles.enteredBase, ...enteredStyleXArray] : null,
    exiting === value ? exitingStyleXArray : null,
    exited === value ? exitedStyleXArray : null,
  ];
  const props = childUsesStyleXArray
    ? { styleXArray: appliedStyles }
    : { ...stylex.props(appliedStyles) };

  return <DeterminedElement {...props}>{children}</DeterminedElement>;
};
