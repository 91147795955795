"use client";

import stylex from "@stylexjs/stylex";
import type { FieldValues, FormProviderProps } from "react-hook-form";
import { FormProvider } from "react-hook-form";

import type { WithStylexArray } from "../../../../types";

export const Form = <
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  formProps,
  ...props
}: FormProviderProps<TFieldValues, TContext, TTransformedValues> & {
  formProps: WithStylexArray<React.HTMLAttributes<HTMLFormElement>>;
}) => (
  <FormProvider {...props}>
    <form {...stylex.props(formProps.styleXArray)} {...formProps}>
      {props.children}
    </form>
  </FormProvider>
);

Form.displayName = "Form";
