"use client";

import { useModal } from "@ebay/nice-modal-react";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { Fragment, useCallback, useContext } from "react";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  numericPercentages,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import { IconButton, ShareIcon, ShareModal } from "../../../../../components";
import type { WithButtonWrapper } from "../../../../../types";
import { PlayerInternalContext, Queue, TextOnScreen } from "../../../core";
import { VolumeControl } from "../../controls";
import { MediaMenu } from "../MediaMenu";

const styles = stylex.create({
  controlSet: {
    alignItems: "center",
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
  },
  fullWidth: {
    width: numericPercentages[100],
  },
  smallContainer: {
    justifyContent: "space-between",
  },
  withVolume: {
    justifyContent: "space-between",
  },
  leftSet: {
    justifyContent: "flex-start",
  },
  modalZ: {
    zIndex: zIndices.modalOverQueue,
  },
  modalPadding: {
    paddingTop: spacing.l,
  },
});

export type SettingsControlsProps = WithButtonWrapper<{
  size?: "s" | "l";
  withVolume?: boolean;
}>;

export const SettingsControls = ({
  size = "l",
  withVolume = false,
  Wrapper = ({ children }) => <Fragment>{children}</Fragment>,
}: SettingsControlsProps) => {
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();
  const { collapsePlayer, transcriptOpen, transcriptOpenChangeHandler } =
    useContext(PlayerInternalContext);
  const shareModal = useModal(ShareModal);

  const ShareAndSettings = useCallback(
    () => (
      <>
        <Wrapper asChild>
          <IconButton
            icon={<ShareIcon fill={semanticColors.onColorVeryHigh} />}
            variant={"transparentWhite"}
            tabIndex={0}
            onClick={() =>
              shareModal.show({
                description: currentItem?.prayer?.label_desc,
                color: currentItem?.collection?.images?.color_hex ?? "#000000",
                imgSrc:
                  currentItem?.collection?.images?.large ??
                  currentItem?.collection?.images?.medium ??
                  currentItem?.collection?.images?.small,
                link: currentItem?.prayer?.shareable_url,
                styleXArray: [themes.dark, styles.modalZ, styles.modalPadding],
                title: currentItem?.prayer?.title,
              })
            }
          />
        </Wrapper>
        <MediaMenu Wrapper={Wrapper} />
      </>
    ),
    [Wrapper, size],
  );

  const TextAndQueue = useCallback(
    () => (
      <>
        <TextOnScreen
          prayerId={currentItem?.prayer?.id}
          iconColor={semanticColors.onColorVeryHigh}
          Wrapper={Wrapper}
          onClick={(e) => {
            if (player?.display === "expanded") collapsePlayer(e);
          }}
          open={transcriptOpen}
          onOpenChange={transcriptOpenChangeHandler}
        />
        <Queue Wrapper={Wrapper} />
      </>
    ),
    [Wrapper, size, transcriptOpen, transcriptOpenChangeHandler, currentItem],
  );

  return (
    <div
      {...stylex.props(
        styles.controlSet,
        styles.fullWidth,
        size === "s" ? styles.smallContainer : null,
        withVolume ? styles.withVolume : null,
      )}
      onClick={(e) => {
        if (e.currentTarget !== e.target) e.stopPropagation();
      }}
    >
      {withVolume ? (
        <>
          <div {...stylex.props(styles.controlSet, styles.leftSet)}>
            <ShareAndSettings />
            <VolumeControl
              value={player?.volume ?? 1}
              onVolumeChange={(v) => player?.setVolume(v)}
              iconColor={semanticColors.onColorVeryHigh}
              onOverlay={true}
              Wrapper={Wrapper}
            />
          </div>
          <div {...stylex.props(styles.controlSet)}>
            <TextAndQueue />
          </div>
        </>
      ) : (
        <>
          <ShareAndSettings />
          <TextAndQueue />
        </>
      )}
    </div>
  );
};
