import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import { CAROUSEL_GRID_SIZES } from "../../../../constants";
import type { CarouselApi } from "../../../../hooks";
import type {
  CarouselGridSize,
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidth1 = "@media (min-width: 640px) and (max-width: 767px)";
const cssMediaMinWidth2 = "@media (min-width: 768px) and (max-width: 1023px)";
const cssMediaMinWidth3 = "@media (min-width: 1024px) and (max-width: 1263px)";
const cssMediaMinWidth4 = "@media (min-width: 1264px) and (max-width: 1535px)";
const cssMediaMinWidth5 = "@media (min-width: 1536px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    display: "grid",
    gap: {
      [cssMediaMinWidth2]: spacing.m,
      default: spacing.s,
    },
  },
});

const stylesSize = stylex.create({
  gridTemplateColumns: (props: { size: CarouselGridSize }) => ({
    gridTemplateColumns: {
      [cssMediaMinWidth1]: `repeat(${props.size[1]}, minmax(0, 1fr))`,
      [cssMediaMinWidth2]: `repeat(${props.size[2]}, minmax(0, 1fr))`,
      [cssMediaMinWidth3]: `repeat(${props.size[3]}, minmax(0, 1fr))`,
      [cssMediaMinWidth4]: `repeat(${props.size[4]}, minmax(0, 1fr))`,
      [cssMediaMinWidth5]: `repeat(${props.size[5]}, minmax(0, 1fr))`,
      default: `repeat(${props.size[0]}, minmax(0, 1fr))`,
    },
  }),
});

export type GridProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  size?: CarouselApi["size"];
};

export const Grid = forwardRef<any, GridProps>(
  ({ asChild = false, children, size = "m", styleXArray, ...props }, ref) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });
    const determinedCarouselGridSize =
      CAROUSEL_GRID_SIZES[size as keyof typeof CAROUSEL_GRID_SIZES] ??
      (size as CarouselGridSize);

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          stylesSize.gridTemplateColumns({ size: determinedCarouselGridSize }),
          styleXArray,
        )}
      >
        {children}
      </DeterminedElement>
    );
  },
);

Grid.displayName = "Grid";
