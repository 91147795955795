"use client";

import type { MediaSizes } from "@packages/media";
import { usePlayer } from "@packages/media";
import * as Toolbar from "@radix-ui/react-toolbar";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";

import {
  numericPercentages,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { ResumeButton, Scrubber } from "../../controls";
import { PlaybackControls } from "../PlaybackControls";

const playerControlsContainerStyles = stylex.create({
  "collapsed-desktop": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    gridArea: "playback",
    justifyContent: "center",
    rowGap: spacing.xxs,
  },
  "collapsed-mobile": {},
  "expanded-desktop": {
    width: numericPercentages[100],
  },
  "expanded-mobile": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    rowGap: spacing.ml,
    width: numericPercentages[100],
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

const playerButtonStyles = stylex.create({
  "collapsed-desktop": {
    alignItems: "center",
    columnGap: spacing.l,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    padding: spacing.s,
  },
  "collapsed-mobile": {},
  "expanded-desktop": {
    alignItems: "center",
    columnGap: spacing.l,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    padding: spacing.s,
  },
  "expanded-mobile": {
    alignItems: "center",
    columnGap: spacing.l,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

const scrubberStyles = stylex.create({
  scrubber: {
    position: "relative",
  },
});

export type MediaControlsProps = {
  size: MediaSizes;
  onClose: MouseEventHandler<HTMLButtonElement>;
};

export const MediaControls = ({ size, onClose }: MediaControlsProps) => {
  const player = usePlayer();

  const preventExpanding: MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  return (
    <div
      key={"mediaPlayerControls"}
      {...stylex.props(playerControlsContainerStyles[size])}
      onClick={preventExpanding}
    >
      {["expanded-mobile", "expanded-desktop"].includes(size) ? (
        <Scrubber
          durationS={player?.duration ?? 0}
          elapsedS={player?.currentTime ?? 0}
          jumpTo={(t) => player?.setTime(t)}
          showTime={"under"}
          styleXArray={[scrubberStyles.scrubber]}
        >
          <ResumeButton Wrapper={Toolbar.Button} />
        </Scrubber>
      ) : null}

      <div
        key={"playerButtonContainer"}
        {...stylex.props(playerButtonStyles[size])}
      >
        <PlaybackControls
          Wrapper={Toolbar.Button}
          size={size}
          onClose={onClose}
        />
      </div>

      {size === "collapsed-desktop" ? (
        <Scrubber
          durationS={player?.duration ?? 0}
          elapsedS={player?.currentTime ?? 0}
          jumpTo={(t) => player?.setTime(t)}
          showTime={
            size === "collapsed-desktop"
              ? "beside"
              : player?.display === "expanded"
                ? "under"
                : false
          }
        />
      ) : null}
    </div>
  );
};
