"use client";

import * as RadixUIDialog from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";
import { useRouter } from "next/navigation";
import type { ComponentProps } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { IconButton } from "../../_core";
import { Text } from "../../_core";
import { CloseIcon } from "../../icons";

const styles = stylex.create({
  base: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: spacing.xl,
  },
  title: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: spacing.m,
    marginTop: spacing.none,
    width: spacing.full,
  },
});

export type ModalHeaderProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Title> & {
    showClose?: boolean;
    titleStyleXArray?: StyleXArray;
    type?: "title" | "headline";
  }
>;

export const ModalHeader = ({
  className,
  onClick,
  children,
  showClose = false,
  styleXArray = [],
  titleStyleXArray = [],
  type = "title",
  ...props
}: ModalHeaderProps) => {
  const router = useRouter();

  return (
    <div {...stylex.props(styles.base, styleXArray)}>
      <Text asChild size="m" type={type}>
        <RadixUIDialog.Title
          {...props}
          {...stylex.props(styles.title, titleStyleXArray)}
        >
          {children}
        </RadixUIDialog.Title>
      </Text>
      {showClose && (
        <IconButton
          icon={<CloseIcon />}
          onClick={() => router.back()}
          variant={"neutral"}
        />
      )}
    </div>
  );
};
