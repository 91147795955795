"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useState } from "react";
import { useFocus, useHover, usePress } from "react-aria";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
} from "../../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { HallowImage } from "../../../../blocks";
import { Text } from "../../../_core";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    minWidth: spacing.none,
  },
  illustrationImg: {
    "-webkit-user-drag": "none",
    borderBottomLeftRadius: spacing.s,
    borderTopRightRadius: spacing.s,
    bottom: spacing.none,
    height: spacing.xxl,
    left: spacing.none,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    userSelect: "none",
    width: spacing.xxl,
  },
  img: (props: { color: string }) => ({
    "-webkit-user-drag": "none",
    borderColor: props.color,
    borderRadius: radius.s,
    borderStyle: "solid",
    borderWidth: stroke.heavy,
    height: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    userSelect: "none",
    width: spacing.full,
  }),
  imgContainer: {
    "-webkit-user-drag": "none",
    paddingBottom: "70%",
    position: "relative",
    userSelect: "none",
    width: spacing.full,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    minWidth: spacing.none,
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
  },
  infoTopContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.t,
  },
  metadata: {
    color: semanticColors.neutralsMedium,
  },
});

export type CampaignMediumProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  activeState?: boolean;
  color: string;
  description: string;
  focusState?: boolean;
  hoverState?: boolean;
  illustrationImgSrc: string;
  imgSrc: Images;
  metadata: string;
  title: string;
};

export const CampaignMedium = forwardRef<any, CampaignMediumProps>(
  (
    {
      activeState = true,
      asChild = false,
      children,
      color,
      description,
      focusState = true,
      hoverState = true,
      illustrationImgSrc,
      imgSrc,
      metadata,
      title,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    const { isPressed: active, pressProps: activeProps } = usePress({});
    const { focusProps } = useFocus({
      onFocusChange: (focus) => setFocus(focus),
    });
    const { isHovered: hover, hoverProps } = useHover({});

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="imgContainer" {...stylex.props(styles.imgContainer)}>
          <HallowImage
            alt={title}
            src={imgSrc}
            size={"m"}
            {...stylex.props(
              styles.img({ color }),
              stylesOutline.base,
              focus && focusState ? stylesOutline.focusControlled : null,
              hover && hoverState ? stylesOutline.hoverControlled : null,
            )}
          />
          <img
            alt={title}
            src={illustrationImgSrc}
            {...stylex.props(styles.illustrationImg)}
          />
        </div>,
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          <div {...stylex.props(styles.infoTopContainer)}>
            <Text overflow="ellipsis" size="l" type="title">
              {title}
            </Text>
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          </div>
          <Text
            overflow="ellipsis"
            size="l"
            styleXArray={[styles.metadata]}
            type="detail"
          >
            {metadata}
          </Text>
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...activeProps}
        {...focusProps}
        {...hoverProps}
        {...deepMergeStyleXProps({
          object1: stylex.props(
            styles.determinedElement,
            activeState ? stylesOpacity.active : null,
            activeState ? stylesOpacity.base : null,
            activeState && hoverState ? stylesScale.base : null,
            activeState && hoverState ? stylesScale.m : null,
          ),
          object2: props,
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

CampaignMedium.displayName = "CampaignMedium";
