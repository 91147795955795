"use client";

import * as RadixUIDialog from "@radix-ui/react-dialog";
import * as stylex from "@stylexjs/stylex";
import { type ComponentProps, type ReactNode } from "react";

import {
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { useTheme } from "../../../../theme";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { IconButton } from "../../_core";
import { CloseIcon } from "../../icons";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  close: {
    marginLeft: "auto",
  },
  content: {
    backgroundColor: semanticColors.background,
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    height: "100vh",
    left: 0,
    overflowY: "auto",
    paddingBottom: spacing.m,
    paddingLeft: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
    paddingTop: spacing.m,
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: zIndices.modalOverModal,
  },
});

export type FullscreenModalProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Root>
> & {
  closeStyleXArray?: StyleXArray;
  contentProps?: ComponentProps<typeof RadixUIDialog.Content>;
  contentStyleXArray?: StyleXArray;
  portalProps?: ComponentProps<typeof RadixUIDialog.Portal>;
  trigger?: ReactNode;
  showCloseButton?: boolean;
};

export const FullscreenModal = ({
  children,
  closeStyleXArray = [],
  contentProps,
  contentStyleXArray = [],
  portalProps,
  styleXArray = [],
  trigger,
  showCloseButton = true,
  ...props
}: FullscreenModalProps) => {
  const { themeContainerRef } = useTheme();

  return (
    <RadixUIDialog.Root {...props} {...stylex.props(styleXArray)}>
      <RadixUIDialog.Trigger asChild>{trigger}</RadixUIDialog.Trigger>
      <RadixUIDialog.Overlay />
      <RadixUIDialog.Portal
        container={themeContainerRef.current}
        {...portalProps}
      >
        <RadixUIDialog.Content
          {...contentProps}
          {...stylex.props(styles.content, contentStyleXArray)}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          {showCloseButton && (
            <RadixUIDialog.Close asChild>
              <IconButton
                icon={<CloseIcon />}
                styleXArray={[styles.close, closeStyleXArray]}
                variant="transparentPrimary"
              />
            </RadixUIDialog.Close>
          )}
          {children}
        </RadixUIDialog.Content>
      </RadixUIDialog.Portal>
    </RadixUIDialog.Root>
  );
};
