import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    height: spacing.full,
    minWidth: 0,
    width: spacing.full,
  },
});

export type GridItemProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
>;

export const GridItem = forwardRef<any, GridItemProps>(
  ({ asChild = false, children, styleXArray, ...props }, ref) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.determinedElement, styleXArray)}
      >
        {children}
      </DeterminedElement>
    );
  },
);

GridItem.displayName = "GridItem";
