"use client";

import { useFormatter, useTranslations } from "@packages/i18n";
import { useRequestCommunitiesAdminStats } from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useErrorNotification } from "../../../../../hooks/useErrorNotification";
import { StatisticCard } from "../../../../_base";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  totalStatsCardsContainer: {
    display: "grid",
    gap: spacing.m,
    gridTemplateColumns: {
      [cssMediaMinWidthDesktop]: "repeat(4, minmax(0, 1fr))",
      default: "repeat(2, minmax(0, 1fr))",
    },
    paddingVertical: spacing.l,
  },
});

export type CommunityTotalsStatisticCardsProps = { communityId: number };

export const CommunityTotalsStatisticCards = ({
  communityId,
}: CommunityTotalsStatisticCardsProps) => {
  const { query: communityStatsQuery } = useRequestCommunitiesAdminStats({
    id: communityId,
  });
  const format = useFormatter();
  const t = useTranslations();

  useErrorNotification({ isError: communityStatsQuery.isError });

  const cardLoadingState =
    communityStatsQuery.isLoading || communityStatsQuery.isError;

  return (
    <div {...stylex.props(styles.totalStatsCardsContainer)}>
      <StatisticCard
        value={format.number(communityStatsQuery.data?.prayers_completed)}
        label={t("parish_admin_dashboard_prayers_completed")}
        loading={cardLoadingState}
      />
      <StatisticCard
        value={format.number(communityStatsQuery.data?.total_minutes_prayed)}
        label={t("total_minutes_prayed")}
        loading={cardLoadingState}
      />
      <StatisticCard
        value={format.number(communityStatsQuery.data?.member_count)}
        label={t("total_members")}
        loading={cardLoadingState}
      />
      <StatisticCard
        value={format.number(communityStatsQuery.data?.intentions_count)}
        label={t("total_intentions")}
        loading={cardLoadingState}
      />
    </div>
  );
};

CommunityTotalsStatisticCards.displayName = "CommunityTotalsStatisticCards";
