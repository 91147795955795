"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { Fragment } from "react";

import { radius, spacing } from "../../../../../../global/stylex/vars.stylex";
import type { BlockProps } from "../../../../refactor";
import { Block } from "../../../../refactor";
import type { TriviaMediumProps } from "../TriviaMedium";
import { TriviaMedium } from "../TriviaMedium";
import { TriviaScore } from "../TriviaScore";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  actions: {
    marginTop: spacing.m,
  },
  block: {
    display: {
      [cssMediaMinWidthTablet]: "flex",
      default: "none",
    },
  },
  description: {
    paddingTop: spacing.s,
  },
  imgContainer: (props: { color: TriviaMediumProps["color"] }) => ({
    alignItems: "center",
    backgroundColor: props.color,
    borderBottomRightRadius: radius.l,
    borderTopRightRadius: radius.l,
    display: "flex",
    justifyContent: "center",
  }),
  triviaMedium: {
    display: {
      [cssMediaMinWidthTablet]: "none",
      default: "block",
    },
  },
});

export type TriviaLargeProps = Pick<
  TriviaMediumProps,
  | "activeState"
  | "centerActions"
  | "color"
  | "description"
  | "focusState"
  | "hoverState"
  | "imgSrc"
  | "questions"
  | "summary"
  | "tags"
  | "title"
> &
  Pick<BlockProps, "actions" | "descriptionProps">;

export const TriviaLarge = ({
  actions,
  activeState = false,
  centerActions,
  color = "black",
  description,
  descriptionProps,
  focusState = true,
  hoverState = true,
  imgSrc,
  questions,
  summary,
  tags,
  title,
}: TriviaLargeProps) => {
  const t = useTranslations();

  return (
    <Fragment>
      <Block
        actions={actions}
        actionsStyleXArray={[styles.actions]}
        activeState={activeState}
        color={color}
        description={description ?? t("trivia_sub_title")}
        descriptionProps={{
          ...descriptionProps,
          styleXArray: [styles.description, descriptionProps?.styleXArray],
        }}
        focusState={focusState}
        hoverState={hoverState}
        imgContainerStyleXArray={[styles.imgContainer({ color })]}
        imgContainerProps={{
          children:
            questions && summary ? (
              <TriviaScore
                color={color}
                questions={questions}
                size="l"
                summary={summary}
              />
            ) : null,
        }}
        imgSrc={questions && summary ? null : imgSrc}
        styleXArray={[styles.block]}
        tags={tags}
        title={title ?? t("trivia_section_title")}
      />
      <TriviaMedium
        activeState={activeState}
        centerActions={centerActions}
        color={color}
        description={description}
        focusState={focusState}
        hoverState={hoverState}
        imgSrc={imgSrc}
        questions={questions}
        summary={summary}
        styleXArray={[styles.triviaMedium]}
        tags={tags}
        title={title}
      />
    </Fragment>
  );
};
