"use client";

import * as stylex from "@stylexjs/stylex";
import { type ReactNode } from "react";

import { lineHeights, spacing } from "../../../../../global/stylex/vars.stylex";
import { useDynamicViewport } from "../../../../hooks";
import { VIEWPORT_TABLET } from "../../../../lib";
import type { DropdownMenuHeaderProps } from "../DropdownMenuHeader";
import { DropdownMenuHeader } from "../DropdownMenuHeader";
import type { ModalHeaderProps } from "../ModalHeader";
import { ModalHeader } from "../ModalHeader";

const defaultStyle = stylex.create({
  empty: {
    // default style for Modal Header, which we need to account for in visual space
    height: `calc(${spacing.xl} + ${spacing.m} + ${lineHeights.titleM})`,
  },
});

export type ModalDropdownMenuHeaderProps = {
  children?: ReactNode;
  dropdownMenuHeaderProps?: DropdownMenuHeaderProps;
  modalHeaderProps?: ModalHeaderProps;
  minDesktopWidth?: number;
};

export const ModalDropdownMenuHeader = ({
  children,
  dropdownMenuHeaderProps = {},
  modalHeaderProps = {},
  minDesktopWidth = VIEWPORT_TABLET,
}: ModalDropdownMenuHeaderProps) => {
  const isDesktopViewport = useDynamicViewport({
    minimumWidth: minDesktopWidth,
    defaultState: null,
  });

  // Radix throws errors if we render Dialog components outside Dialogs, but by defaulting
  // this media query to false, we always render Dialog components first, even if the parent
  // isn't a Dialog
  if (isDesktopViewport === null)
    return <div {...stylex.props(defaultStyle.empty)} />;

  if (isDesktopViewport)
    return (
      <DropdownMenuHeader {...dropdownMenuHeaderProps}>
        {dropdownMenuHeaderProps.children || children}
      </DropdownMenuHeader>
    );

  return (
    <ModalHeader {...modalHeaderProps}>
      {modalHeaderProps.children || children}
    </ModalHeader>
  );
};
