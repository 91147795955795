"use client";

import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { semanticColors } from "../../../../../global/stylex/vars.stylex";
import { Text } from "../../../_base/_core/Text";
import { useFormField } from "./FormField";

const styles = stylex.create({
  description: {
    color: semanticColors.neutralsMedium,
  },
});

export const FormDescription = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <Text
      ref={ref}
      type="detail"
      size="l"
      id={formDescriptionId}
      {...stylex.props(styles.description)}
      {...props}
    />
  );
});

FormDescription.displayName = "FormDescription";
