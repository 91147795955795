"use client";

import type { DependencyList, Dispatch, Ref, SetStateAction } from "react";
import { useEffect, useState } from "react";

export type UseIntersectionObserverRefStateProps = {
  deps?: DependencyList;
  ref: Ref<any>;
};

export const useIntersectionObserverRefState = ({
  deps,
  ref,
}: UseIntersectionObserverRefStateProps) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (ref["current"]) {
      const observer = new IntersectionObserver(([intersection]) => {
        setState(intersection.isIntersecting);
      });
      observer.observe(ref["current"]);
    }
  }, [deps]);

  return [state, setState] as [boolean, Dispatch<SetStateAction<boolean>>];
};
