"use client";

import type { ComponentProps } from "react";
import { useRef } from "react";

import { useScrollArea } from "../../../hooks";
import { scrollContext } from "./scrollContext";

export type ScrollContextProviderProps = Omit<
  ComponentProps<typeof scrollContext.Provider>,
  "value"
>;

export const ScrollContextProvider = ({
  children,
}: ScrollContextProviderProps) => {
  const viewportRef = useRef<HTMLDivElement>();

  const scrollArea = useScrollArea({ viewportRef });

  return (
    <scrollContext.Provider
      value={{
        ...scrollArea,
        viewportRef,
      }}
    >
      {children}
    </scrollContext.Provider>
  );
};
