import * as stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";
import { forwardRef } from "react";

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../types";

const Element: HallowElement = "textarea";

export type TextAreaProps = WithStylexArray<
  HallowElementProps<typeof Element>
> & { sublabel?: ReactNode };

const styles = stylex.create({
  container: {
    backgroundColor: "inherit",
    border: `${numericPixels[1]} solid ${semanticColors.neutralsVeryLow}`,
    borderRadius: radius.m,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    height: spacing.full,
    padding: spacing.m,
    width: spacing.full,
  },
  textarea: {
    background: "none",
    border: "none",
    boxShadow: "none",
    color: semanticColors.primary,
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyM,
    fontWeight: fontWeights.bodyM,
    lineHeight: lineHeights.bodyM,
    margin: "0",
    outline: "none",
    padding: "0",
    resize: "none",
    width: spacing.full,
    "::placeholder": {
      color: semanticColors.neutralsLower,
    },
  },
  sublabel: { color: semanticColors.neutralsMedHigh },
});

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ styleXArray, sublabel, ...props }, ref) => {
    return (
      <div {...stylex.props(styles.container, styleXArray)}>
        <textarea {...stylex.props(styles.textarea)} ref={ref} {...props} />
        {sublabel && <span {...stylex.props(styles.sublabel)}>{sublabel}</span>}
      </div>
    );
  },
);

TextArea.displayName = "TextArea";
