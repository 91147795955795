"use client";

import { useLocalStorageState } from "@packages/sdk";
import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

export type ConsentContext = {
  consent: number[];
  setConsent: Dispatch<SetStateAction<number[]>>;
};

export const consentContext = createContext<ConsentContext>({
  consent: [],
  setConsent: () => {},
});

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useLocalStorageState<number[]>({
    key: "consent",
  });

  return (
    <consentContext.Provider value={{ consent, setConsent }}>
      {children}
    </consentContext.Provider>
  );
};
