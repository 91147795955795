"use client";

import { useContext } from "react";

import { Theme } from "../types";
import { themeContext } from "./themeContext";

export const useTheme = () => {
  const {
    setTheme,
    theme,
    themeContainerRef,
    fontStyle,
    setFontStyle,
    letterSpacing,
    setLetterSpacing,
    wordSpacing,
    setWordSpacing,
  } = useContext(themeContext);

  const toggleTheme = () =>
    setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);

  return {
    setTheme,
    theme,
    themeContainerRef,
    toggleTheme,
    fontStyle,
    setFontStyle,
    letterSpacing,
    setLetterSpacing,
    wordSpacing,
    setWordSpacing,
  };
};
