import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { CAROUSEL_GRID_SIZES } from "../../../../../constants";
import type { CarouselApi } from "../../../../../hooks";
import type {
  CarouselGridSize,
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../../types";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidth2 = "@media (min-width: 768px)";
const confinedCssMediaMinWidth1 =
  "@media (min-width: 640px) and (max-width: 767px)";
const confinedCssMediaMinWidth2 =
  "@media (min-width: 768px) and (max-width: 1023px)";
const confinedCssMediaMinWidth3 =
  "@media (min-width: 1024px) and (max-width: 1263px)";
const confinedCssMediaMinWidth4 =
  "@media (min-width: 1264px) and (max-width: 1535px)";
const confinedCssMediaMinWidth5 = "@media (min-width: 1536px)";

const hallowElement: HallowElement = "div";

const getPositionStyles = ({
  isEnd,
  isStart,
}: {
  isEnd: boolean;
  isStart: boolean;
}) => {
  if (isEnd && isStart) return null;
  if (isEnd) return stylesPosition.end;
  if (isStart) return stylesPosition.start;
  return stylesPosition.base;
};

const styles = stylex.create({
  determinedElement: {
    flexShrink: 0,
    height: spacing.full,
    minWidth: spacing.none,
    scrollSnapAlign: "start",
  },
});

const stylesPosition = stylex.create({
  base: {
    marginLeft: {
      [cssMediaMinWidth2]: spacing.s,
      default: spacing.xxs,
    },
    marginRight: {
      [cssMediaMinWidth2]: spacing.s,
      default: spacing.xxs,
    },
  },
  end: {
    marginLeft: {
      [cssMediaMinWidth2]: spacing.s,
      default: spacing.xxs,
    },
  },
  start: {
    marginRight: {
      [cssMediaMinWidth2]: spacing.s,
      default: spacing.xxs,
    },
  },
});

/* ((100% / marginpx * (#columns -1)) / #columns) */
const stylesSize = stylex.create({
  width: (props: { size: CarouselGridSize }) => ({
    width: {
      [confinedCssMediaMinWidth1]:
        props.size[1] === 1
          ? spacing.full
          : `calc(calc(100% - calc(8px * ${props.size[1] - 1})) / ${props.size[1]})`,
      [confinedCssMediaMinWidth2]:
        props.size[2] === 1
          ? spacing.full
          : `calc(calc(100% - calc(16px * ${props.size[2] - 1})) / ${props.size[2]})`,
      [confinedCssMediaMinWidth3]:
        props.size[3] === 1
          ? spacing.full
          : `calc(calc(100% - calc(16px * ${props.size[3] - 1})) / ${props.size[3]})`,
      [confinedCssMediaMinWidth4]:
        props.size[4] === 1
          ? spacing.full
          : `calc(calc(100% - calc(16px * ${props.size[4] - 1})) / ${props.size[4]})`,
      [confinedCssMediaMinWidth5]:
        props.size[5] === 1
          ? spacing.full
          : `calc(calc(100% - calc(16px * ${props.size[5] - 1})) / ${props.size[5]})`,
      default:
        props.size[0] === 1
          ? spacing.full
          : `calc(calc(100% - calc(8px * ${props.size[0] - 1})) / ${props.size[0]})`,
    },
  }),
});

/* ((100% / #columns) - ((offsetpx + scroll-paddingpx - marginpx) / #columns) - marginpx) */
const stylesSizePageOffet = stylex.create({
  width: (props: { size: CarouselGridSize }) => ({
    width: {
      [confinedCssMediaMinWidth1]:
        props.size[1] === 1
          ? "calc(100% - calc(24px + 24px))"
          : `calc(calc(100% / ${props.size[1]}) - calc(calc(24px + 24px - 8px) / ${props.size[1]}) - 8px)`,
      [confinedCssMediaMinWidth2]:
        props.size[2] === 1
          ? "calc(100% - calc(48px + 48px))"
          : `calc(calc(100% / ${props.size[2]}) - calc(calc(48px + 48px - 16px) / ${props.size[2]}) - 16px)`,
      [confinedCssMediaMinWidth3]:
        props.size[3] === 1
          ? "calc(100% - calc(48px + 48px))"
          : `calc(calc(100% / ${props.size[3]}) - calc(calc(48px + 48px - 16px) / ${props.size[3]}) - 16px)`,
      [confinedCssMediaMinWidth4]:
        props.size[4] === 1
          ? "calc(100% - calc(48px + 48px))"
          : `calc(calc(100% / ${props.size[4]}) - calc(calc(48px + 48px - 16px) / ${props.size[4]}) - 16px)`,
      [confinedCssMediaMinWidth5]:
        props.size[5] === 1
          ? "calc(100% - calc(48px + 48px))"
          : `calc(calc(100% / ${props.size[5]}) - calc(calc(48px + 48px - 16px) / ${props.size[5]}) - 16px)`,
      default:
        props.size[0] === 1
          ? "calc(100% - calc(24px + 24px))"
          : `calc(calc(100% / ${props.size[0]}) - calc(calc(24px + 24px - 8px) / ${props.size[0]}) - 8px)`,
    },
  }),
});

export type CarouselItemProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
> & {
  carouselApi: CarouselApi;
  isEnd?: boolean;
  isStart?: boolean;
};

export const CarouselItem = forwardRef<any, CarouselItemProps>(
  (
    {
      carouselApi,
      children,
      isEnd = false,
      isStart = false,
      styleXArray,
      ...props
    },
    ref,
  ) => {
    const { pageOffset, size } = carouselApi;

    const DeterminedElement = hallowElement;
    const determinedCarouselGridSize =
      CAROUSEL_GRID_SIZES[size as keyof typeof CAROUSEL_GRID_SIZES] ??
      (size as CarouselGridSize);

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          getPositionStyles({ isEnd, isStart }),
          pageOffset
            ? stylesSizePageOffet.width({ size: determinedCarouselGridSize })
            : stylesSize.width({ size: determinedCarouselGridSize }),
          styleXArray,
        )}
      >
        {children}
      </DeterminedElement>
    );
  },
);

CarouselItem.displayName = "CarouselItem";
