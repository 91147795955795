import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../types";

const Element: HallowElement = "svg";

const styles = stylex.create({
  svg: (height, width) => ({
    height,
    width,
  }),
});

export type HallowLogoHaroldProps = WithStylexArray<
  Omit<HallowElementProps<typeof Element>, "type">
>;

export const HallowLogoHarold = forwardRef<any, HallowLogoHaroldProps>(
  (
    {
      fill = "currentColor",
      height = 24,
      styleXArray = [],
      width = 20,
      ...props
    },
    ref,
  ) => (
    <svg
      fill="none"
      ref={ref}
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...stylex.props(styles.svg(height, width), styleXArray)}
    >
      <g>
        <path
          d="M9.70635 4.72595C4.29853 4.72595 0 9.02448 0 14.3283C0 19.6321 4.29853 24 9.70635 24C15.1142 24 19.378 19.7015 19.378 14.3283C19.378 8.95515 14.9755 4.72595 9.70635 4.72595Z"
          fill={fill}
        />
        <path
          d="M4.60495 3.29805C6.0044 3.38437 7.84687 3.36253 9.78052 3.19336C11.7145 3.0242 13.5327 2.72607 14.8958 2.39779C16.837 1.9149 17.2496 1.46113 17.2104 1.01221C17.1892 0.770589 17.0329 0.575422 16.7791 0.458252C16.835 0.697098 16.6426 0.887758 16.2779 1.09367C15.1807 1.67675 12.4706 2.12289 9.70772 2.36451C6.94487 2.60613 4.19867 2.63768 3.01692 2.25393C2.62208 2.11457 2.39953 1.95996 2.41305 1.71522C2.18356 1.87434 2.06327 2.09377 2.08442 2.33539C2.12359 2.78431 2.60891 3.15939 4.6046 3.29805H4.60495Z"
          fill={fill}
        />
        <path
          d="M16.7789 0.458242C16.4156 0.281101 15.7757 0.128573 14.69 0.0495353C13.2905 -0.0367819 11.4481 -0.0149426 9.51442 0.154225C7.58043 0.323393 5.76222 0.621517 4.39917 0.9498C3.3436 1.21603 2.74042 1.47776 2.41318 1.71521C2.39966 1.95995 2.62221 2.11456 3.01705 2.25392C3.68055 1.88265 5.89187 1.30651 9.58722 0.983079C13.2822 0.659649 15.5601 0.843376 16.278 1.09366C16.6427 0.887748 16.8347 0.697088 16.7793 0.458242H16.7789Z"
          fill={fill}
          opacity="0.5"
        />
      </g>
    </svg>
  ),
);

HallowLogoHarold.displayName = "HallowLogoHarold";
