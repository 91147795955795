import type { LSFontFamily } from "@packages/sdk";
import type { Theme } from "@stylexjs/stylex";
import { createTheme } from "@stylexjs/stylex";

import { fontFamilies } from "./vars.stylex";

export const defaultFonts = createTheme(fontFamilies, fontFamilies);

export const serifFonts = createTheme(fontFamilies, {
  sansSerif: "serif",
  serif: "serif",
});

export const sansSerifFonts = createTheme(fontFamilies, {
  sansSerif: "sans-serif",
  serif: "sans-serif",
});

export const fontThemes: Record<LSFontFamily, Theme<typeof fontFamilies>> = {
  normal: defaultFonts,
  serif: serifFonts,
  sans_serif: sansSerifFonts,
};
