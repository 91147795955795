"use client";

import * as stylex from "@stylexjs/stylex";
import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";

import {
  numericPercentages,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../../types";
import { Slider, Time } from "../../../primitives";

const styles = stylex.create({
  container: {
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    placeContent: "flex-start",
    placeItems: "center",
    width: numericPercentages[100],
  },
  onCollapsedPlayer: {
    width: "auto",
  },
  onExpandedPlayer: {
    flexDirection: "column",
    placeContent: "flex-start",
    placeItems: "center",
    rowGap: spacing.s,
  },
  underTimer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: numericPercentages[100],
  },
});

export type ScrubberProps = PropsWithChildren<
  WithStylexArray<{
    durationS: number;
    elapsedS: number;
    showTime?: "under" | "beside" | false;
    jumpTo: (newTime: number) => void;
  }>
>;

export const Scrubber = ({
  durationS,
  elapsedS,
  showTime = false,
  jumpTo,
  styleXArray,
  children,
}: ScrubberProps) => {
  const [sliderValue, setSliderValue] = useState<number>(
    (elapsedS / durationS) * 100,
  );
  const [inMotion, setInMotion] = useState<boolean>(false);
  const [displayElapsedS, setDisplayElapsedS] = useState<number>(elapsedS);
  const [displayRemainingS, setDisplayRemainingS] = useState<number>(
    durationS - elapsedS,
  );

  useEffect(() => {
    if (!inMotion) {
      setSliderValue((elapsedS / durationS) * 100);
      setDisplayElapsedS(elapsedS);
      setDisplayRemainingS(Math.max(durationS - elapsedS, 0));
    }
  }, [elapsedS, durationS]);

  const handleMove = ([newPercent]: [number]) => {
    setInMotion(true);
    setSliderValue(newPercent);
    setDisplayElapsedS((newPercent / 100) * durationS);
    setDisplayRemainingS(
      Math.max(durationS - (newPercent / 100) * durationS, 0),
    );
  };

  const handleCommit = ([newPercent]: [number]) => {
    setSliderValue(newPercent);
    jumpTo((newPercent / 100) * durationS);
    setInMotion(false);
  };

  return (
    <div
      {...stylex.props(
        styles.container,
        showTime === "beside" ? styles.onCollapsedPlayer : null,
        showTime === "under" ? styles.onExpandedPlayer : null,
        styleXArray,
      )}
    >
      {showTime === "beside" ? <Time timeS={displayElapsedS} /> : null}
      <Slider
        size={showTime === "beside" ? "small" : "large"}
        onValueChange={handleMove}
        onValueCommit={handleCommit}
        value={sliderValue}
        step={0.001}
      />
      {showTime === "beside" ? (
        <Time timeS={displayRemainingS} negative={true} />
      ) : null}
      {showTime === "under" ? (
        <div {...stylex.props(styles.underTimer)}>
          <Time timeS={displayElapsedS} />
          {children}
          <Time timeS={displayRemainingS} negative={true} />
        </div>
      ) : null}
    </div>
  );
};
