import stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { IconElement } from "../../icons";

const styles = stylex.create({
  notice: {
    borderRadius: spacing.s,
    display: "flex",
    gap: spacing.s,
    marginBottom: spacing.m,
    padding: spacing.m,
    ":not(#__unused__) a": {
      textDecoration: "underline",
    },
  },
  plain: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.primary,
  },
  info: {
    backgroundColor: semanticColors.accentLowest,
    color: semanticColors.accentHigh,
  },
  error: {
    backgroundColor: semanticColors.errorLowest,
    color: semanticColors.errorMedium,
  },
});

export interface NoticeProps extends React.PropsWithChildren {
  variant?: "plain" | "info" | "error";
  icon?: IconElement;
  body?: React.ReactNode;
}

export const Notice = ({ variant = "info", ...props }: NoticeProps) => {
  return (
    <div {...stylex.props(styles.notice, styles[variant])}>
      {props.icon && props.icon}
      <p className="notice-body">{props.body ?? props.children}</p>
    </div>
  );
};

Notice.displayName = "Notice";
