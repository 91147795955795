"use client";

import { useLocale } from "@packages/i18n";
import { toLocaleUTCDateString } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import { Text } from "../../../_base";

const styles = stylex.create({
  dateWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

type StackedDate = {
  date?: Date;
  month?: string;
  day?: string;
};

export const StackedDate = ({
  date = new Date(),
  month = "short",
  day = "numeric",
}: StackedDate) => {
  const locale = useLocale();

  const localeDate = toLocaleUTCDateString(date, locale, {
    month,
    day,
  });

  const dateWords = localeDate.split(" ");
  const dateMonth = (
    <Text type="title" size="xs">
      {dateWords[0]}
    </Text>
  );
  const dateDay = (
    <Text type="title" size="s">
      {dateWords[1]}
    </Text>
  );

  return (
    <span {...stylex.props(styles.dateWrapper)}>
      {dateMonth}
      {dateDay}
    </span>
  );
};
