import { cloneElement, Fragment } from "react";

export type DetermineChildrenInjectProps = {
  afterChildrenInject?: (JSX.Element | null)[] | null;
  beforeChildrenInject?: (JSX.Element | null)[] | null;
  children: JSX.Element;
};

export const determineChildrenInject = ({
  afterChildrenInject = null,
  beforeChildrenInject = null,
  children,
}: DetermineChildrenInjectProps) => {
  const getEligibleChildren = () => {
    // If this is a nested "asChild" component that also used determineChildrenInject,
    // the children will be the return array of [beforeChildrenInject, children, afterChildren].
    // In this case, find the first eligble child in that array.
    if (Array.isArray(children)) {
      const childrenChild = children.find(
        (child) => !Array.isArray(child) && child !== null,
      );
      if (childrenChild) return childrenChild;

      const childrenArray = children.find((child) =>
        Array.isArray(child)
          ? child.find((childChild) => childChild !== null && childChild?.props)
          : null,
      );
      if (childrenArray)
        return childrenArray.find(
          (child: any) => child !== null && child?.props,
        );
    }

    // If children is a regular ReactNode, it will have "props" and can be used as the Slot's children.
    if (children?.props) return children;

    // If children is simply a number, string, etc. then it must be wrapped in a Fragment before being passed to Slot.
    return <Fragment>{children}</Fragment>;
  };

  // Cloning the children allows "injection" before and after while preserving the original children
  // Ex. <Button asChild startIcon="icon"><Link ... /></Button>
  // Without cloning, the <Button> would render as an <svg /> (because of the "startIcon" prop)
  // With cloning, we "clone" the <Link /> and inject the <svg /> inside of it, allowing the <Button /> to render as a <Link />
  return cloneElement(getEligibleChildren(), children?.props ?? {}, [
    beforeChildrenInject,
    children?.props?.children ??
      (typeof children === "string" ? children : null),
    afterChildrenInject,
  ]);
};
