import { getTranslations } from "@packages/i18n/server";
import stylex from "@stylexjs/stylex";
import Link from "next/link";

import {
  fontFamilies,
  fontSizes,
  numericPercentages,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { Button, Text } from "../../../_base";

// Importing pseudo-classes from another file is currently not supported by stylex
const cssMediaIsDesktopViewport = "@media (min-width: 768px)";

const styles = stylex.create({
  mainContainer: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "column",
    gap: spacing.l,
    justifyContent: "center",
    paddingHorizontal: {
      [cssMediaIsDesktopViewport]: spacing.xxl,
      default: spacing.xl,
    },
    paddingVertical: {
      [cssMediaIsDesktopViewport]: 160,
      default: 56,
    },
    width: numericPercentages[100],
  },
  contentContainer: {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    justifyContent: "center",
    marginHorizontal: "auto",
    maxWidth: {
      [cssMediaIsDesktopViewport]: "560px",
      default: numericPercentages[100],
    },
    textAlign: "center",
  },
  peaceText: {
    fontFamily: fontFamilies.serif,
    fontWeight: "400",
  },
  titleText: {
    fontSize: {
      [cssMediaIsDesktopViewport]: fontSizes.displayL,
      default: fontSizes.displayM,
    },
    margin: 0,
  },
  subtitleText: {
    fontSize: {
      [cssMediaIsDesktopViewport]: fontSizes.bodyXL,
      default: fontSizes.bodyL,
    },
    margin: 0,
  },
});

export const HomeHeader = async () => {
  const t = await getTranslations();

  return (
    <div {...stylex.props(styles.mainContainer)}>
      <div {...stylex.props(styles.contentContainer)}>
        <Text as="h1" type="display" size="l" styleXArray={[styles.titleText]}>
          {t.rich("web_homepage_title_gods_peace", {
            serif: (chunks) => (
              <span {...stylex.props(styles.peaceText)}>
                {chunks}
                <br />
              </span>
            ),
          })}
        </Text>
        <Text as="h2" type="body" size="xl" styleXArray={[styles.subtitleText]}>
          {t("web_homepage_subtitle")}
        </Text>
      </div>
      <Button size="l">
        <Link href={`https://hallow.app.link/FFwpmzvn4Fb`}>
          {t("try_hallow_for_free")}
        </Link>
      </Button>
    </div>
  );
};
