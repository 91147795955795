"use client";

import * as RadixUiToggleGroup from "@radix-ui/react-toggle-group";
import stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import type { ButtonProps } from "../../../_base";
import { Button } from "../../../_base";
import type { TabGroupProps } from "../TabGroup";

const styles = stylex.create({
  itemButton: {
    "-webkit-tap-highlight-color": "transparent",
    marginRight: spacing.xs,
  },
  itemButtonDisabledWhenSingleOnPRIMARY: {
    backgroundColor: semanticColors.primary,
    color: semanticColors.secondary,
    cursor: "pointer",
    opacity: 1,
  },
  itemButtonDisabledWhenSingleOnSELECT: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.primary,
    cursor: "pointer",
    opacity: 1,
  },
});

export type TabGroupItemProps = WithStylexArray<
  ComponentProps<typeof RadixUiToggleGroup.Item> & {
    disabledWhenSingle?: boolean;
    onClick?: ButtonProps["onClick"];
    parentState: TabGroupProps["value"];
    variant?: "primary" | "select";
  }
>;

export const TabGroupItem = ({
  children,
  disabledWhenSingle = true,
  onClick,
  parentState,
  styleXArray = [],
  value,
  variant = "primary",
}: TabGroupItemProps) => {
  const toggleGroupItemOn =
    parentState === value || parentState?.includes(value);

  return (
    <RadixUiToggleGroup.Item
      key={`tab-group-item-${children}`}
      aria-label={value}
      asChild
      value={value}
      disabled={disabledWhenSingle && parentState === value}
    >
      <Button
        onClick={onClick}
        size="s"
        styleXArray={[
          styles.itemButton,
          disabledWhenSingle && toggleGroupItemOn
            ? styles[`itemButtonDisabledWhenSingleOn${variant.toUpperCase()}`]
            : null,
          styleXArray,
        ]}
        variant={
          variant === "select"
            ? "transparentPrimary"
            : toggleGroupItemOn
              ? "primary"
              : "secondary"
        }
      >
        {children}
      </Button>
    </RadixUiToggleGroup.Item>
  );
};
