"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, Fragment } from "react";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
} from "../../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { HallowImage } from "../../../../blocks";
import { Mask, type MaskProps, Tag, Text } from "../../../_core";
import { CheckmarkIcon } from "../../../icons";
import type { CampaignLargeMobileProps } from "../CampaignLargeMobile";
import { CampaignLargeMobile } from "../CampaignLargeMobile";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  actionsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  campaignLargeMobile: {
    display: {
      [cssMediaMinWidthTablet]: "none",
      default: "flex",
    },
  },
  determinedElement: (props: { color: string }) => ({
    backgroundColor: props.color,
    borderColor: props.color,
    borderRadius: radius.l,
    borderStyle: "solid",
    borderWidth: 8,
    boxSizing: "border-box",
    display: {
      [cssMediaMinWidthTablet]: "flex",
      default: "none",
    },
    height: 480,
  }),
  illustrationImg: {
    "-webkit-user-drag": "none",
    borderRadius: spacing.s,
    height: 104,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    userSelect: "none",
    width: 104,
  },
  imgContainer: {
    flex: 3,
    position: "relative",
  },
  img: {
    "-webkit-user-drag": "none",
    borderBottomRightRadius: radius.m,
    borderTopRightRadius: radius.m,
    height: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    userSelect: "none",
    width: spacing.full,
  },
  infoContainer: {
    boxSizing: "border-box",
    color: semanticColors.onColorHighest,
    display: "flex",
    flex: 2,
    flexDirection: "column",
    gap: spacing.l,
    justifyContent: "center",
    minWidth: 384,
    paddingLeft: 80,
  },
  infoTopContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  infoTopTopContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.ms,
  },
  mask: {
    left: -1,
    position: "absolute",
  },
  tagJoined: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    maxWidth: 24,
  },
  tagsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
});

export type CampaignLargeProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  actions?: KeyedElement | KeyedElement[];
  activeState?: boolean;
  campaignLargeMobileProps?: Partial<CampaignLargeMobileProps>;
  color: string;
  description: string;
  focusState?: boolean;
  hoverState?: boolean;
  illustrationImgSrc?: string;
  imgSrc?: Images;
  joined?: boolean;
  tags: string[];
  title: string;
};

export const CampaignLarge = forwardRef<any, CampaignLargeProps>(
  (
    {
      actions,
      activeState = false,
      asChild = false,
      campaignLargeMobileProps = {},
      children,
      color,
      description,
      focusState = true,
      hoverState = true,
      illustrationImgSrc,
      imgSrc,
      joined,
      tags,
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          <div {...stylex.props(styles.infoTopContainer)}>
            <div {...stylex.props(styles.infoTopTopContainer)}>
              <img
                alt={title}
                src={illustrationImgSrc}
                {...stylex.props(styles.illustrationImg)}
              />
              <Text overflow="ellipsis" size="m" type="title">
                {title}
              </Text>
            </div>
            <Text lineClamp={2} size="l" type="headline">
              {description}
            </Text>
            <div {...stylex.props(styles.tagsContainer)}>
              {tags.map((tag) => (
                <Tag key={tag} variant="lighten">
                  {tag}
                </Tag>
              ))}
              {joined && (
                <Tag variant="lighten" styleXArray={[styles.tagJoined]}>
                  <CheckmarkIcon.SimpleOn size={12} />
                </Tag>
              )}
            </div>
          </div>
          {actions && (
            <div key="actions" {...stylex.props(styles.actionsContainer)}>
              {actions}
            </div>
          )}
        </div>,
        <div key="imgContainer" {...stylex.props(styles.imgContainer)}>
          <Mask
            color={color as MaskProps["color"]}
            direction="to right"
            orientation="vertical"
            styleXArray={[styles.mask]}
          />
          <HallowImage
            alt={title}
            src={imgSrc}
            size={"l"}
            {...stylex.props(styles.img)}
          />
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <Fragment>
        <CampaignLargeMobile
          activeState={activeState}
          asChild={asChild}
          children={children}
          color={color}
          description={description}
          focusState={focusState}
          hoverState={hoverState}
          illustrationImgSrc={illustrationImgSrc}
          imgSrc={imgSrc}
          joined={joined}
          ref={ref}
          tags={tags}
          title={title}
          {...deepMergeStyleXProps({
            object1: stylex.props(styles.campaignLargeMobile),
            object2: campaignLargeMobileProps,
          })}
        />
        <DeterminedElement
          ref={ref}
          {...deepMergeStyleXProps({
            object1: stylex.props(
              styles.determinedElement({ color }),
              activeState ? stylesOpacity.active : null,
              activeState ? stylesOpacity.base : null,
              stylesOutline.base,
              focusState ? stylesOutline.focus : null,
              hoverState ? stylesOutline.hover : null,
              activeState && hoverState ? stylesScale.base : null,
              activeState && hoverState ? stylesScale.s : null,
            ),
            object2: props,
          })}
        >
          {determinedChildren}
        </DeterminedElement>
      </Fragment>
    );
  },
);

CampaignLarge.displayName = "CampaignLarge";
