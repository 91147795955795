"use client";

import * as RadixUiToggleGroup from "@radix-ui/react-toggle-group";
import stylex from "@stylexjs/stylex";
import type { ComponentProps, Dispatch, SetStateAction } from "react";

import type { WithStylexArray } from "../../../../types";
import type { ScrollAreaProps } from "../ScrollArea";
import { ScrollArea } from "../ScrollArea";

const styles = stylex.create({
  container: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    scrollbarWidth: "none",
  },
});

export type TabGroupProps = WithStylexArray<
  ComponentProps<typeof RadixUiToggleGroup.Root> & {
    scrollAreaProps?: ScrollAreaProps;
    setParentState?: Dispatch<
      SetStateAction<ComponentProps<typeof RadixUiToggleGroup.Root>["value"]>
    >;
  }
>;

export const TabGroup = ({
  children,
  scrollAreaProps,
  setParentState,
  styleXArray = [],
  ...props
}: TabGroupProps) => (
  <ScrollArea
    maskLeft
    maskRight
    masksSize="s"
    vertical={false}
    {...scrollAreaProps}
  >
    <RadixUiToggleGroup.Root
      onValueChange={(value: TabGroupProps["value"]) => {
        if (setParentState) setParentState(value);
      }}
      {...stylex.props(styles.container, styleXArray)}
      {...props}
    >
      {children}
    </RadixUiToggleGroup.Root>
  </ScrollArea>
);
