"use client";

import type { MediaSizes } from "@packages/media";
import { usePlayer } from "@packages/media";
import { Button as ToolbarButton } from "@radix-ui/react-toolbar";
import * as stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { VolumeControl } from "../../controls";
import { SettingsControls } from "../SettingsControls";

const settingsContainerStyles = stylex.create({
  "collapsed-desktop": {
    alignItems: "center",
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gridArea: "settings",
    justifyContent: "flex-end",
    paddingHorizontal: spacing.m,
  },
  "collapsed-mobile": {
    display: "none",
  },
  "expanded-desktop": {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    gridArea: "controls",
    rowGap: spacing.m,
  },
  "expanded-mobile": {},
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

export type SettingsContainerProps = { size: MediaSizes };

export const SettingsContainer = ({ size }: SettingsContainerProps) => {
  const player = usePlayer();

  return (
    <div
      key={"settingsContainer"}
      {...stylex.props(settingsContainerStyles[size])}
    >
      <SettingsControls
        Wrapper={ToolbarButton}
        withVolume={size === "expanded-desktop"}
        size={size === "expanded-mobile" ? "s" : "l"}
      />
      {size === "collapsed-desktop" ? (
        <VolumeControl
          iconColor={semanticColors.onColorVeryHigh}
          value={player?.volume ?? 1}
          onVolumeChange={(v) => player?.setVolume(v)}
          onOverlay={true}
          Wrapper={ToolbarButton}
        />
      ) : null}
    </div>
  );
};
