import * as stylex from "@stylexjs/stylex";

import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

const Element: HallowElement = "div";

const styles = stylex.create({
  element: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
});

export type FullscreenModalFooterProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof Element>>
>;

export const FullscreenModalFooter = ({
  asChild = false,
  children,
  styleXArray = [],
  ...props
}: FullscreenModalFooterProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement: Element,
  });

  return (
    <DeterminedElement
      {...props}
      {...stylex.props(styles.element, styleXArray)}
    >
      {children}
    </DeterminedElement>
  );
};
