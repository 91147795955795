import { useLocale, useNow } from "@packages/i18n";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

export type UseCountdownProps = {
  targetDate: string;
  format?: "days" | "hours" | "minutes" | "seconds" | "default";
};

export const useCountdown = ({
  targetDate,
  format = "default",
}: UseCountdownProps) => {
  const now = useNow({ updateInterval: 1000 });
  const target = new Date(targetDate);
  const difference = differenceInSeconds(target, new Date(now));
  const locale = useLocale();

  const formatCountdown = () => {
    if (difference > 0) {
      const days = differenceInDays(target, now);
      const hours = differenceInHours(target, now);
      const minutes = differenceInMinutes(target, now);
      const seconds = difference % 60;

      switch (format) {
        case "days":
          return days;
        case "hours":
          return hours;
        case "minutes":
          return minutes;
        case "seconds":
          return seconds;
      }

      if (days > 0) {
        return Intl.NumberFormat(locale, {
          style: "unit",
          unit: "day",
          unitDisplay: "long",
        }).format(days);
      } else if (hours > 0) {
        return Intl.NumberFormat(locale, {
          style: "unit",
          unit: "hour",
          unitDisplay: "long",
        }).format(hours);
      } else {
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0",
        )}`;
      }
    } else {
      return "";
    }
  };

  return formatCountdown();
};
