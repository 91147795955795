"use client";

import { useTranslations } from "@packages/i18n";
import type { NextUp } from "@packages/sdk";
import { getURLFromDeeplink } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { ContainerProps } from "../../../../blocks";
import { Container, Grid, GridItem } from "../../../../blocks";
import { Button, Text } from "../../../_core";
import { PlayIcon, SubscriptionIcon } from "../../../icons";
import type { NextUpLargeProps } from "../../nextUp";
import { NextUpLarge } from "../../nextUp";
import type { SectionProps } from "../Section";

/**
 * TODO: MediaPlayer
 */
export type GetNextUpPropsProps = Pick<SectionProps, "link" | "mutate"> & {
  item: NextUp;
};

export const getNextUpProps = ({
  item,
}: GetNextUpPropsProps): NextUpLargeProps => {
  const t = useTranslations();

  const actions = [
    <Button
      asChild
      key="button"
      startIcon={
        !item.has_access ? <SubscriptionIcon.Filled /> : <PlayIcon.Small />
      }
      variant="white"
    >
      <Link href={getURLFromDeeplink({ deeplink: item.deeplink })}>
        {t(item.has_access ? "play_session" : "button_subscribe_to_listen")}
      </Link>
    </Button>,
  ];

  return {
    actions,
    color: (item.color_hex ??
      item.images?.color_hex) as NextUpLargeProps["color"],
    description: item.description,
    imgSrc: item.images ?? { large: item.image_url },
    subscription: !item.has_access,
    tag: item.reason_label,
    title: item.title,
  };
};

const styles = stylex.create({
  paddingBottom: {
    paddingBottom: spacing.m,
  },
  paddingTop: {
    paddingTop: spacing.m,
  },
});

export type SectionNextUpProps = ContainerProps &
  Omit<SectionProps, "section"> & {
    nextUp?: NextUp;
  };

export const SectionNextUp = ({
  link = true,
  mutate = true,
  nextUp,
  ...props
}: SectionNextUpProps) => {
  const t = useTranslations();

  return (
    <Container paddingX {...props}>
      <Grid size="xl" styleXArray={[styles.paddingBottom]}>
        <GridItem>
          <Container styleXArray={[styles.paddingBottom, styles.paddingTop]}>
            <Text as="h2" overflow="ellipsis" size="m" type="headline">
              {t("home_up_next")}
            </Text>
          </Container>
          <NextUpLarge {...getNextUpProps({ item: nextUp, link, mutate })} />
        </GridItem>
      </Grid>
    </Container>
  );
};
