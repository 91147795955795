export type ConsoleWarnPackagesUiProps = {
  heading: string;
  warning: string;
};

export const consoleWarnPackagesUi = ({
  heading,
  warning,
}: ConsoleWarnPackagesUiProps) =>
  console.warn(`[@packages/ui] ${heading}:\n\t${warning}`);
