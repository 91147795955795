"use client";

import * as RadixUiDropdownMenu from "@radix-ui/react-dropdown-menu";
import stylex from "@stylexjs/stylex";
import { type ComponentProps, Fragment, type ReactNode } from "react";

import {
  numericPixels,
  radius,
  semanticColors,
  spacing,
  stroke,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { useTheme } from "../../../../theme";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { DropdownMenuHeader } from "../DropdownMenuHeader";

const styles = stylex.create({
  content: {
    alignItems: "flex-start",
    backgroundColor: semanticColors.onOverlayBackground,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.l,
    borderStroke: stroke.regular,
    borderStyle: "solid",
    color: semanticColors.primary,
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
    maxHeight: "75vh",
    maxWidth: numericPixels[480],
    overflow: "hidden",
    padding: spacing.s,
    zIndex: zIndices.modalContent,
  },
  overlay: {
    backgroundColor: semanticColors.higherContrastMed,
    inset: spacing.none,
    position: "fixed",
    zIndex: zIndices.modalOverlay,
  },
});

const defaultContentProps: ComponentProps<typeof RadixUiDropdownMenu.Content> =
  { sideOffset: 16 };

export type DropdownMenuProps = WithStylexArray<
  ComponentProps<typeof RadixUiDropdownMenu.Root> & {
    align?: ComponentProps<typeof RadixUiDropdownMenu.Content>["align"];
    contentProps?: ComponentProps<typeof RadixUiDropdownMenu.Content>;
    contentStyleXArray?: StyleXArray;
    header?: {
      text: string;
    } | null;
    overlay?: boolean;
    portalProps?: ComponentProps<typeof RadixUiDropdownMenu.Portal>;
    side?: ComponentProps<typeof RadixUiDropdownMenu.Content>["side"];
    trigger?: ReactNode;
  }
>;

export const DropdownMenu = ({
  align = "start",
  children,
  contentProps,
  contentStyleXArray = [],
  header,
  overlay = false,
  portalProps,
  side = "bottom",
  styleXArray = [],
  trigger,
  ...props
}: DropdownMenuProps) => {
  const { themeContainerRef } = useTheme();

  return (
    <RadixUiDropdownMenu.Root {...props} {...stylex.props(styleXArray)}>
      <RadixUiDropdownMenu.Trigger asChild>
        {trigger}
      </RadixUiDropdownMenu.Trigger>
      <RadixUiDropdownMenu.Portal
        container={themeContainerRef.current}
        {...portalProps}
      >
        <Fragment>
          {overlay && <div {...stylex.props(styles.overlay)} />}
          <RadixUiDropdownMenu.Content
            {...{ ...defaultContentProps, ...contentProps, align, side }}
            {...stylex.props(styles.content, contentStyleXArray)}
          >
            {header && <DropdownMenuHeader>{header.text}</DropdownMenuHeader>}
            {children}
          </RadixUiDropdownMenu.Content>
        </Fragment>
      </RadixUiDropdownMenu.Portal>
    </RadixUiDropdownMenu.Root>
  );
};
