import * as stylex from "@stylexjs/stylex";

export const stylesScreenReader = stylex.create({
  only: {
    border: "0 !important",
    clip: "rect(1px, 1px, 1px, 1px) !important",
    clipPath: "inset(50%) !important",
    height: "1px !important",
    margin: "-1px !important",
    overflow: "hidden !important",
    padding: "0 !important",
    position: "absolute !important",
    whiteSpace: "nowrap !important",
    width: "1px !important",
  },
});
