import type { ReadonlyURLSearchParams } from "next/navigation";

import { checkRedirectQueries } from "./checkRedirectQueries";

export const saveUrlQueries = (queries: ReadonlyURLSearchParams) => {
  const parish = queries.get("parish");
  if (parish) sessionStorage.setItem("parish", parish);

  const interest = queries.get("interest");
  if (interest) sessionStorage.setItem("interest", interest);

  const referrer = queries.get("referrer");
  if (referrer) sessionStorage.setItem("referrer", referrer);

  const campaign_name = queries.get("campaign_name");
  if (campaign_name) sessionStorage.setItem("campaign_name", campaign_name);
  const referral_name = queries.get("referral_name");
  if (referral_name) sessionStorage.setItem("referral_name", referral_name);

  const utm_source = queries.get("utm_source");
  if (utm_source) sessionStorage.setItem("utm_source", utm_source);
  const utm_medium = queries.get("utm_medium");
  if (utm_medium) sessionStorage.setItem("utm_medium", utm_medium);
  const utm_campaign = queries.get("utm_campaign");
  if (utm_campaign) sessionStorage.setItem("utm_campaign", utm_campaign);

  const loginRedirect = queries.get("loginRedirect");
  if (loginRedirect) {
    checkRedirectQueries(loginRedirect as string, queries, [
      "referrer",
      "utm_source",
      "utm_medium",
      "utm_campaign",
    ]);
  }
};
