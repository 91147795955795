"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { type StyleXVar } from "@stylexjs/stylex/lib/StyleXTypes";
import { useCallback, useRef, useState } from "react";

import {
  colors,
  numericPercentages,
  numericValues,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { IconButton, VolumeIcon } from "../../../../../components";
import type {
  HallowElementProps,
  WithButtonWrapper,
  WithStylexArray,
} from "../../../../../types";
import { Slider } from "../../../primitives";

const styles = stylex.create({
  container: {
    columnGap: spacing.ms,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    placeContent: "flex-start",
    placeItems: "center",
  },
  containerFull: {
    placeContent: "center",
    width: numericPercentages[100],
  },
  icon: {
    padding: numericValues[0],
  },
  slider: {
    padding: spacing.none,
    width: spacing.xxxl,
  },
  sliderFull: {
    width: numericPercentages[100],
  },
});

export type VolumeControlProps = WithStylexArray<
  WithButtonWrapper<
    Omit<HallowElementProps<"div">, "onVolumeChange"> & {
      onVolumeChange: (newVol: number) => void;
      value: number;
      iconColor?: StyleXVar<any>;
      onOverlay?: boolean;
      fullWidth?: boolean;
    }
  >
>;

export const VolumeControl = ({
  styleXArray,
  onVolumeChange,
  value,
  iconColor = colors.white,
  onOverlay = false,
  fullWidth = false,
  Wrapper = ({ children }) => <>{children}</>,
  ...props
}: VolumeControlProps) => {
  const t = useTranslations();
  const [volume, setVolume] = useState<number>((value ?? 1) * 100);
  const lastVolume = useRef<number>(volume);

  const updateVolume = ([newVolume]: [number]) => {
    lastVolume.current = (value ?? 1) * 100;
    onVolumeChange(newVolume / 100.0);
    setVolume(newVolume);
  };

  const mute = useCallback(() => {
    if (volume === 0) updateVolume([lastVolume.current]);
    else updateVolume([0]);
  }, [volume]);

  const Icon =
    volume === 0
      ? VolumeIcon.Mute
      : volume < 50
        ? VolumeIcon.Min
        : VolumeIcon.Max;

  return (
    <div
      {...stylex.props(
        styles.container,
        fullWidth ? styles.containerFull : null,
        styleXArray,
      )}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <Wrapper asChild>
        <IconButton
          icon={<Icon color={iconColor} {...stylex.props(styles.icon)} />}
          variant="transparentWhite"
          onClick={mute}
          title={t(
            volume === 0
              ? "web_mute_button_tooltip_unmute"
              : "web_mute_button_tooltip",
          )}
          tabIndex={0}
        />
      </Wrapper>
      <Slider
        size={"small"}
        value={volume}
        defaultValue={100}
        onValueChange={updateVolume}
        // onValueCommit={updateVolume}
        styleXArray={[styles.slider, fullWidth ? styles.sliderFull : null]}
        onColor={onOverlay}
      />
    </div>
  );
};
