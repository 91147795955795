"use client";

import { I18nMessageClient } from "@packages/i18n";
import { useRequestCommunitiesAdminMostPopularPrayers } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

import {
  numericPixels,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { useCarousel } from "../../../../../hooks";
import { ContentMedium, Text } from "../../../../_base";
import { Carousel } from "../../../layout";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    paddingTop: spacing.m,
  },
  paddingBottom: {
    paddingBottom: spacing.m,
  },
  paddingTop: {
    paddingTop: spacing.m,
  },
  carousel: {
    maxWidth: `calc(100vw - calc(${numericPixels[56]} + ${spacing.sidebar}))`,
  },
});

export type CarouselMostPopularPrayersProps = { id: number };

export const CarouselMostPopularPrayers = ({
  id,
}: CarouselMostPopularPrayersProps) => {
  const carouselApi = useCarousel({
    pageOffset: false,
    size: [2, 2, 3, 5, 5, 5],
  });

  const { query: queryCommunitiesAdminMostPopularPrayers } =
    useRequestCommunitiesAdminMostPopularPrayers({ id });

  return (
    <div {...stylex.props(styles.base)}>
      <Text size="xl" type="title">
        <I18nMessageClient message="most_popular" />
      </Text>
      <Carousel
        carouselApi={carouselApi}
        containerStylexArray={[styles.paddingBottom, styles.paddingTop]}
        styleXArray={[styles.carousel]}
      >
        {queryCommunitiesAdminMostPopularPrayers.data?.results?.map(
          (mostPopularPrayer, index) => (
            <ContentMedium
              asChild
              description={mostPopularPrayer.label_desc}
              imgSrc={mostPopularPrayer.images}
              metadata={mostPopularPrayer.lengths}
              rank={index + 1}
              title={mostPopularPrayer.title}
            >
              <Link
                href={`/admin/${id}/dashboard/prayers/${mostPopularPrayer.id}`}
              />
            </ContentMedium>
          ),
        )}
      </Carousel>
    </div>
  );
};
