import type { Images } from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { HallowImage } from "../../../../blocks";
import { Text } from "../../../_core";

type ContentMoreMenuHeaderProps = {
  imageSrc?: Images;
  title: string;
  subtitle: string;
  metadata: string;
};

const styles = stylex.create({
  container: {
    display: "flex",
    gap: spacing.ms,
    paddingHorizontal: spacing.m,
  },
  textContainer: { display: "flex", flexDirection: "column", gap: spacing.xs },
  image: {
    backgroundColor: semanticColors.neutralsMedium,
    borderRadius: radius.s,
    flexShrink: 0,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
  },
});

const ContentMoreMenuHeader = ({
  title,
  subtitle,
  metadata,
  imageSrc,
}: ContentMoreMenuHeaderProps) => {
  return (
    <div {...stylex.props(styles.container)}>
      <HallowImage
        src={imageSrc}
        size={"s"}
        alt={title}
        width={72}
        height={72}
        {...stylex.props(styles.image)}
      />
      <div {...stylex.props(styles.textContainer)}>
        <Text type="title" size="m" overflow="ellipsis">
          {title}
        </Text>
        <Text type="body" size="s" overflow="ellipsis">
          {subtitle}
        </Text>
        <Text type="detail" size="l" overflow="ellipsis">
          {metadata}
        </Text>
      </div>
    </div>
  );
};

export default ContentMoreMenuHeader;
