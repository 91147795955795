"use client";

import { useTranslations } from "@packages/i18n";
import {
  getMinutesAndSeconds,
  useCurrentQueueItem,
  usePlayer,
} from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useCallback, useEffect, useState } from "react";

import { numericPercentages } from "../../../../../../global/stylex/vars.stylex";
import { Button, Transition } from "../../../../../components";
import { TransitionRoot } from "../../../../../providers";
import type { WithButtonWrapper } from "../../../../../types";

const styles = stylex.create({
  container: {
    height: numericPercentages[100],
    left: 0,
    position: "absolute",
    top: 0,
    width: numericPercentages[100],
  },
  emptyContainer: {
    pointerEvents: "none",
  },
  button: {
    left: numericPercentages[50],
    top: numericPercentages[50],
    transform: "translateX(-50%)",
  },
  enteredButton: {
    position: "absolute",
  },
});

export const ResumeButton = ({
  Wrapper = ({ children }) => <>{children}</>,
}: WithButtonWrapper) => {
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();
  const canResume =
    currentItem?.selected_audio?.resumes_at &&
    player &&
    player.currentTime <
      Math.min(currentItem.selected_audio.resumes_at / 1000, 15);
  const [value, setValue] = useState<"resume" | "">(canResume ? "resume" : "");
  const t = useTranslations();

  const [minutes, seconds] = getMinutesAndSeconds({
    time: currentItem?.selected_audio?.resumes_at ?? 0,
    timeIsMs: true,
  });

  useEffect(() => {
    setValue(canResume ? "resume" : "");
  }, [canResume]);

  const handleResume = useCallback(
    (e) => {
      if (!currentItem) return;
      e.stopPropagation();
      player?.setTime(currentItem.selected_audio.resumes_at / 1000);
    },
    [player, currentItem],
  );

  return (
    <TransitionRoot
      defaultValue={canResume ? "resume" : ""}
      value={value}
      styleXArray={[
        styles.container,
        value !== "resume" ? styles.emptyContainer : null,
      ]}
    >
      <Wrapper asChild>
        <Transition
          value={"resume"}
          styleXArray={[styles.button]}
          enteredStyleXArray={[styles.enteredButton]}
          asChild
          childUsesStyleXArray
        >
          <Button
            variant={"transparentWhite"}
            size={"xs"}
            onClick={handleResume}
          >
            {t("audio_player_resume_at", { "0": minutes, "1": seconds })}
          </Button>
        </Transition>
      </Wrapper>
    </TransitionRoot>
  );
};
