"use client";

import type { MutableRefObject } from "react";
import { createContext } from "react";

import type { useScrollArea } from "../../../hooks";

export type ScrollContext = ReturnType<typeof useScrollArea> & {
  viewportRef: MutableRefObject<HTMLElement>;
};

export const scrollContext = createContext<ScrollContext>({
  canScrollBottom: false,
  canScrollLeft: false,
  canScrollRight: false,
  canScrollTop: false,
  scroll: () => {},
  scrollingX: false,
  scrollingY: false,
  scrollX: 0,
  scrollY: 0,
  viewportRef: null,
});
