"use client";

import { usePathMatch, useRequestMe, useSession } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { forwardRef } from "react";

import {
  colors,
  numericPixels,
  radius,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import type { TabBarIcon } from "../../icons";
import { BibleIcon, HomeIcon, SearchIcon } from "../../icons";
import { Avatar } from "../Avatar";
import { Button } from "../Button";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const hallowElement: HallowElement = "nav";

const links: { href: string; icon: TabBarIcon }[] = [
  { href: "/", icon: HomeIcon },
  { href: "/search", icon: SearchIcon },
  { href: "/bible", icon: BibleIcon },
];

const styles = stylex.create({
  button: {
    backgroundColor: {
      ":hover": colors.transparent,
    },
    borderRadius: radius.none,
    height: spacing.full,
    padding: spacing.none,
  },
  determinedElement: {
    backgroundColor: semanticColors.background,
    bottom: spacing.none,
    display: {
      [cssMediaMinWidthDesktop]: "none",
      default: "flex",
    },
    gap: spacing.t,
    justifyContent: "center",
    left: spacing.none,
    position: "sticky",
    width: spacing.full,
    zIndex: zIndices.maskOver,
  },
  li: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    height: spacing.full,
    justifyContent: "center",
  },
  ul: {
    display: "flex",
    height: spacing.xxl,
    maxWidth: numericPixels[480],
    width: spacing.full,
  },
});

export type TabBarProps = WithAsChild<
  WithStylexArray<Omit<HallowElementProps<typeof hallowElement>, "children">>
>;

export const TabBar = forwardRef<any, TabBarProps>(
  ({ asChild = false, styleXArray, ...props }, ref) => {
    const matcher = usePathMatch();

    const { query: me } = useRequestMe();
    const { status } = useSession();

    if (status !== "authenticated") return null;

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.determinedElement, styleXArray)}
      >
        <ul {...stylex.props(styles.ul)}>
          {links.map((link) => {
            const Icon = matcher(link.href, link.href === "/")
              ? link.icon.Filled
              : link.icon.Outline;
            return (
              <li key={link.href} {...stylex.props(styles.li)}>
                <Button
                  asChild
                  isFullWidth
                  variant="transparentPrimary"
                  styleXArray={[styles.button]}
                >
                  <Link href={link.href}>
                    <Icon />
                  </Link>
                </Button>
              </li>
            );
          })}
          <li {...stylex.props(styles.li)}>
            <Button
              asChild
              isFullWidth
              variant="transparentPrimary"
              styleXArray={[styles.button]}
            >
              <Link href="/library">
                <Avatar
                  selected={matcher("/library")}
                  imgSrc={me.data?.image_url}
                />
              </Link>
            </Button>
          </li>
        </ul>
      </DeterminedElement>
    );
  },
);

TabBar.displayName = "TabBar";
