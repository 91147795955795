"use client";

import * as stylex from "@stylexjs/stylex";

import { useStylex } from "../../../../../hooks";
import type { HallowElement, HallowElementProps } from "../../../../../types";
import { Text } from "../../../_core";

const hallowElement: HallowElement = "svg";

const styles = stylex.create({
  text: {
    textAlign: "center",
  },
});

export type TickProps = HallowElementProps<typeof hallowElement> & {
  dataLength?: number;
  payload?: any;
} & any;

export const Tick = ({
  dataLength,
  payload,
  tickFormatter,
  verticalAnchor,
  visibleTicksCount,
  x,
  y,
  ...props
}: TickProps) => {
  const { getStylexColorVarValue } = useStylex();

  return (
    <g transform={`translate(${x},${y})`} {...props}>
      <Text asChild size="l" styleXArray={[styles.text]} type="detail">
        <text
          dy={8}
          x={0}
          y={0}
          textAnchor={
            payload?.index === 0
              ? "start"
              : payload?.index + 1 === dataLength
                ? "end"
                : "middle"
          }
          fill={getStylexColorVarValue({ color: "neutralsLow" })}
        >
          {payload.value}
        </text>
      </Text>
    </g>
  );
};

Tick.displayName = "Tick";
