import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";
import { useRequestBackgroundSounds } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import { semanticColors } from "../../../../../../global/stylex/vars.stylex";
import {
  ModalDropdownMenuHeader,
  ModalDropdownMenuItem,
  ModalTabContent,
} from "../../../../../components";
import { VolumeControl } from "../../controls";

const styles = stylex.create({
  loading: {
    height: "100vh",
  },
});

export const SelectBackgroundTabContent = () => {
  const t = useTranslations();
  const player = usePlayer();
  const { query: bgQuery } = useRequestBackgroundSounds();

  return (
    <ModalTabContent
      value={"selectBgMenu"}
      key={"selectBgMenu"}
      footer={
        <VolumeControl
          key={"bgVolumeControl"}
          iconColor={semanticColors.onColorVeryHigh}
          onOverlay={true}
          value={player?.bgVolume ?? 0.25}
          fullWidth={true}
          onVolumeChange={player ? player.setBgVolume : () => {}}
        />
      }
      header={
        <ModalDropdownMenuHeader>
          {t("track_settings_background_sounds")}
        </ModalDropdownMenuHeader>
      }
    >
      <>
        {bgQuery.isPending ? (
          <div {...stylex.props(styles.loading)}>
            {t("background_sounds_loading")}
          </div>
        ) : bgQuery.error ? (
          t("background_sounds_error_loading")
        ) : bgQuery.data ? (
          <>
            <ModalDropdownMenuItem
              key={"mediaMenu_selectBgSounds_none_null"}
              selected={!player?.bgId}
              onSelect={() => player?.setBgId(null)}
            >
              {t("web_audio_setting_list_option_background_none")}
            </ModalDropdownMenuItem>
            {bgQuery.data.map((i) => (
              <ModalDropdownMenuItem
                key={`mediaMenu_selectBgSounds_${i.prayer.title.toLowerCase()}_${i.selected_audio.id}`}
                selected={i.selected_audio.id === player?.bgId}
                onSelect={() => player?.setBgId(i.selected_audio.id)}
              >
                {i.prayer.title}
              </ModalDropdownMenuItem>
            ))}
          </>
        ) : null}
      </>
    </ModalTabContent>
  );
};
