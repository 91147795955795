"use client";

import * as Toast from "@radix-ui/react-toast";
import * as stylex from "@stylexjs/stylex";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";

import {
  dropShadow,
  numericMilliseconds,
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  shades,
  spacing,
  stroke,
} from "../../../../global/stylex/vars.stylex";
import { type WithStylexArray } from "../../../types";
import type { IconElement } from "../../_base";

const popupStyles = stylex.create({
  default: {
    alignItems: "center",
    background: semanticColors.secondary,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.full,
    borderWidth: stroke.light,
    boxShadow: `${numericValues[0]} ${dropShadow.s} ${dropShadow.ms} ${numericValues[0]} ${shades.shade10}`,
    color: semanticColors.primary,
    columnGap: spacing.ms,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    opacity: 0,
    padding: spacing.s,
    // we can't use variables here because then the CSS prop is invalid
    transform: "scale(0.7) translateY(50px)",
    transitionDelay: numericValues[0],
    transitionDuration: `${numericMilliseconds[400]}, ${numericMilliseconds[500]}`,
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "cubic-bezier(.5,.87,.3,1.11)",
    width: numericPercentages[100],
  },
  open: {
    opacity: 1,
    transform: "scale(1) translateY(0)",
    transitionDelay: "100ms, 0",
    transitionDuration: "500ms",
  },
  icon: {
    display: "flex",
    height: spacing.xl,
    placeContent: "center",
    placeItems: "center",
    width: spacing.xl,
  },
});

export type PopupProps = WithStylexArray<
  PropsWithChildren<{
    durationMs?: number;
    icon?: IconElement;
    type: Toast.ToastProps["type"];
  }>
>;

export const Popup = ({
  children,
  durationMs = 5000,
  icon,
  styleXArray,
  type,
}: PopupProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    requestAnimationFrame(() => setOpen(true));
    setTimeout(() => requestAnimationFrame(() => setOpen(false)), durationMs);
  }, []);

  // account for in AND out animation time on duration
  return (
    <Toast.Root duration={durationMs + 1000} type={type} asChild ref={ref}>
      <div
        {...stylex.props(
          popupStyles.default,
          open ? popupStyles.open : [],
          styleXArray,
        )}
      >
        {icon ? <div {...stylex.props(popupStyles.icon)}>{icon}</div> : null}
        {children}
      </div>
    </Toast.Root>
  );
};
