"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  numericPixels,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { ButtonProps } from "../Button";
import { Button } from "../Button";

const styles = stylex.create({
  button: {
    justifyContent: "center",
  },
  s: {
    padding: spacing.xxs,
    width: spacing.xl,
  },
  m: {
    padding: spacing.s,
    width: numericPixels[40],
  },
  l: {
    padding: spacing.m,
    width: numericPixels[56],
  },
});

export type IconButtonProps = Omit<
  ButtonProps,
  "endIcon" | "isFullWidth" | "size" | "startIcon"
> & {
  icon: ButtonProps["startIcon"];
  size?: "s" | "m" | "l";
};

export const IconButton = forwardRef<any, IconButtonProps>(
  ({ icon, size = "m", styleXArray = [], ...props }, ref) => (
    <Button
      ref={ref}
      size={size}
      startIcon={icon}
      styleXArray={[styles.button, styles[size], ...styleXArray]}
      {...(props as ButtonProps)}
    />
  ),
);

IconButton.displayName = "IconButton";
