import type * as RadixUIScrollArea from "@radix-ui/react-scroll-area";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { useContext } from "react";

import {
  numericPercentages,
  numericPixels,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { ScrollArea } from "../../../blocks";
import { ModalTabContext } from "../ModalTabContainer";

const IS_DESKTOP = "@media screen and (min-width: 768px)";

const transitionStyles = stylex.create({
  base: {
    display: "grid",
    gridTemplateAreas: '"header" "body" "footer"',
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content minmax(calc(100% - 96px), 1fr) min-content",
    position: "absolute",
    transform: "translateX(200%)",
    transition: "transform 300ms ease-in",
  },
  entering: {
    transform: "translateX(200%)",
  },
  entered: {
    position: "relative",
    transform: "translateX(0)",
  },
  exiting: {
    transform: "translateX(-200%)",
  },
  exited: {
    transform: "translateX(-200%)",
  },
  established: {
    height: numericPercentages[100],
  },
});

const styles = stylex.create({
  footer: {
    gridArea: "footer",
  },
  header: {
    gridArea: "header",
  },
  scrollArea: {
    maxWidth: numericPercentages[100],
    minWidth: {
      [IS_DESKTOP]: numericPixels[300],
      default: numericPercentages[100],
    },
    width: numericPercentages[100],
  },
  areaContent: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    rowGap: spacing.xxs,
  },
  scrollRoot: {
    gridArea: "body",
  },
});

export type ModalTabContentProps = PropsWithChildren<
  WithStylexArray<{
    areaContentStyleXArray?: StyleXArray;
    value: string;
    header?: ReactNode;
    footer?: ReactNode;
    maskBottom?: boolean;
    viewportProps?: ComponentProps<typeof RadixUIScrollArea.Viewport>;
  }>
>;

export const ModalTabContent = ({
  areaContentStyleXArray = [],
  children,
  maskBottom = true,
  value,
  footer,
  header,
  styleXArray = [],
  viewportProps,
}: ModalTabContentProps) => {
  const tabs = useContext(ModalTabContext);

  if (
    [tabs.entering, tabs.exited, tabs.entered, tabs.exiting].includes(value)
  ) {
    const style =
      tabs.entering === value
        ? transitionStyles.entering
        : tabs.entered === value
          ? transitionStyles.entered
          : tabs.exiting === value
            ? transitionStyles.exiting
            : transitionStyles.exited;

    return (
      <div
        ref={tabs.setHeight.bind(null, value)}
        {...stylex.props(
          transitionStyles.base,
          style,
          tabs.established === value ? transitionStyles.established : null,
          styleXArray,
        )}
      >
        {header ? <div {...stylex.props(styles.header)}>{header}</div> : null}
        <ScrollArea
          horizontal={false}
          maskBottom={maskBottom}
          masksSize="xs"
          maskTop
          styleXArray={[styles.scrollRoot]}
          viewportProps={viewportProps}
          masksColor={"onOverlayBackground"}
        >
          <div {...stylex.props(styles.areaContent, areaContentStyleXArray)}>
            {children}
          </div>
        </ScrollArea>
        {footer ? <div {...stylex.props(styles.footer)}>{footer}</div> : null}
      </div>
    );
  }

  return null;
};
