"use client";

import { useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { useSession } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  colors,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import type { TextProps } from "../../../_base";
import { Text } from "../../../_base";

const styles = stylex.create({
  desktopMenu: {
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.onOverlayLow,
      ":active": semanticColors.onOverlayLow,
    },
    borderRadius: radius.m,
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":active": semanticColors.neutralsMedHigh,
    },
    cursor: "pointer",
    padding: numericPixels[16],
    width: spacing.full,
  },
});

export type LogoutButton = {
  size?: TextProps["size"];
  type?: TextProps["type"];
};

export const LogoutButton = forwardRef<any, LogoutButton>(
  ({ size = "m", type = "button" }, ref) => {
    const analytics = useAnalytics();
    const isDesktop = useIsDesktopViewport();
    const t = useTranslations();
    const { logout } = useSession();

    const clickLogout = () =>
      logout({
        callback: () => {
          analytics.logout();
          location.reload();
        },
      });

    return (
      <Text
        onClick={() => clickLogout()}
        styleXArray={isDesktop ? [styles.desktopMenu] : []}
        {...({ size, type } as TextProps)}
      >
        {t("logout_title")}
      </Text>
    );
  },
);

LogoutButton.displayName = "LogoutButton";
