"use client";

import * as stylex from "@stylexjs/stylex";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { TransitionStatus } from "react-transition-group";

import { useIsDesktopViewport } from "../../../../hooks";

const MAX_MODAL_HEIGHT = 670;
const ANIMATION_MS = 300;

const styles = stylex.create({
  default: {
    height: "var(--modal-height, 100%)",
    opacity: 1,
    position: "relative",
    transition: "height 300ms ease-out",
  },
  loaded: {
    opacity: 1,
  },
});

export type ModalTabContainer = PropsWithChildren<{
  defaultValue: string;
  styleXArray?: any;
}>;

export type ModalTabContextShape = {
  setValue: (newValue: string) => void;
  setHeight: (value: string, node: HTMLElement) => void;
  established: string;
} & Omit<Record<TransitionStatus, string>, "unmounted">;

export const ModalTabContext = createContext<ModalTabContextShape>({
  setValue: () => {},
  setHeight: () => {},
  entering: "",
  entered: "",
  exiting: "",
  exited: "",
  established: "",
});

export const ModalTabContainer = ({
  children,
  defaultValue,
  styleXArray = [],
}: ModalTabContainer) => {
  const isDesktopViewport = useIsDesktopViewport();
  const realMaxModalHeight = useMemo<number>(
    () =>
      typeof window === "undefined"
        ? MAX_MODAL_HEIGHT
        : (window.visualViewport?.height ?? window.innerHeight) * 0.6,
    [isDesktopViewport],
  );
  const [modalHeight, setModalHeight] = useState<number | null>(null);
  const [entering, setEntering] = useState<string>("");
  const [entered, setEntered] = useState<string>(defaultValue);
  const [exiting, setExiting] = useState<string>("");
  const [exited, setExited] = useState<string>("");
  const [established, setEstablished] = useState<string>(defaultValue);
  const heights = useRef<Record<string, number>>({});

  useEffect(() => {
    return () => {
      setEntering("");
      setEntered(defaultValue);
      setEstablished(defaultValue);
      setExited("");
      setExiting("");
      setModalHeight(null);
    };
  }, []);

  const setValue = useCallback(
    (newValue: string) => {
      setEntering(newValue);
      setExiting(entered);
      setEstablished("");
    },
    [entering, entered, exiting, exited],
  );

  const setHeight = useCallback(
    (value: string, node: HTMLElement) => {
      const calculatedHeight = node?.getBoundingClientRect()?.height;
      if (!calculatedHeight || isNaN(calculatedHeight))
        heights.current[value] = realMaxModalHeight;
      else
        heights.current[value] = Math.min(realMaxModalHeight, calculatedHeight);
    },
    [realMaxModalHeight],
  );

  useEffect(() => {
    if (entering.length && exiting.length) {
      requestAnimationFrame(() => {
        setEntered(entering);
        setExited(exiting);
        setEntering("");
        setExiting("");
      });
    }
  }, [entering, exiting]);

  useEffect(() => {
    setTimeout(() => {
      setExited("");
      setEstablished(entered);
    }, ANIMATION_MS);
  }, [entered, exited]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setModalHeight(heights.current[entered]);
    });
  }, [entered]);

  return (
    <ModalTabContext.Provider
      value={{
        setValue,
        setHeight,
        entered,
        exiting,
        exited,
        entering,
        established,
      }}
    >
      <div
        style={{ "--modal-height": `${modalHeight}px` }}
        {...stylex.props(styles.default, styleXArray)}
        data-tab={entered}
      >
        {children}
      </div>
    </ModalTabContext.Provider>
  );
};
