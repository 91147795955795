"use client";

import { forwardRef } from "react";

import { semanticColors } from "../../../../../../global/stylex/vars.stylex";
import { MoveIcon } from "../../../../../components";
import type { HallowElementProps } from "../../../../../types";

export type QueueHandleProps = HallowElementProps<"button">;

export const QueueHandle = forwardRef<HTMLButtonElement, QueueHandleProps>(
  (props, ref) => {
    return (
      <button ref={ref} onClick={(e) => e.stopPropagation()} {...props}>
        <MoveIcon color={semanticColors.onColorVeryHigh} />
      </button>
    );
  },
);

QueueHandle.displayName = "QueueHandle";
