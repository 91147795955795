export enum HallowAnalyticsEvent {
  CommunityReportResponded = "Community Report Responded",
  TappedAdminDashboardTab = "Tapped Admin Dashboard Tab",
  TappedCommunityPermissionsToggle = "Tapped Community Permissions Toggle",
  TappedCommunitySettings = "Tapped Community Settings",
  TappedDashboardChartTimeframe = "Tapped Dashboard Chart Timeframe",
  TappedWebAppNav = "Tapped Web App Nav",
  TappedWebCommunity = "Tapped Web Community",
  TappedWebCommunityDropdown = "Tapped Web Community Dropdown",
}
