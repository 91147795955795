import { getArtworkMetadata } from "@packages/sdk";
import type { MutableRefObject } from "react";
import { useMemo } from "react";
import { useCallback } from "react";

import type { Display } from "../providers";
import type { MediaElementsRef } from "../types";
import { useCurrentQueueItem } from "./useCurrentQueueItem";

export const supportsMediaSession = () =>
  typeof navigator !== "undefined" && "mediaSession" in navigator;

export type UseMediaSessionAPIProps = {
  handleEnd: () => Promise<void>;
  handleReverse: () => Promise<void>;
  mediaRef: MutableRefObject<MediaElementsRef>;
};

export type UseMediaSessionAPIReturn = {
  updateMetadata: () => void;
  updatePlaybackState: (paused: boolean, display: Display) => void;
  establishStandardHandlers: (
    play: MediaElementsRef["play"],
    pause: (wasNotTapped?: boolean) => void,
    onClose: () => void,
  ) => void;
  destroyStandardHandlers: () => void;
  establishSeekHandlers: () => void;
  destroySeekHandlers: () => void;
  establishTrackHandlers: () => void;
  destroyTrackHandlers: () => void;
  updateMediaPosition: () => void;
  updateSpeed: () => void;
  updateDuration: () => void;
};

export const useMediaSessionAPI = ({
  handleEnd,
  handleReverse,
  mediaRef,
}: UseMediaSessionAPIProps): UseMediaSessionAPIReturn => {
  const { currentItem } = useCurrentQueueItem();

  const updateMetadata = useCallback(() => {
    if (currentItem && supportsMediaSession()) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: currentItem.prayer.title,
        artist: currentItem.prayer.guides.find(
          (g) => g.id === currentItem.selected_audio.guide_id,
        ).name,
        album: currentItem.collection.title,
        artwork: getArtworkMetadata(currentItem.collection.images),
      });
    }
  }, [currentItem]);

  const updatePlaybackState = (paused: boolean, display: Display) => {
    if (supportsMediaSession()) {
      navigator.mediaSession.playbackState =
        display === "hidden" ? "none" : paused ? "paused" : "playing";
    }
  };

  const establishStandardHandlers = (
    play: () => Promise<void>,
    pause: (wasNotTapped?: boolean) => void,
    onClose: () => void,
  ) => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler("play", () => play());
      navigator.mediaSession.setActionHandler("pause", () => pause());
      navigator.mediaSession.setActionHandler("stop", () => {
        pause(true);
        if (mediaRef.current) mediaRef.current.currentTime = 0;
        onClose();
      });
      navigator.mediaSession.setActionHandler("seekto", ({ seekTime }) => {
        if (mediaRef.current) {
          mediaRef.current.currentTime = seekTime;
        }
      });
    }
  };

  const destroyStandardHandlers = () => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler("play", null);
      navigator.mediaSession.setActionHandler("pause", null);
      navigator.mediaSession.setActionHandler("seekto", null);
      navigator.mediaSession.setActionHandler("stop", null);
    }
  };

  const establishSeekHandlers = () => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler(
        "seekbackward",
        ({ seekOffset }) => {
          if (mediaRef.current) {
            mediaRef.current.currentTime = Math.max(
              mediaRef.current.currentTime - (seekOffset ?? 10),
              0,
            );
          }
        },
      );
      navigator.mediaSession.setActionHandler(
        "seekforward",
        ({ seekOffset }) => {
          if (mediaRef.current) {
            mediaRef.current.currentTime = Math.min(
              mediaRef.current.currentTime + (seekOffset ?? 10),
              mediaRef.current.duration,
            );
          }
        },
      );
    }
  };

  const destroySeekHandlers = () => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler("seekforward", null);
      navigator.mediaSession.setActionHandler("seekbackward", null);
    }
  };

  const establishTrackHandlers = useCallback(() => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler("nexttrack", () => handleEnd());
      navigator.mediaSession.setActionHandler("previoustrack", () =>
        handleReverse(),
      );
    }
  }, [handleEnd, handleReverse]);

  const destroyTrackHandlers = () => {
    if (supportsMediaSession()) {
      navigator.mediaSession.setActionHandler("nexttrack", null);
      navigator.mediaSession.setActionHandler("previoustrack", null);
    }
  };

  const updateDuration = useCallback(() => {
    if (supportsMediaSession() && currentItem) {
      pushState();
    }
  }, [currentItem]);

  const updateSpeed = useCallback(() => {
    if (supportsMediaSession() && currentItem) {
      pushState();
    }
  }, [currentItem]);

  const updateMediaPosition = useCallback(() => {
    if (supportsMediaSession() && currentItem) {
      pushState();
    }
  }, [currentItem]);

  const pushState = () => {
    if (
      !isNaN(mediaRef.current?.duration) &&
      mediaRef.current?.duration !== 0 &&
      !isNaN(mediaRef.current?.currentTime) &&
      !isNaN(mediaRef.current?.playbackRate) &&
      mediaRef.current?.playbackRate !== 0
    ) {
      navigator.mediaSession.setPositionState({
        position: Math.min(
          mediaRef.current.duration,
          mediaRef.current.currentTime,
        ),
        playbackRate: mediaRef.current.playbackRate,
        duration: mediaRef.current.duration,
      });
    }
  };

  return useMemo(
    () => ({
      updateMediaPosition,
      updateMetadata,
      updatePlaybackState,
      establishSeekHandlers,
      establishStandardHandlers,
      establishTrackHandlers,
      destroySeekHandlers,
      destroyStandardHandlers,
      destroyTrackHandlers,
      updateDuration,
      updateSpeed,
    }),
    [
      updateMediaPosition,
      updateMetadata,
      updatePlaybackState,
      establishSeekHandlers,
      establishStandardHandlers,
      establishTrackHandlers,
      destroySeekHandlers,
      destroyStandardHandlers,
      destroyTrackHandlers,
      updateDuration,
      updateSpeed,
    ],
  );
};
