import type {
  ColorFormat,
  ColorFormatResult,
  CSSColor,
  RGBAArray,
} from "./types";

export type GetRGBAFromColorProps<ColorFormatType extends ColorFormat> = {
  color: CSSColor;
  format?: ColorFormatType;
};
export const getRGBAFromColor = <
  ColorFormatType extends ColorFormat = "string",
>({
  color,
  format,
}: GetRGBAFromColorProps<ColorFormatType>): ColorFormatResult<ColorFormatType> => {
  let rgbaArray: RGBAArray = [255, 255, 255, 255]; // Default white with full opacity

  // hex
  if (/^#[a-fA-F0-9]{6,8}$/.test(color)) {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    let a = 255; // Default alpha to full opacity if not specified
    if (color.length === 9) {
      // Check if alpha value is present
      a = parseInt(color.substring(7, 9), 16);
    }
    rgbaArray = [r, g, b, a / 255];
  }

  // rgb(a)
  if (/^rgba?/.test(color)) {
    rgbaArray = color
      .split("(")[1]
      .split(")")[0]
      .split(",")
      .map((c) => parseFloat(c)) as RGBAArray;
  }

  switch (format) {
    case "array":
      return rgbaArray as ColorFormatResult<ColorFormatType>;
    case "object":
      return {
        r: rgbaArray[0],
        g: rgbaArray[1],
        b: rgbaArray[2],
        a: rgbaArray[3],
      } as ColorFormatResult<ColorFormatType>;
    default:
      return `rgba(${rgbaArray[0]},${rgbaArray[1]},${rgbaArray[2]},${rgbaArray[3]})` as ColorFormatResult<ColorFormatType>;
  }
};
