"use client";

import { Close as DialogClose } from "@radix-ui/react-dialog";
import { Button as ToolbarButton } from "@radix-ui/react-toolbar";
import type { MouseEventHandler } from "react";

import { colors } from "../../../../../../global/stylex/vars.stylex";
import { CloseIcon, IconButton } from "../../../../../components";
import { stylexDynamics } from "../../../../../lib";
import type { WithStylexArray } from "../../../../../types";

export type CloseButtonProps = WithStylexArray<{
  onClick: MouseEventHandler<HTMLButtonElement>;
}>;

export const CloseButton = ({ onClick, styleXArray }: CloseButtonProps) => {
  return (
    <ToolbarButton asChild>
      <DialogClose asChild>
        <IconButton
          icon={<CloseIcon fill={colors.white} />}
          variant={"transparentWhite"}
          onClick={onClick}
          styleXArray={[
            stylexDynamics.hideDesktopViewport("flex"),
            styleXArray,
          ]}
          tabIndex={0}
        />
      </DialogClose>
    </ToolbarButton>
  );
};
