import type { TextProps } from "../../../_core";
import type { CategoryMediumProps } from "../CategoryMedium";
import { CategoryMedium } from "../CategoryMedium";

export type CategoryLargeProps = CategoryMediumProps;

export const CategoryLarge = ({
  maskProps,
  titleProps,
  ...props
}: CategoryLargeProps) => (
  <CategoryMedium
    {...props}
    maskProps={{ size: "m", ...maskProps }}
    titleProps={{ size: "xl", ...titleProps } as TextProps}
  />
);
