"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentGuide } from "@packages/media";

import { AudioIcon, ModalTab } from "../../../../../components";

export const SelectGuideTab = () => {
  const t = useTranslations();
  const { currentItem, guides } = useCurrentGuide();

  return (
    <ModalTab
      key={"guideSelectMenuTab"}
      value={"guideSelectMenu"}
      label={t("track_settings_guide")}
      startIcon={<AudioIcon />}
    >
      {guides.find((g) => g.id === currentItem.selected_audio.guide_id)?.name ??
        t("general_word_error")}
    </ModalTab>
  );
};
