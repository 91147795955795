"use client";

import * as stylex from "@stylexjs/stylex";
import type { SlotProps } from "input-otp";
import { OTPInput as OTPInputBase } from "input-otp";

import { animations } from "../../../../../global/stylex/animations.stylex";
import {
  fontSizes,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";

const styles = stylex.create({
  container: {
    display: "flex",
    gap: spacing.s,
  },
  input: {
    alignItems: "center",
    borderColor: semanticColors.neutralsVeryLow,
    borderRadius: radius.s,
    borderStyle: "solid",
    borderWidth: "2px",
    display: "flex",
    fontSize: fontSizes.titleL,
    height: numericPixels[48],
    justifyContent: "center",
    width: numericPixels[40],
  },
  inputActive: {
    borderColor: semanticColors.primary,
    zIndex: 1,
  },
  fakeCaret: {
    alignItems: "center",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationName: animations.pulse,
    display: "flex",
    justifyContent: "center",
    pointerEvents: "none",
    position: "absolute",
  },
  fakeCaretLine: {
    backgroundColor: semanticColors.primary,
    height: "16px",
    width: "2px",
  },
  fakeDash: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  fakeDashLine: {
    backgroundColor: semanticColors.primary,
    height: numericPixels[2],
    width: numericPixels[8],
  },
});

type OTPInputProps = {
  onSubmit: (value: string) => void;
  onChange?: (value: string) => void;
};

const FakeCaret = () => (
  <div {...stylex.props(styles.fakeCaret)}>
    <div {...stylex.props(styles.fakeCaretLine)} />
  </div>
);

const FakeDash = () => (
  <div {...stylex.props(styles.fakeDash)}>
    <div {...stylex.props(styles.fakeDashLine)} />
  </div>
);

export const Slot = (props: SlotProps) => (
  <div {...stylex.props(styles.input, props.isActive && styles.inputActive)}>
    {props.char !== null && <div>{props.char}</div>}
    {props.hasFakeCaret && <FakeCaret />}
  </div>
);

export const OTPInput = ({ onSubmit, onChange }: OTPInputProps) => {
  const maxLength = 6;
  return (
    <OTPInputBase
      maxLength={maxLength}
      onChange={(value) => {
        onChange?.(value);
        value.length === maxLength && onSubmit(value);
      }}
      render={({ slots }) => (
        <div {...stylex.props(styles.container)}>
          {slots.slice(0, 3).map((slot, idx) => (
            <Slot key={idx} {...slot} />
          ))}

          <FakeDash />

          {slots.slice(3).map((slot, idx) => (
            <Slot key={idx} {...slot} />
          ))}
        </div>
      )}
    />
  );
};
