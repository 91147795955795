import * as stylex from "@stylexjs/stylex";
import type { LinkProps } from "next/link";
import Link from "next/link";
import type { ReactNode } from "react";
import { forwardRef } from "react";

import { semanticColors, spacing } from "../../../../global/stylex/vars.stylex";
import type { HallowElementProps, WithStylexArray } from "../../../types";
import { Button, type ButtonProps } from "../../_base";

const styles = stylex.create({
  base: {
    backgroundColor: "transparent",
    color: {
      default: semanticColors.accentMedium,
      ":focus-visible": semanticColors.accentLower,
      ":hover": semanticColors.accentLow,
      ":active": semanticColors.accentHigher,
    },
    display: "flex",
  },
});

const stylesSize = {
  s: {
    paddingLeft: "none",
    paddingRight: "none",
  },
  m: {
    paddingLeft: "none",
    paddingRight: "none",
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
  },
  l: {
    paddingLeft: "none",
    paddingRight: "none",
    paddingTop: spacing.m,
    paddingBottom: spacing.m,
  },
};

export type ButtonLinkProps = WithStylexArray<{
  linkProps: LinkProps & HallowElementProps<"a">;
  buttonProps: Omit<ButtonProps, "isFullWidth" | "size" | "variant">;
  size?: "s" | "m" | "l";
  children: ReactNode;
}>;

//TODO: Add support for variants - for now it always uses the accent variant

export const ButtonLink = forwardRef<any, ButtonLinkProps>(
  (
    {
      size = "m",
      styleXArray = [],
      children,
      linkProps,
      buttonProps,
      ...props
    },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        size={size}
        asChild
        styleXArray={[styles.base, stylesSize[size], ...styleXArray]}
        {...(buttonProps as ButtonProps)}
        {...props}
      >
        <Link {...(linkProps as LinkProps)}>{children}</Link>
      </Button>
    );
  },
);

ButtonLink.displayName = "ButtonLink";
