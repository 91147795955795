import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  colors,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import type { IconElement } from "../../icons";
import { GiftIcon } from "../../icons";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    display: "flex",
  },
  contentContainer: {
    backgroundColor: semanticColors.accentLowest,
    borderBottomRightRadius: radius.ms,
    borderTopRightRadius: radius.ms,
    color: semanticColors.accentHighest,
    display: "flex",
    flexDirection: "column",
    gap: spacing.t,
    justifyContent: "center",
    padding: spacing.m,
  },
  iconContainer: {
    alignItems: "center",
    backgroundColor: semanticColors.accentHigh,
    borderBottomLeftRadius: radius.ms,
    borderTopLeftRadius: radius.ms,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    width: spacing.xxl,
  },
});

export type PromotionalBannerProps = WithAsChild<
  WithStylexArray<Omit<HallowElementProps<typeof hallowElement>, "children">>
> & {
  description?: string;
  icon?: IconElement;
  title: string;
};

export const PromotionalBanner = forwardRef<any, PromotionalBannerProps>(
  (
    {
      asChild = false,
      description,
      icon = <GiftIcon.Outline />,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
      >
        <div {...stylex.props(styles.iconContainer)}>{icon}</div>
        <div {...stylex.props(styles.contentContainer)}>
          <Text size="m" type="title">
            {title}
          </Text>
          <Text size="xl" type="detail">
            {description}
          </Text>
        </div>
      </DeterminedElement>
    );
  },
);

PromotionalBanner.displayName = "PromotionalBanner";
