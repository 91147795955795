"use client";

import type { NiceModalHandler } from "@ebay/nice-modal-react";
import type * as RadixUIDialog from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";
import type { ComponentProps, ReactNode } from "react";

import {
  numericPercentages,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import { createModal, useModal } from "../../../../niceModal";
import type { WithStylexArray } from "../../../../types";
import { ScrollArea } from "../../../blocks";
import type { FullscreenModalProps } from "../FullscreenModal";
import { FullscreenModal } from "../FullscreenModal";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";

const cssMediaMinWidthDesktop = "@media (min-width: 768px)";
const styles = stylex.create({
  viewport: {
    height: `calc(100vh - ${spacing.footer})`,
    maxWidth: {
      [cssMediaMinWidthDesktop]: "480px",
      default: numericPercentages[100],
    },
    padding: { [cssMediaMinWidthDesktop]: spacing.xl },
    width: numericPercentages[100],
  },
  content: {
    width: "fit-content",
    zIndex: zIndices.modalOverModal,
  },
  overlay: { zIndex: zIndices.overlayOverModal },
});

type FullscreenModalModalProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Root>
> & {
  trigger?: ReactNode;
  fullScreenModalProps?: FullscreenModalProps;
  modalProps?: ModalProps;
};

export const FullscreenModalModal = ({
  open,
  onOpenChange,
  fullScreenModalProps = {
    showCloseButton: true,
  },
  modalProps = {
    contentStyleXArray: [styles.content],
    overlayStyleXArray: [styles.overlay],
  },
  styleXArray,
  children,
  trigger,
}: FullscreenModalModalProps) => {
  const isDesktopViewport = useIsDesktopViewport();

  const handleOpenChange = (open) => {
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  const ModalEl = ({ children }: { children: React.ReactElement }) => {
    if (isDesktopViewport)
      return (
        <Modal
          open={open}
          onOpenChange={handleOpenChange}
          trigger={trigger}
          {...modalProps}
        >
          {children}
        </Modal>
      );

    return (
      <FullscreenModal
        open={open}
        onOpenChange={handleOpenChange}
        modal={false}
        trigger={trigger}
        {...fullScreenModalProps}
      >
        {children}
      </FullscreenModal>
    );
  };

  return (
    <ModalEl>
      <ScrollArea
        viewportStyleXArray={[styles.viewport, styleXArray]}
        masksColor={"onOverlayBackground"}
        maskBottom
        maskTop
        masksSize="xs"
      >
        {children}
      </ScrollArea>
    </ModalEl>
  );
};

export function createFullscreenModal<T extends {}>(
  children: (
    props: T,
    modal: NiceModalHandler<Record<string, unknown>>,
  ) => React.ReactNode,
) {
  return createModal<T>((props) => {
    const modal = useModal();

    return (
      <FullscreenModalModal
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.hide())}
      >
        {children(props, modal)}
      </FullscreenModalModal>
    );
  });
}
