"use client";

import { Provider } from "@ebay/nice-modal-react";
import type { ComponentProps } from "react";

export type NiceModalProviderProps = ComponentProps<typeof Provider>;

export const NiceModalProvider = ({ children }: NiceModalProviderProps) => {
  return <Provider>{children}</Provider>;
};
