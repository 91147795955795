export enum Consent {
  NECESSARY = 0,
  ANALYTICS = 1, // also called functional
  PERFORMANCE = 2,
  ADVERTISING = 3, // also called targeting
}

export const DEFAULT_CONSENT_OPTIONS = [
  Consent.ANALYTICS,
  Consent.PERFORMANCE,
  Consent.ADVERTISING,
];
