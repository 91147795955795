import { useAnalytics } from "@packages/analytics";
import { useMediaAnalytics, useQueue } from "@packages/media";
import * as stylex from "@stylexjs/stylex";

import {
  numericValues,
  semanticColors,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Button,
  RepeatIcon,
  RepeatOneIcon,
  ShuffleIcon,
} from "../../../../../components";
import type { WithButtonWrapper } from "../../../../../types";
import { PlayPause } from "../../controls";

const buttonStyles = stylex.create({
  default: {
    border: numericValues[0],
    color: semanticColors.onColorHigh,
    height: "min-content",
    padding: 0,
    width: "auto",
  },
  active: {
    color: semanticColors.primary,
  },
});

export type QueueControlsProps = WithButtonWrapper;

export const QueueControls = ({
  Wrapper = ({ children }) => <>{children}</>,
}: QueueControlsProps) => {
  const queue = useQueue();
  const { loopChanged } = useMediaAnalytics();
  const analytics = useAnalytics();

  return (
    <>
      <Wrapper asChild>
        <Button
          variant={"transparentWhite"}
          onClick={() => {
            const newShuffleValue = queue.shuffle();
            analytics.track({
              event: "Tapped Shuffle",
              properties: {
                shuffled: newShuffleValue,
                prayer: queue.currentItem.prayer.id,
              },
            });
          }}
          styleXArray={[
            buttonStyles.default,
            queue.shuffled ? buttonStyles.active : null,
          ]}
        >
          <ShuffleIcon />
        </Button>
      </Wrapper>
      <Wrapper asChild>
        <PlayPause size={"l"} />
      </Wrapper>
      <Wrapper asChild>
        <Button
          variant={"transparentWhite"}
          onClick={() => {
            const newLoopValue = queue.repeat();
            loopChanged(newLoopValue);
          }}
          styleXArray={[
            buttonStyles.default,
            queue.repeating !== "none" ? buttonStyles.active : null,
          ]}
        >
          {queue.repeating === "one" ? <RepeatOneIcon /> : <RepeatIcon />}
        </Button>
      </Wrapper>
    </>
  );
};
