import { useMedia } from "react-use";

export type UseDynamicViewportHeightProps = {
  minimumHeight: number;
  defaultState?: boolean;
};
export const useDynamicViewportHeight = ({
  minimumHeight,
  defaultState = false,
}: UseDynamicViewportHeightProps) =>
  useMedia(`(min-height: ${minimumHeight}px)`, defaultState);
