import { URL_APP_STORE, URL_PLAY_STORE } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { forwardRef } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import { AppleIcon, GoogleIcon } from "../../icons/Icon/ExternalIcons";
import type { ButtonProps } from "../Button";
import { Button } from "../Button";
import { Text } from "../Text";

const styles = stylex.create({
  button: {
    gap: spacing.s,
    height: "fit-content",
  },
  div: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    marginTop: 2,
  },
});

export type DownloadAppButtonProps = WithStylexArray<
  Omit<ButtonProps, "color" | "endIcon" | "size" | "startIcon" | "variant">
> & {
  store?: "apple" | "google";
};

export const DownloadAppButton = forwardRef<any, DownloadAppButtonProps>(
  ({ store = "apple", styleXArray = [], ...props }, ref) => {
    const DeterminedExternalIcon =
      store === "apple" ? <AppleIcon /> : <GoogleIcon />;

    return (
      <Button
        asChild
        ref={ref}
        styleXArray={[styles.button]}
        variant="outline"
        {...props}
      >
        <Link href={store === "apple" ? URL_APP_STORE : URL_PLAY_STORE}>
          {DeterminedExternalIcon}
          <div {...stylex.props(styles.div)}>
            <Text size="m" type="detail">
              {store === "apple" ? "Download on the" : "Get it on"}
            </Text>
            {store === "apple" ? "App Store" : "Google Play"}
          </div>
        </Link>
      </Button>
    );
  },
);

DownloadAppButton.displayName = "DownloadAppButton";
