import * as stylex from "@stylexjs/stylex";
import { Fragment } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { BlockProps } from "../../../../refactor";
import { Block } from "../../../../refactor";
import type { NextUpMediumProps } from "../NextUpMedium";
import { NextUpMedium } from "../NextUpMedium";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  actions: {
    marginTop: spacing.m,
  },
  block: {
    display: {
      [cssMediaMinWidthTablet]: "flex",
      default: "none",
    },
  },
  nextUpMedium: {
    display: {
      [cssMediaMinWidthTablet]: "none",
      default: "flex",
    },
  },
});

export type NextUpLargeProps = {} & Pick<
  NextUpMediumProps,
  | "activeState"
  | "asChild"
  | "children"
  | "color"
  | "description"
  | "focusState"
  | "hoverState"
  | "imgSrc"
  | "rightActions"
  | "subscription"
  | "title"
> &
  Pick<NextUpMediumProps, "tag"> &
  Pick<BlockProps, "actions" | "metadata" | "metadataInlineIcon">;

export const NextUpLarge = ({
  actions,
  activeState = false,
  asChild,
  children,
  color,
  description,
  focusState = true,
  hoverState = true,
  imgSrc,
  metadata,
  metadataInlineIcon,
  rightActions,
  subscription,
  tag,
  title,
}: NextUpLargeProps) => {
  return (
    <Fragment>
      <Block
        actions={actions}
        actionsStyleXArray={[styles.actions]}
        activeState={activeState}
        color={color}
        description={description}
        focusState={focusState}
        hoverState={hoverState}
        imgSrc={imgSrc}
        metadata={metadata}
        metadataInlineIcon={metadataInlineIcon}
        styleXArray={[styles.block]}
        subscription={subscription}
        title={title}
      />
      <NextUpMedium
        centerActions={actions}
        activeState={activeState}
        asChild={asChild}
        children={children}
        color={color}
        description={description}
        focusState={focusState}
        hoverState={hoverState}
        imgSrc={imgSrc}
        rightActions={rightActions}
        subscription={subscription}
        tag={tag}
        title={title}
        styleXArray={[styles.nextUpMedium]}
      />
    </Fragment>
  );
};
