import { Root } from "@radix-ui/react-accessible-icon";

import { semanticColors } from "../../../../global/stylex/vars.stylex";
import type { HallowElement } from "../../../types";
import type { BasicIconProps } from "./Icon";
import { ProgressCircle } from "./ProgressCircle";

const Element: HallowElement = "svg";

export type StopCountdownProps = BasicIconProps & {
  total: number;
  remaining: number;
};

export const StopCountdown = ({
  total,
  remaining,
  label,
  size = 64,
  stroke = semanticColors.onOverlayMedium,
  ...props
}: StopCountdownProps) => {
  // these are based on the view-box, so we can make them constants
  const HALF_SVG_WIDTH = 32;
  const CIRCLE_RADIUS = 31;

  const progress = remaining / total;

  return (
    <Root label={label ?? ""}>
      <Element
        stroke={"none"}
        fill={"none"}
        height={size}
        viewBox={"0 0 64 64"}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x="22"
          y="22"
          rx="4"
          ry="4"
          height="20"
          width="20"
          fill={stroke}
        />
        <ProgressCircle
          stroke={stroke}
          circleRadius={CIRCLE_RADIUS}
          halfSvgWidth={HALF_SVG_WIDTH}
          progress={progress}
        />
      </Element>
    </Root>
  );
};
