import * as stylex from "@stylexjs/stylex";

export const stylesOpacity = stylex.create({
  active: {
    opacity: {
      default: 1,
      ":active": 0.5,
    },
  },
  base: {
    transition: "all 150ms ease-out",
  },
});
