"use client";

import type { DependencyList, MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";

export type UseScrollAreaProps = {
  dependencies?: DependencyList;
  timeout?: number;
  viewportRef: MutableRefObject<HTMLElement>;
};

export const useScrollArea = ({
  dependencies = [],
  timeout = 200,
  viewportRef,
}: UseScrollAreaProps) => {
  const timeoutRef = useRef<null | number>(null);
  const [canScrollBottom, setCanScrollBottom] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollTop, setCanScrollTop] = useState(false);
  const [scrollingX, setScrollingX] = useState(false);
  const [scrollingY, setScrollingY] = useState(false);
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const resetTimeout = () => window.clearTimeout(timeoutRef.current);

  useEffect(() => {
    const handleScroll = () => {
      resetTimeout();

      if (!viewportRef.current) return;

      const determinedX =
        viewportRef.current.clientWidth + viewportRef.current.scrollLeft;
      const determinedY =
        viewportRef.current.clientHeight + viewportRef.current.scrollTop;

      setCanScrollBottom(viewportRef.current.scrollHeight - determinedY > 1);
      setCanScrollLeft(viewportRef.current.scrollLeft > 0);
      setCanScrollRight(viewportRef.current.scrollWidth - determinedX > 1);
      setCanScrollTop(viewportRef.current.scrollTop > 0);

      if (
        scrollX !== viewportRef.current.scrollLeft &&
        viewportRef.current.scrollLeft > 0
      )
        setScrollingX(true);
      if (
        scrollY !== viewportRef.current.scrollTop &&
        viewportRef.current.scrollTop > 0
      )
        setScrollingY(true);

      setScrollX(viewportRef.current.scrollLeft);
      setScrollY(viewportRef.current.scrollTop);

      timeoutRef.current = window.setTimeout(() => {
        setScrollingX(false);
        setScrollingY(false);
      }, timeout);
    };

    handleScroll();

    if (viewportRef.current)
      viewportRef.current.addEventListener("scroll", handleScroll);

    if (window) window.addEventListener("resize", handleScroll);

    return () => {
      if (viewportRef.current)
        viewportRef.current.removeEventListener("scroll", handleScroll);

      if (window) window.removeEventListener("resize", handleScroll);
    };
  }, [scrollX, scrollY, viewportRef.current, ...dependencies]);

  const scroll = (props: ScrollToOptions) =>
    viewportRef.current ? viewportRef.current?.scrollTo(props) : null;

  return {
    canScrollBottom,
    canScrollLeft,
    canScrollRight,
    canScrollTop,
    scroll,
    scrollingX,
    scrollingY,
    scrollX,
    scrollY,
  };
};
