"use client";

import { useAnalytics } from "@packages/analytics/src/lib/useAnalytics";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useParams } from "next/navigation";

import {
  fontSizes,
  fontWeights,
  lineHeights,
  numericPixels,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { IconElement } from "../../../_base";
import { Button, IconButton, Text } from "../../../_base";

const styles = stylex.create({
  container: {
    "-webkit-tap-highlight-color": "transparent",
    alignItems: "center",
    backgroundColor: {
      default: "transparent",
      ":hover": "transparent",
      ":focus": "transparent",
      ":active": "transparent",
    },
    display: "flex",
    flexDirection: "column",
    maxWidth: numericPixels[64],
    padding: spacing.s,
    rowGap: spacing.none,
    width: numericPixels[64],
  },
  iconButton: {
    padding: spacing.none,
  },
  text: {
    color: semanticColors.onOverlayHighest,
    fontSize: fontSizes.buttonXS,
    fontWeight: fontWeights.buttonXS,
    lineHeight: lineHeights.buttonXS,
    paddingTop: spacing.s,
    textWrap: "nowrap",
  },
});

export type SocialIconButtonProps = {
  action?: string;
  isExternalIcon?: boolean;
  title: string;
  icon: IconElement;
  onClick?: () => void;
  type?: string;
  url?: string;
};

export const SocialIconButton = ({
  action,
  isExternalIcon = true,
  title,
  icon,
  onClick,
  type,
  url,
  ...props
}: SocialIconButtonProps) => {
  const analytics = useAnalytics();
  const params = useParams();

  const track = () => {
    if (type === "campaign") {
      analytics.track({
        event: "Tapped Share Campaign",
        properties: {
          campaign: params.id,
          action: action,
          share_sheet: "campaign",
        },
      });
    }
  };

  const titledButton = (
    <>
      <IconButton
        asChild
        icon={icon}
        variant={isExternalIcon ? "secondary" : "neutral"}
        styleXArray={[isExternalIcon && styles.iconButton]}
      >
        <span />
      </IconButton>
      <Text styleXArray={[styles.text]}>{title}</Text>
    </>
  );

  return (
    <span onClick={() => track()}>
      {url ? (
        <Link
          href={url}
          target="_blank"
          {...stylex.props(styles.container)}
          {...props}
        >
          {titledButton}
        </Link>
      ) : (
        <Button
          onClick={onClick}
          styleXArray={[styles.container]}
          {...props}
          size="m"
          variant="secondary"
        >
          {titledButton}
        </Button>
      )}
    </span>
  );
};
