"use client";

import * as stylex from "@stylexjs/stylex";
import { useEffect, useRef, useState } from "react";

import { voxAnimation } from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";

const dotStyles = stylex.create({
  dot: {
    transition: "all 250ms ease-out",
    willChange: "transform, height",
  },
  state0Dot1: {
    height: "2px",
    transform: "translateY(0)",
  },
  state0Dot2: {
    height: "2px",
    transform: "translateY(0)",
  },
  state0Dot3: {
    height: "2px",
    transform: "translateY(0)",
  },
  state0Dot4: {
    height: "2px",
    transform: "translateY(0)",
  },
  state0Dot5: {
    height: "2px",
    transform: "translateY(0)",
  },
  state1Dot1: {
    height: voxAnimation.stage7Height,
    transform: voxAnimation.stage7Transform,
  },
  state2Dot1: {
    height: voxAnimation.stage3Height,
    transform: voxAnimation.stage3Transform,
  },
  state3Dot1: {
    height: voxAnimation.stage6Height,
    transform: voxAnimation.stage6Transform,
  },
  state4Dot1: {
    height: voxAnimation.stage4Height,
    transform: voxAnimation.stage4Transform,
  },
  state5Dot1: {
    height: voxAnimation.stage2Height,
    transform: voxAnimation.stage2Transform,
  },
  state1Dot2: {
    height: voxAnimation.stage2Height,
    transform: voxAnimation.stage2Transform,
  },
  state2Dot2: {
    height: voxAnimation.stage6Height,
    transform: voxAnimation.stage6Transform,
  },
  state3Dot2: {
    height: voxAnimation.stage3Height,
    transform: voxAnimation.stage3Transform,
  },
  state4Dot2: {
    height: voxAnimation.stage6Height,
    transform: voxAnimation.stage6Transform,
  },
  state5Dot2: {
    height: voxAnimation.stage3Height,
    transform: voxAnimation.stage3Transform,
  },
  state1Dot3: {
    height: voxAnimation.stage4Height,
    transform: voxAnimation.stage4Transform,
  },
  state2Dot3: {
    height: voxAnimation.stage1Height,
    transform: voxAnimation.stage1Transform,
  },
  state3Dot3: {
    height: voxAnimation.stage6Height,
    transform: voxAnimation.stage6Transform,
  },
  state4Dot3: {
    height: voxAnimation.stage4Height,
    transform: voxAnimation.stage4Transform,
  },
  state5Dot3: {
    height: voxAnimation.stage7Height,
    transform: voxAnimation.stage7Transform,
  },
  state1Dot4: {
    height: voxAnimation.stage5Height,
    transform: voxAnimation.stage5Transform,
  },
  state2Dot4: {
    height: voxAnimation.stage3Height,
    transform: voxAnimation.stage3Transform,
  },
  state3Dot4: {
    height: voxAnimation.stage1Height,
    transform: voxAnimation.stage1Transform,
  },
  state4Dot4: {
    height: voxAnimation.stage7Height,
    transform: voxAnimation.stage7Transform,
  },
  state5Dot4: {
    height: voxAnimation.stage3Height,
    transform: voxAnimation.stage3Transform,
  },
  state1Dot5: {
    height: voxAnimation.stage7Height,
    transform: voxAnimation.stage7Transform,
  },
  state2Dot5: {
    height: voxAnimation.stage7Height,
    transform: voxAnimation.stage7Transform,
  },
  state3Dot5: {
    height: voxAnimation.stage4Height,
    transform: voxAnimation.stage4Transform,
  },
  state4Dot5: {
    height: voxAnimation.stage2Height,
    transform: voxAnimation.stage2Transform,
  },
  state5Dot5: {
    height: voxAnimation.stage6Height,
    transform: voxAnimation.stage6Transform,
  },
});

type SpecialNumberState = 0 | 1 | 2 | 3 | 4 | 5;

export type VoxProps = WithStylexArray<{
  playing: boolean;
  size?: number;
  fill?: string;
}>;

export const VoxIcon = ({
  playing,
  size = 24,
  fill = "white",
  styleXArray,
}: VoxProps) => {
  const [state, setState] = useState<SpecialNumberState>(0);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const stateRef = useRef<SpecialNumberState>(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    if (playing) {
      timer.current = setInterval(() => {
        let newState = stateRef.current + 1;
        if (newState > 5) newState = 1;
        setState(newState as SpecialNumberState);
      }, 500);
    } else {
      if (timer.current) clearInterval(timer.current);
      setState(0);
    }
  }, [playing]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...stylex.props(styleXArray)}
    >
      <rect
        {...stylex.props(dotStyles.dot, dotStyles[`state${state}Dot1`])}
        x="3"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        {...stylex.props(dotStyles.dot, dotStyles[`state${state}Dot2`])}
        x="7"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        {...stylex.props(dotStyles.dot, dotStyles[`state${state}Dot3`])}
        x="11"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        {...stylex.props(dotStyles.dot, dotStyles[`state${state}Dot4`])}
        x="15"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        {...stylex.props(dotStyles.dot, dotStyles[`state${state}Dot5`])}
        x="19"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
    </svg>
  );
};
