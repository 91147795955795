import { SECONDS_PER_MINUTE } from "@packages/sdk";

export type GetMinutesAndSecondsProps = {
  time: number;
  timeIsMs?: boolean;
};

const format = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
  maximumFractionDigits: 0,
});
export const getMinutesAndSeconds = ({
  time,
  timeIsMs = false,
}: GetMinutesAndSecondsProps): [string, string] => {
  let timeS = time;
  if (timeIsMs) timeS = time / 1000;

  const minutes = format.format(Math.floor(timeS / SECONDS_PER_MINUTE));
  const seconds = format.format(Math.floor(timeS % SECONDS_PER_MINUTE));

  return [minutes, seconds];
};
