import * as stylex from "@stylexjs/stylex";

export const stylesScale = stylex.create({
  base: {
    transition: "all 150ms ease-out",
  },
  l: {
    scale: {
      default: 1,
      ":hover": 1.015,
      ":active": 0.985,
    },
  },
  m: {
    scale: {
      default: 1,
      ":hover": 1.00875,
      ":active": 0.99125,
    },
  },
  s: {
    scale: {
      default: 1,
      ":hover": 1.0025,
      ":active": 0.9975,
    },
  },
});
