import { useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem } from "@packages/media";
import * as stylex from "@stylexjs/stylex";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  colors,
  semanticColors,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import {
  AudioControlsIcon,
  IconButton,
  ModalDropdownMenu,
  ModalDropdownMenuHeader,
  ModalTabContainer,
  ModalTabContent,
} from "../../../../../components";
import { ThemeContainer } from "../../../../../theme";
import type { WithButtonWrapper } from "../../../../../types";
import { Theme } from "../../../../../types";
import {
  SelectBackgroundTab,
  SelectBackgroundTabContent,
} from "../SelectBackground";
import { SelectGuideTab, SelectGuideTabContent } from "../SelectGuide";
import { SelectLengthTab, SelectLengthTabContent } from "../SelectLength";
import {
  SelectPlaybackSpeedTab,
  SelectPlaybackSpeedTabContent,
} from "../SelectPlaybackSpeed";
import { SelectTimerTab, SelectTimerTabContent } from "../SelectTimer";

const colorStyle = stylex.create({
  darkMode: {
    color: semanticColors.primary,
  },
  container: {
    background: colors.transparent,
  },
});

const overlayStyle = stylex.create({
  default: {
    zIndex: zIndices.overlayOverMediaPlayer,
  },
});

const zAxisStyle = stylex.create({
  default: {
    zIndex: zIndices.modalOverMediaPlayer,
  },
});

export type MediaMenuProps = WithButtonWrapper;

export const MediaMenu = ({
  Wrapper = ({ children }) => <>{children}</>,
}: MediaMenuProps) => {
  const t = useTranslations();
  const { currentItem } = useCurrentQueueItem();
  const analytics = useAnalytics();

  if (!currentItem)
    return (
      <IconButton
        key={"mediaSettingsButton"}
        icon={<AudioControlsIcon fill={semanticColors.onColorVeryHigh} />}
        disabled={true}
        variant="transparentWhite"
      />
    );

  const {
    bg_sounds_enabled,
    speed_changes_enabled,
    guide_id,
    id: audioId,
  } = currentItem.selected_audio;
  const hasGuides = currentItem.prayer.guides.length > 1;
  let hasLengths = false;
  for (const option of currentItem.prayer.tracks) {
    if (option.id === audioId) continue;
    if (option.guide_id === guide_id) {
      hasLengths = true;
      break;
    }
  }

  const contentStyleXArray = [
    themes.dark,
    colorStyle.darkMode,
    zAxisStyle.default,
  ];

  return (
    <ThemeContainer
      theme={Theme.DARK}
      styleXArray={[colorStyle.darkMode, colorStyle.container]}
    >
      <ModalDropdownMenu
        trigger={
          <Wrapper asChild>
            <IconButton
              key={"mediaSettingsButton"}
              icon={<AudioControlsIcon fill={semanticColors.onColorVeryHigh} />}
              variant="transparentWhite"
              onClickCapture={() =>
                analytics.track({
                  event: "Tapped Track Settings",
                })
              }
              tabIndex={0}
            />
          </Wrapper>
        }
        dropdownMenuProps={{
          contentStyleXArray,
        }}
        modalProps={{
          contentStyleXArray,
          overlayStyleXArray: [overlayStyle.default],
        }}
      >
        <ModalTabContainer defaultValue={"menu"}>
          <ModalTabContent
            value={"menu"}
            header={
              <ModalDropdownMenuHeader>
                {t("settings_title")}
              </ModalDropdownMenuHeader>
            }
          >
            {bg_sounds_enabled ? <SelectBackgroundTab /> : null}
            {hasGuides ? <SelectGuideTab /> : null}
            {hasLengths ? <SelectLengthTab /> : null}
            {speed_changes_enabled ? <SelectPlaybackSpeedTab /> : null}
            <SelectTimerTab />
          </ModalTabContent>
          {bg_sounds_enabled ? <SelectBackgroundTabContent /> : null}
          {hasGuides ? <SelectGuideTabContent /> : null}
          {hasLengths ? <SelectLengthTabContent /> : null}
          {speed_changes_enabled ? <SelectPlaybackSpeedTabContent /> : null}
          <SelectTimerTabContent />
        </ModalTabContainer>
      </ModalDropdownMenu>
    </ThemeContainer>
  );
};
