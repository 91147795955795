"use client";

import { useTranslations } from "@packages/i18n";
import {
  getValidatedCookiesHlwConsent,
  URL_COOKIE_POLICY,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { useRouter } from "next/navigation";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

import {
  colors,
  numericPercentages,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  Button,
  CloseIcon,
  FullscreenModal,
  ModalHeader,
} from "../../../_base";
import { IconButton } from "../../../_base";
import { Text } from "../../../_base";
import { Accordion } from "../../../refactor";
import { ConsentCheckbox } from "../ConsentCheckbox";

const IS_DESKTOP = "@media (min-width: 768px)";

const styles = stylex.create({
  accordion: {
    width: numericPercentages[100],
  },
  modalContent: {
    paddingTop: spacing.xl,
  },
  closeButton: {
    alignSelf: "flex-end",
  },
  consentContainer: {
    display: "flex",
    flexDirection: {
      [IS_DESKTOP]: "row",
      default: "column",
    },
    marginTop: spacing.l,
  },
  descriptionContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: spacing.xl,
    marginRight: spacing.xxl,
    padding: 0,
  },
  formContainer: {
    alignItems: "flex-end",
    borderTop: `solid 1px ${colors.grey30}`,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: spacing.xxl,
    width: numericPercentages[100],
  },
  link: {
    textDecoration: "underline",
  },
  submitButton: {
    marginTop: spacing.l,
  },
  title: {
    marginBottom: spacing.m,
  },
});

type Props = {
  saveConsent: (consent: number[] | null) => void;
  setConsentModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const CookieManager = ({
  saveConsent,
  setConsentModalVisible,
}: Props) => {
  const router = useRouter();
  const t = useTranslations();

  const [consentArray, setConsentArray] = useState<number[]>([1, 2, 3]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      const consentCookie = getValidatedCookiesHlwConsent();
      if (e.newURL.endsWith("cookie-manager")) {
        e.preventDefault();
        e.stopPropagation();

        // consent checkboxes should match current cookie consent
        updateConsentState();

        setOpen(true);
      } else if (open) setOpen(false);

      // if consent exists when we close CookieManager, hide ConsentModal
      if (consentCookie) setConsentModalVisible(false);
    });

    // handle page refresh
    if (window.location.hash.endsWith("cookie-manager")) setOpen(true);
    else setOpen(false);

    // consent checkboxes should match current cookie consent
    updateConsentState();
  }, []);

  const updateConsentState = () => {
    const consentCookie = getValidatedCookiesHlwConsent();

    if (consentCookie) setConsentArray(consentCookie);
    else setConsentArray([1, 2, 3]);
  };

  const close = () => {
    router.back();
    setOpen(false);
  };

  const updateConsentArray = (isChecked, value) => {
    const newConsentArray = [...consentArray];

    if (isChecked && !newConsentArray.includes(value))
      newConsentArray.push(value);
    else if (!isChecked && newConsentArray.includes(value)) {
      const idx = newConsentArray.indexOf(value);
      newConsentArray.splice(idx, 1);
    }

    newConsentArray.sort();
    setConsentArray(newConsentArray);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await saveConsent(consentArray);
      setOpen(false);

      // TODO: Add alert to user
      console.log(`Success!`);

      router.back();
    } catch (err) {
      console.log(err);
      // TODO: Add error to user; probably handle in error handling PR
    }
  };

  return (
    <FullscreenModal
      contentStyleXArray={[styles.modalContent]}
      open={open}
      onOpenChange={setOpen}
      showCloseButton={false}
    >
      <IconButton
        icon={<CloseIcon />}
        onClick={() => close()}
        styleXArray={[styles.closeButton]}
        variant={"white"}
      />
      <div {...stylex.props(styles.consentContainer)}>
        <div {...stylex.props(styles.descriptionContainer)}>
          <ModalHeader asChild>
            <Text size="s" type="headline" styleXArray={[styles.title]}>
              {t("cookie_manager_title")}
            </Text>
          </ModalHeader>
          <Text size="m" type="body">
            {t("cookie_manager_description", {
              0: t("cookie_manager_policy_link_lowercase"),
            })}
          </Text>
          <Text asChild size="m" type="body">
            <a
              href={URL_COOKIE_POLICY}
              rel="noreferrer"
              {...stylex.props(styles.link)}
              target="_blank"
            >
              {t("cookie_manager_policy_link_uppercase")}
            </a>
          </Text>
        </div>
        <div {...stylex.props(styles.formContainer)}>
          <Accordion styleXArray={[styles.accordion]} type={"single"}>
            {/* TODO: map over checkboxes */}
            <ConsentCheckbox
              description={t("cookie_manager_necessary_cookie_para")}
              disabled={true}
              id={"necessary-approval"}
              isChecked={true}
              label={t("cookie_manager_necessary_cookie_title")}
              onCheck={updateConsentArray}
              value={0}
            />
            <ConsentCheckbox
              description={t("cookie_manager_performance_cookie_para")}
              id={"performance-approval"}
              isChecked={consentArray.includes(2)}
              label={t("cookie_manager_performance_cookie_title")}
              onCheck={updateConsentArray}
              value={2}
            />
            <ConsentCheckbox
              description={t("cookie_manager_functional_cookie_para")}
              id={"functional-approval"}
              isChecked={consentArray.includes(1)}
              label={t("cookie_manager_functional_cookie_title")}
              onCheck={updateConsentArray}
              value={1}
            />
            <ConsentCheckbox
              description={t("cookie_manager_targeting_cookie_para")}
              id={"targeting-approval"}
              isChecked={consentArray.includes(3)}
              label={t("cookie_manager_targeting_cookie_title")}
              onCheck={updateConsentArray}
              value={3}
            />
          </Accordion>
          {/* TODO: add loading state to submit button */}
          <Button
            onClick={(e) => onSubmit(e)}
            styleXArray={[styles.submitButton]}
            type="submit"
            variant="color"
          >
            {t("button_save")}
          </Button>
        </div>
      </div>
    </FullscreenModal>
  );
};
