import stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { HallowElementProps, WithStylexArray } from "../../../../types";
import { Text } from "../../../_base/_core/Text";

const cssMediaMinWidthDesktop = "@media (min-width: 768px)";
const styles = stylex.create({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingBottom: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: spacing.s,
    },
    paddingLeft: {
      [cssMediaMinWidthDesktop]: spacing.xl,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthDesktop]: spacing.l,
      default: spacing.m,
    },
    paddingTop: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: spacing.s,
    },
    width: spacing.full,
  },
  title: {
    padding: {
      [cssMediaMinWidthDesktop]: `${spacing.m} 0`,
      default: `${spacing.xs} 0`,
    },
  },
  titleWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: spacing.m,
  },
});

export type PanelContentContainerProps = WithStylexArray<
  HallowElementProps<"div">
> & {
  title?: string;
  titleActionLeft?: ReactNode;
  titleActionRight?: ReactNode;
};

export const PanelContentContainer = ({
  children,
  styleXArray,
  title,
  titleActionLeft,
  titleActionRight,
  ...props
}: PanelContentContainerProps) => {
  return (
    <div {...stylex.props(styles.container, styleXArray)} {...props}>
      <div {...stylex.props(styles.titleWrapper)}>
        {titleActionLeft && titleActionLeft}
        {title && (
          <Text type="headline" size="m" as="h1" styleXArray={[styles.title]}>
            {title}
          </Text>
        )}
        {titleActionRight && titleActionRight}
      </div>
      {children}
    </div>
  );
};
