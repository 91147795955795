"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../global/stylex/styles";
import {
  colors,
  radius,
  semanticColors,
  spacing,
} from "../../../../global/stylex/vars.stylex";
import { useStylex } from "../../../hooks";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  StyleXArray,
  WithAsChild,
  WithStylexArray,
} from "../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../utils";
import type { IconElement, MaskProps, TextProps } from "../../_base";
import { Mask, SubscriptionIcon, Tag, Text } from "../../_base";
import { InlineIcon } from "../../_base";
import { HallowImage } from "../../blocks";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  actions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  description: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    color: semanticColors.onColorHighest,
    display: "-webkit-box",
    overflow: "hidden",
  },
  determinedElement: (props: { color: BlockProps["color"] }) => ({
    backgroundColor: props.color,
    borderRadius: radius.l,
    display: "flex",
    height: "480px",
    width: spacing.full,
  }),
  infoContainer: {
    borderBottomLeftRadius: radius.l,
    borderTopLeftRadius: radius.l,
    color: colors.white,
    display: "flex",
    flex: 2,
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
    minWidth: "348px",
    paddingLeft: "80px",
  },
  img: {
    "-webkit-user-drag": "none",
    borderBottomRightRadius: radius.l,
    borderTopRightRadius: radius.l,
    height: spacing.full,
    maxWidth: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    userSelect: "none",
    width: spacing.full,
  },
  imgContainer: {
    flex: 3,
    height: spacing.full,
    position: "relative",
  },
  mask: {
    left: -1,
    position: "absolute",
  },
  metadataContainer: {
    alignItems: "center",
    color: semanticColors.onColorVeryHigh,
    display: "flex",
    gap: spacing.xs,
  },
  tagsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
    maxWidth: "480px",
  },
});

export type BlockProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  actions?: KeyedElement[];
  actionsStyleXArray?: StyleXArray;
  activeState?: boolean;
  color?: MaskProps["color"];
  description?: string;
  descriptionProps?: TextProps;
  focusState?: boolean;
  hoverState?: boolean;
  imgContainerProps?: HallowElementProps<"div">;
  imgContainerStyleXArray?: StyleXArray;
  imgSrc?: Images;
  metadata?: string;
  metadataInlineIcon?: IconElement;
  subscription?: boolean;
  tags?: string[];
  title?: string;
};

export const Block = forwardRef<any, BlockProps>(
  (
    {
      actions,
      actionsStyleXArray,
      activeState = false,
      asChild = false,
      children,
      color = "black",
      description,
      descriptionProps,
      focusState = true,
      hoverState = true,
      imgContainerProps,
      imgContainerStyleXArray,
      imgSrc,
      metadata,
      metadataInlineIcon,
      styleXArray,
      subscription,
      tags,
      title,
      ...props
    },
    ref,
  ) => {
    const { getStylexColorVarValue } = useStylex();

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          {title && (
            <div {...stylex.props(styles.titleContainer)}>
              {subscription && <SubscriptionIcon.Filled />}
              <Text size="l" type="headline">
                {title}
              </Text>
            </div>
          )}
          {tags?.length > 0 && (
            <div {...stylex.props(styles.tagsContainer)}>
              {tags?.map((tag, index) => (
                <Tag key={`${tag}${index}`} variant="lighten">
                  {tag}
                </Tag>
              ))}
            </div>
          )}
          {description && (
            <Text
              {...({
                size: "m",
                type: "body",
                ...descriptionProps,
                styleXArray: [
                  styles.description,
                  descriptionProps?.styleXArray,
                ],
              } as TextProps)}
            >
              {description}
            </Text>
          )}
          {metadata && (
            <div {...stylex.props(styles.metadataContainer)}>
              {metadataInlineIcon && <InlineIcon icon={metadataInlineIcon} />}
              <Text overflow="ellipsis" size="l" type="detail">
                {metadata}
              </Text>
            </div>
          )}
          {actions?.length > 0 && (
            <div {...stylex.props(styles.actions, actionsStyleXArray)}>
              {actions}
            </div>
          )}
        </div>,
        <div
          key="imageContainer"
          {...imgContainerProps}
          {...stylex.props(styles.imgContainer, imgContainerStyleXArray)}
        >
          {imgContainerProps?.children}
          <Mask
            color={color}
            direction="to right"
            orientation="vertical"
            styleXArray={[styles.mask]}
          />
          {imgSrc && (
            <HallowImage
              size={"l"}
              alt={title}
              src={imgSrc}
              {...stylex.props(styles.img)}
            />
          )}
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.determinedElement({
            color: getStylexColorVarValue({ color }),
          }),
          activeState ? stylesOpacity.active : null,
          activeState ? stylesOpacity.base : null,
          stylesOutline.base,
          focusState ? stylesOutline.focus : null,
          hoverState ? stylesOutline.hover : null,
          activeState && hoverState ? stylesScale.base : null,
          activeState && hoverState ? stylesScale.s : null,
          styleXArray,
        )}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

Block.displayName = "Block";
