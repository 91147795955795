import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import { Text } from "../Text";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    alignContent: "center",
    backgroundColor: semanticColors.accentLowest,
    borderRadius: radius.ms,
    color: semanticColors.accentHigher,
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    marginTop: numericPixels[15],
    minWidth: {
      [cssMediaMinWidthDesktop]: numericPixels[300],
      default: spacing.none,
    },
    paddingBottom: spacing.xl,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    paddingTop: spacing.xl,
    position: "relative",
    textAlign: "center",
    width: spacing.fit,
  },
  headline: {
    alignItems: "center",
    backgroundColor: semanticColors.accentHigh,
    borderRadius: numericPixels[5],
    color: semanticColors.accentLowest,
    display: "flex",
    height: numericPixels[30],
    left: numericPercentages[50],
    paddingLeft: numericPixels[10],
    paddingRight: numericPixels[10],
    position: "absolute",
    textTransform: "uppercase",
    top: spacing.none,
    transform: `translate(-50%, -50%)`,
  },
});

export type PromotionalOfferProps = WithAsChild<
  WithStylexArray<Omit<HallowElementProps<typeof hallowElement>, "children">>
> & {
  description?: string;
  headline?: string;
  title?: string;
};

export const PromotionalOffer = forwardRef<any, PromotionalOfferProps>(
  (
    {
      asChild = false,
      description,
      styleXArray = [],
      headline,
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
      >
        {headline && (
          <Text size="xs" type="title" styleXArray={[styles.headline]}>
            {headline}
          </Text>
        )}
        <Text size="s" type="headline">
          {title}
        </Text>
        <Text size="xl" type="detail">
          {description}
        </Text>
      </DeterminedElement>
    );
  },
);

PromotionalOffer.displayName = "PromotionalOffer";
