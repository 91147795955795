"use client";

import stylex from "@stylexjs/stylex";

import {
  numericPercentages,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";

const IS_LARGE_SCREEN = "@media screen and (min-width: 1200px)";

const styles = stylex.create({
  content: {
    backgroundColor: semanticColors.onOverlayBackground,
    borderRadius: `${radius.l} ${radius.l} 0 0`,
    bottom: spacing.none,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    left: numericPercentages[50],
    maxHeight: "92vh",
    maxWidth: {
      [IS_LARGE_SCREEN]: "66vw",
      default: "976px",
    },
    overflow: "auto",
    padding: spacing.l,
    position: "fixed",
    top: "8%",
    transform: "translateX(-50%)",
    width: numericPercentages[100],
  },
});

export type OverlayModalProps = ModalProps;

export const OverlayModal = ({
  contentStyleXArray = [],
  ...props
}: OverlayModalProps) => {
  return (
    <Modal
      {...props}
      contentStyleXArray={[styles.content, ...contentStyleXArray]}
    />
  );
};
