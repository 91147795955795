import * as RadixUiSeparator from "@radix-ui/react-separator";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import {
  numericPercentages,
  numericPixels,
  semanticColors,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";

const styles = stylex.create({
  base: {
    backgroundColor: semanticColors.neutralsLowest,
  },
});

const stylesOrientation = stylex.create({
  horizontal: {
    height: numericPixels[1],
    width: numericPercentages[100],
  },
  vertical: {
    height: numericPercentages[100],
    width: numericPixels[1],
  },
});

export type DividerProps = WithStylexArray<
  ComponentProps<typeof RadixUiSeparator.Root>
>;

export const Divider = ({
  className,
  orientation = "horizontal",
  styleXArray = [],
  ...props
}: DividerProps) => (
  <RadixUiSeparator.Root
    {...props}
    {...stylex.props(styles.base, stylesOrientation[orientation], styleXArray)}
  />
);
