import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  numericPercentages,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    alignItems: "center",
    color: semanticColors.primary,
    display: "flex",
    gap: spacing.ms,
    height: spacing.xxxl,
    justifyContent: "space-between",
    paddingBottom: spacing.m,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    paddingTop: spacing.m,
    width: numericPercentages[100],
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    height: spacing.xl,
    justifyContent: "center",
    width: spacing.xl,
  },
});

export type ListHeaderProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  icon?: IconElement;
  title: string;
};

export const ListHeader = forwardRef<any, ListHeaderProps>(
  (
    { asChild = false, children, icon, styleXArray = [], title, ...props },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <Text overflow="ellipsis" key="text" size="m" type="title">
          {title}
        </Text>,
        <span key="iconContainer" {...stylex.props(styles.iconContainer)}>
          {icon && icon}
        </span>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListHeader.displayName = "ListHeader";
