"use client";

import { useCallback, useEffect, useRef, useState } from "react";

export const useStatePromise = <T>(
  initialState: T,
): [T, (newState: T) => Promise<T>] => {
  const [state, setState] = useState<T>(initialState);
  const resolver = useRef<null | ((value: any) => void)>(null);

  const _setState = useCallback(
    (newState: T): Promise<T> => {
      return new Promise((resolve) => {
        setState(state);
        if (newState === state) resolve(state);
        else {
          resolver.current = resolve;
        }
      });
    },
    [state],
  );

  useEffect(() => {
    resolver.current?.(state);
  }, [state]);

  return [state, _setState];
};
