import { useTranslations } from "@packages/i18n";
import { SPEEDS, usePlayerSpeedIndex } from "@packages/media";

import {
  ModalDropdownMenuHeader,
  ModalDropdownMenuItem,
  ModalTabContent,
} from "../../../../../components";

export const SelectPlaybackSpeedTabContent = () => {
  const { currentIdx, player } = usePlayerSpeedIndex();
  const t = useTranslations();

  return (
    <ModalTabContent
      value={"speedSelectMenu"}
      key={"speedSelectMenu"}
      header={
        <ModalDropdownMenuHeader>
          {t("track_settings_playback_speed")}
        </ModalDropdownMenuHeader>
      }
    >
      {SPEEDS.map((s, idx) => (
        <ModalDropdownMenuItem
          key={`speed_${s}`}
          selected={idx === currentIdx}
          onSelect={player ? () => player.setSpeed(s) : undefined}
        >
          {t("web_audio_player_speed", { "0": s })}
        </ModalDropdownMenuItem>
      ))}
    </ModalTabContent>
  );
};
