"use client";

import * as RadixUIDialog from "@radix-ui/react-dialog";
import * as stylex from "@stylexjs/stylex";
import { type ComponentProps, type ReactNode } from "react";

import {
  numericPercentages,
  radius,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { useTheme } from "../../../../theme";
import type { StyleXArray, WithStylexArray } from "../../../../types";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  content: {
    backgroundColor: semanticColors.onOverlayBackground,
    borderRadius: radius.l,
    bottom: {
      [cssMediaMinWidthDesktop]: "auto",
      default: spacing.s,
    },
    display: "flex",
    flexDirection: "column",
    left: numericPercentages[50],
    maxWidth: `calc(100vw - ${spacing.s} - ${spacing.s})`,
    overflow: "hidden",
    padding: {
      [cssMediaMinWidthDesktop]: spacing.s,
      default: spacing.m,
    },
    position: "fixed",
    top: {
      [cssMediaMinWidthDesktop]: numericPercentages[50],
      default: "auto",
    },
    transform: {
      [cssMediaMinWidthDesktop]: "translate(-50%, -50%)",
      default: "translate(-50%, 0%)",
    },
    width: spacing.full,
    zIndex: zIndices.modalContent,
  },
  close: {
    backgroundColor: semanticColors.onOverlayDarkMedLow,
    borderRadius: radius.xxs,
    borderWidth: spacing.none,
    cursor: "pointer",
    display: {
      [cssMediaMinWidthDesktop]: "none",
      default: "block",
    },
    height: spacing.xxs,
    marginBottom: spacing.s,
    marginLeft: "auto",
    marginRight: "auto",
    width: spacing.xl,
  },
  overlay: {
    backgroundColor: semanticColors.higherContrastMed,
    inset: spacing.none,
    position: "fixed",
    zIndex: zIndices.modalOverlay,
  },
});

export type ModalProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Root>
> & {
  contentProps?: Omit<ComponentProps<typeof RadixUIDialog.Content>, "children">;
  contentStyleXArray?: StyleXArray;
  hasMobilePulldownBar?: boolean;
  overlayProps?: ComponentProps<typeof RadixUIDialog.Overlay>;
  overlayStyleXArray?: StyleXArray;
  portalProps?: ComponentProps<typeof RadixUIDialog.Portal>;
  trigger?: ReactNode;
};

export const Modal = ({
  children,
  contentProps,
  contentStyleXArray = [],
  hasMobilePulldownBar = false,
  overlayProps,
  overlayStyleXArray = [],
  portalProps,
  styleXArray = [],
  trigger,
  ...props
}: ModalProps) => {
  const { themeContainerRef } = useTheme();

  return (
    <RadixUIDialog.Root {...props} {...stylex.props(...styleXArray)}>
      <RadixUIDialog.Trigger asChild>{trigger}</RadixUIDialog.Trigger>
      <RadixUIDialog.Portal
        container={themeContainerRef.current}
        {...portalProps}
      >
        <RadixUIDialog.Overlay
          {...overlayProps}
          {...stylex.props(styles.overlay, ...overlayStyleXArray)}
        />
        <RadixUIDialog.Content
          {...contentProps}
          {...stylex.props(styles.content, ...contentStyleXArray)}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          {hasMobilePulldownBar && (
            <RadixUIDialog.Close {...stylex.props(styles.close)} />
          )}
          {children}
        </RadixUIDialog.Content>
      </RadixUIDialog.Portal>
    </RadixUIDialog.Root>
  );
};
