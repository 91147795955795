"use client";

import * as RadixUiAccordion from "@radix-ui/react-accordion";
import type { ComponentProps, ReactElement } from "react";

import type { WithAsChild, WithStylexArray } from "../../../types";

export type AccordionItemProps = WithAsChild<
  WithStylexArray<
    ComponentProps<typeof RadixUiAccordion.AccordionItem> & {
      trigger: ReactElement;
    }
  >
>;

export const AccordionItem = ({
  asChild = false,
  children,
  trigger,
  value,
}: AccordionItemProps) => {
  return (
    <RadixUiAccordion.Item value={value}>
      <RadixUiAccordion.Trigger asChild={asChild}>
        {trigger}
      </RadixUiAccordion.Trigger>
      <RadixUiAccordion.Content>
        <div>{children}</div>
      </RadixUiAccordion.Content>
    </RadixUiAccordion.Item>
  );
};
