"use client";

import type { ForwardedRef, MutableRefObject } from "react";
import { useRef } from "react";

/**
 * This function links a forwarded ref (which is always optional) to a local ref so we can refer to it locally without
 * worrying about whether someone passed a ref to the component.
 * @param {React.ForwardedRef<any>} ref The forwarded ref made from forwardRef
 */
export const useLocalRef = <T>(ref: ForwardedRef<T>) => {
  const localRef = useRef<T | null>(null);
  return new Proxy<MutableRefObject<T | null>>(localRef, {
    set: (target, prop, value) => {
      if (ref) {
        if (ref as MutableRefObject<T>) {
          (ref as MutableRefObject<T>)[prop] = value;
        } else {
          (ref as Function)(value);
        }
      }
      return Reflect.set(target, prop, value);
    },
  });
};
