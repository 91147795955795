import type { BasicIconProps as IconProps } from "./Icon";
import { IconWrapper } from "./Icon";

export const ArrowDownIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 12.7929C17.0976 13.1834 17.0976 13.8166 16.7071 14.2071L12.7071 18.2071C12.3166 18.5976 11.6834 18.5976 11.2929 18.2071L7.29289 14.2071C6.90237 13.8166 6.90237 13.1834 7.29289 12.7929C7.68342 12.4024 8.31658 12.4024 8.70711 12.7929L11 15.0858L11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5L13 15.0858L15.2929 12.7929C15.6834 12.4024 16.3166 12.4024 16.7071 12.7929Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ArrowLeftIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2071 7.29289C11.5976 7.68342 11.5976 8.31658 11.2071 8.70711L8.91421 11H17.5C18.0523 11 18.5 11.4477 18.5 12C18.5 12.5523 18.0523 13 17.5 13H8.91421L11.2071 15.2929C11.5976 15.6834 11.5976 16.3166 11.2071 16.7071C10.8166 17.0976 10.1834 17.0976 9.79289 16.7071L5.79289 12.7071C5.40237 12.3166 5.40237 11.6834 5.79289 11.2929L9.79289 7.29289C10.1834 6.90237 10.8166 6.90237 11.2071 7.29289Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ArrowRightIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7929 7.29289C13.1834 6.90237 13.8166 6.90237 14.2071 7.29289L18.2071 11.2929C18.5976 11.6834 18.5976 12.3166 18.2071 12.7071L14.2071 16.7071C13.8166 17.0976 13.1834 17.0976 12.7929 16.7071C12.4024 16.3166 12.4024 15.6834 12.7929 15.2929L15.0858 13H6.5C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11H15.0858L12.7929 8.70711C12.4024 8.31658 12.4024 7.68342 12.7929 7.29289Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ArrowUpIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 11.2071C6.90237 10.8166 6.90237 10.1834 7.29289 9.79289L11.2929 5.79289C11.6834 5.40237 12.3166 5.40237 12.7071 5.79289L16.7071 9.79289C17.0976 10.1834 17.0976 10.8166 16.7071 11.2071C16.3166 11.5976 15.6834 11.5976 15.2929 11.2071L13 8.91421L13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5L11 8.91421L8.70711 11.2071C8.31658 11.5976 7.68342 11.5976 7.29289 11.2071Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ChevronAddIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929L12.5858 12L9.29289 8.70711C8.90237 8.31658 8.90237 7.68342 9.29289 7.29289Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ChevronDownIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 10.2929C7.68342 9.90237 8.31658 9.90237 8.70711 10.2929L12 13.5858L15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ChevronLeftIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L11.4142 12L14.7071 15.2929C15.0976 15.6834 15.0976 16.3166 14.7071 16.7071C14.3166 17.0976 13.6834 17.0976 13.2929 16.7071L9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ChevronRightIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929L12.5858 12L9.29289 8.70711C8.90237 8.31658 8.90237 7.68342 9.29289 7.29289Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ChevronUpIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 13.7071C16.3166 14.0976 15.6834 14.0976 15.2929 13.7071L12 10.4142L8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071C6.90237 13.3166 6.90237 12.6834 7.29289 12.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L16.7071 12.2929C17.0976 12.6834 17.0976 13.3166 16.7071 13.7071Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const CloseIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7063 7.70711C18.0968 7.31658 18.0968 6.68342 17.7063 6.29289C17.3157 5.90237 16.6826 5.90237 16.292 6.29289L11.9994 10.5855L7.70711 6.29327C7.31658 5.90275 6.68342 5.90275 6.29289 6.29328C5.90237 6.6838 5.90237 7.31697 6.29289 7.70749L10.5852 11.9998L6.29289 16.292C5.90237 16.6825 5.90237 17.3157 6.29289 17.7062C6.68342 18.0968 7.31658 18.0968 7.70711 17.7062L11.9994 13.414L16.292 17.7066C16.6826 18.0971 17.3157 18.0971 17.7063 17.7066C18.0968 17.3161 18.0968 16.6829 17.7063 16.2924L13.4136 11.9998L17.7063 7.70711Z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

CloseIcon.Filled = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm3.707-13.707a1 1 0 00-1.414 0L12 10.586 9.707 8.293a1 1 0 00-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 101.414 1.414L12 13.414l2.293 2.293a1 1 0 001.414-1.414L13.414 12l2.293-2.293a1 1 0 000-1.414z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const EnterIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9a5 5 0 00-5-5H13a1 1 0 100 2H16a3 3 0 013 3v6a3 3 0 01-3 3h-3a1 1 0 100 2h3a5 5 0 005-5V9zM9.707 16.707a1 1 0 01-1.414-1.414L10.586 13H4a1 1 0 110-2h6.586L8.293 8.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ExitIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4a5 5 0 00-5 5v6a5 5 0 005 5h3a1 1 0 100-2H8a3 3 0 01-3-3V9a3 3 0 013-3H11a1 1 0 100-2H8zm8.707 3.293a1 1 0 10-1.414 1.414L17.586 11H11a1 1 0 100 2h6.586l-2.293 2.293a1 1 0 001.414 1.414l4-4a1 1 0 000-1.414l-4-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ExportIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4a1 1 0 000 2h2.586l-5.293 5.293a1 1 0 001.414 1.414L18 7.414V10a1 1 0 102 0V5a1 1 0 00-1-1h-5zM9 4a5 5 0 00-5 5v6a5 5 0 005 5h6a5 5 0 005-5v-1a1 1 0 10-2 0v1a3 3 0 01-3 3H9a3 3 0 01-3-3V9a3 3 0 013-3h1a1 1 0 000-2H9z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToAlbumIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5a6 6 0 00-6 6H5a8 8 0 118 8v-2a6 6 0 000-12zm-9 9a1 1 0 011-1h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.586l-4.293 4.293a1 1 0 01-1.414-1.414L7.586 15H5a1 1 0 01-1-1zm9-1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToCollectionIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19c-1.425 0-2.394-.001-3.147-.052-.735-.05-1.117-.142-1.384-.253a4 4 0 01-2.165-2.164c-.11-.267-.201-.65-.252-1.384C5.002 14.394 5 13.425 5 12c0-1.425.001-2.394.052-3.147.05-.735.142-1.117.252-1.384A4 4 0 017.47 5.304c.267-.11.65-.201 1.384-.252C9.606 5.002 10.575 5 12 5c1.425 0 2.394.001 3.147.052.735.05 1.117.142 1.384.252a4 4 0 012.165 2.165c.11.267.201.65.252 1.384.05.753.052 1.722.052 3.147 0 1.425-.001 2.394-.052 3.147-.05.735-.142 1.117-.253 1.384a4 4 0 01-2.164 2.165c-.267.11-.65.201-1.384.252-.753.05-1.722.052-3.147.052zm-9-7c0-2.796 0-4.193.457-5.296a6 6 0 013.247-3.247C7.807 3 9.204 3 12 3c2.796 0 4.194 0 5.296.457a6 6 0 013.247 3.247C21 7.807 21 9.204 21 12c0 2.796 0 4.194-.457 5.296a6 6 0 01-3.247 3.247C16.194 21 14.796 21 12 21c-2.796 0-4.193 0-5.296-.457a6 6 0 01-3.247-3.247C3 16.194 3 14.796 3 12zm8-4a1 1 0 100 2h1.586l-4.293 4.293a1 1 0 101.414 1.414L14 11.414V13a1 1 0 102 0V9a1 1 0 00-1-1h-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToCollection2Icon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3a4 4 0 00-4 4 4 4 0 00-4 4h2a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2v2a4 4 0 004-4 4 4 0 004-4V7a4 4 0 00-4-4h-4zm4 10a2 2 0 002-2V7a2 2 0 00-2-2h-4a2 2 0 00-2 2h2a4 4 0 014 4v2zM4 14a1 1 0 011-1h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.586l-4.293 4.293a1 1 0 01-1.414-1.414L7.586 15H5a1 1 0 01-1-1z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToRoutineIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a1 1 0 00-1 1v1a4 4 0 00-4 4v10a4 4 0 004 4h12a4 4 0 004-4V8a4 4 0 00-4-4V3a1 1 0 10-2 0v1H8V3a1 1 0 00-1-1zm0 4h11a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V8a2 2 0 012-2h1zm4 3a1 1 0 100 2h1.586l-4.293 4.293a1 1 0 101.414 1.414L14 12.414V14a1 1 0 102 0v-4a1 1 0 00-1-1h-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToRoutine2Icon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4a1 1 0 011 1v1h4V5a1 1 0 112 0v1a3 3 0 013 3v6a3 3 0 01-3 3h-2v-2h2a1 1 0 001-1V9a1 1 0 00-1-1H8a1 1 0 00-1 1v1H5V9a3 3 0 013-3V5a1 1 0 011-1zm-3 8a1 1 0 100 2h2.586l-4.293 4.293a1 1 0 101.414 1.414L10 15.414V18a1 1 0 102 0v-5a1 1 0 00-1-1H6z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToSessionIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm0 16a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm16-9a1 1 0 100 2h1a1 1 0 100-2h-1zM3 12a1 1 0 011-1h2a1 1 0 110 2H4a1 1 0 01-1-1zm8-4a1 1 0 100 2h1.586l-4.293 4.293a1 1 0 101.414 1.414L14 11.414V13a1 1 0 102 0V9a1 1 0 00-1-1h-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GoToSession2Icon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4a4 4 0 00-4 4v2h2V8a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2h-2v2h2a4 4 0 004-4V8a4 4 0 00-4-4h-6zm-4 8a1 1 0 100 2h2.586l-4.293 4.293a1 1 0 101.414 1.414L10 15.414V18a1 1 0 102 0v-5a1 1 0 00-1-1H6z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const TurnRightIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.293 5.293a1 1 0 011.414 0l1.94 1.94 1.06 1.06a1.001 1.001 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L15.586 10H11a3 3 0 00-3 3v5a1 1 0 11-2 0v-5a5 5 0 015-5h4.586l-1.293-1.293a1 1 0 010-1.414z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const TurnLeftIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.707 5.293a1 1 0 00-1.414 0l-3 3a1.001 1.001 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 10H14a3 3 0 013 3v5a1 1 0 102 0v-5a5 5 0 00-5-5H9.414l1.293-1.293a1 1 0 000-1.414z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const MenuIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm0 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm5 5a1 1 0 100 2h10a1 1 0 100-2H9z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const MoveIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9a1 1 0 000 2h12a1 1 0 100-2H6zm0 4a1 1 0 100 2h12a1 1 0 100-2H6z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const MoreIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);
