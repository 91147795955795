"use client";

import type { Guide } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type { ReactEventHandler } from "react";
import { forwardRef, useState } from "react";

import {
  colors,
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
  tints,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Avatar,
  PauseIcon,
  PlayIcon,
  ProgressCircle,
  Text,
} from "../../../../../components";
import { useLocalRef } from "../../../../../hooks";

const styles = stylex.create({
  avatar: {
    gridArea: "avatar",
    position: "relative",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    rowGap: spacing.s,
    width: numericPercentages[100],
  },
  header: {
    columnGap: spacing.ms,
    display: "grid",
    gridTemplateAreas: '"avatar title" "avatar tagline"',
    gridTemplateColumns: "min-content 1fr",
    gridTemplateRows: "1fr 1fr",
    rowGap: spacing.xxs,
  },
  name: {
    gridArea: "title",
    margin: numericValues[0],
    placeSelf: "end start",
  },
  sample: {
    background: semanticColors.onOverlayLow,
    border: numericValues[0],
    borderRadius: radius.full,
    bottom: numericValues[0],
    cursor: "pointer",
    lineHeight: numericValues[0],
    outline: "none",
    padding: numericValues[0],
    position: "absolute",
    right: numericValues[0],
  },
  tagline: {
    color: semanticColors.onOverlayMedium,
    gridArea: "tagline",
    placeSelf: "start start",
  },
});

export type GuideInfoProps = {
  guide?: Guide;
  includeSample?: boolean;
  pauseOthers?: () => void;
};

export const GuideInfo = forwardRef<HTMLAudioElement, GuideInfoProps>(
  ({ guide, includeSample = false, pauseOthers }, ref) => {
    const sampleRef = useLocalRef<HTMLAudioElement>(ref);
    const [playing, setPlaying] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [started, setStarted] = useState<boolean>(false);

    if (!guide) return null;

    const playSample = () => {
      if (pauseOthers) pauseOthers();
      if (sampleRef.current?.paused) {
        sampleRef.current?.play();
      } else {
        sampleRef.current?.pause();
      }
    };

    const updateProgress: ReactEventHandler<HTMLAudioElement> = (e) => {
      requestAnimationFrame(() => {
        setProgress(
          (e.target as HTMLAudioElement).currentTime /
            (e.target as HTMLAudioElement).duration,
        );
      });
    };

    const handlePlay = () => {
      setPlaying(true);
      setStarted(true);
    };

    const sample =
      includeSample && guide.sample_audio_url ? (
        <>
          <button {...stylex.props(styles.sample)} onClick={playSample}>
            <svg
              stroke={"none"}
              fill={"none"}
              height={24}
              viewBox={"0 0 24 24"}
              width={24}
              xmlns="http://www.w3.org/2000/svg"
            >
              {!started ? (
                <circle cx={12} cy={12} r={10} fill={colors.white} />
              ) : (
                <ProgressCircle
                  circleRadius={10}
                  halfSvgWidth={12}
                  progress={progress}
                  stroke={colors.white}
                  backgroundStroke={tints.tint20}
                />
              )}
              {!playing ? (
                <PlayIcon.Medium
                  size={12}
                  fill={!started ? semanticColors.onOverlayLow : colors.white}
                  x={6}
                  y={6}
                />
              ) : (
                <PauseIcon.Medium size={12} fill={colors.white} x={6} y={6} />
              )}
            </svg>
          </button>
          <audio
            ref={sampleRef}
            src={guide.sample_audio_url}
            onTimeUpdate={updateProgress}
            onEnded={() => setStarted(false)}
            onPlaying={handlePlay}
            onPause={() => setPlaying(false)}
          />
        </>
      ) : null;

    return (
      <div {...stylex.props(styles.container)}>
        <div {...stylex.props(styles.header)}>
          <div {...stylex.props(styles.avatar)}>
            <Avatar name={guide.name} imgSrc={guide.image_url} size={64} />
            {sample}
          </div>
          <Text as={"h2"} type={"title"} size={"m"} styleXArray={[styles.name]}>
            {guide.name}
          </Text>
          <Text type={"detail"} size={"l"} styleXArray={[styles.tagline]}>
            {guide.tagline}
          </Text>
        </div>
        <Text type={"body"} size={"s"}>
          {guide.desc}
        </Text>
      </div>
    );
  },
);

GuideInfo.displayName = "GuideInfo";
