import type { ValidateLocalStorageSchemaProps } from "./validateLocalStorageSchema";
import { validateLocalStorageSchema } from "./validateLocalStorageSchema";

export type GetValidatedLocalStorageValueProps<SchemaType> =
  ValidateLocalStorageSchemaProps<SchemaType> & {
    defaultValue: SchemaType;
  };

export const getValidatedLocalStorageValue = <SchemaType>({
  key,
  schema,
  defaultValue,
}: GetValidatedLocalStorageValueProps<SchemaType>) => {
  try {
    return validateLocalStorageSchema({ key, schema }) ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};
