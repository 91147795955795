export type DeepMergeStyleXPropsProps = {
  object1: object;
  object2: object;
};

export const deepMergeStyleXProps = ({
  object1,
  object2,
}: DeepMergeStyleXPropsProps) =>
  [object1, object2].reduce((deepMerged, object) => {
    Object.entries(object).forEach(([key, value]) => {
      switch (key) {
        case "className":
          const deepMergedClassName = deepMerged["className"];
          deepMerged["className"] =
            `${deepMergedClassName ? `${deepMerged["className"]} ` : ""}${value}`;
          break;
        case "style":
          deepMerged["style"] = { ...(deepMerged["style"] ?? {}), ...value };
          break;
        default:
          deepMerged[key] = value;
      }
    });

    return deepMerged;
  });
