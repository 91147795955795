"use client";

import { type CloudfrontCookie, signCFUrl } from "@packages/sdk";
import type HLS from "hls.js";
import type { Events } from "hls.js";
import type { MutableRefObject } from "react";
import { forwardRef, useCallback, useEffect, useRef } from "react";

import { useLocalRef } from "../../../../hooks";
import type { HallowElementProps } from "../../../../types";

declare global {
  interface Window {
    Hls: typeof HLS;
  }
}

export type AudioProps = HallowElementProps<"audio"> & {
  hlsUrl: string | null;
  policyCookie: CloudfrontCookie["CloudFront-Policy"];
  keyPairIdCookie: CloudfrontCookie["CloudFront-Key-Pair-Id"];
  signatureCookie: CloudfrontCookie["CloudFront-Signature"];
  regularUrl: string | null;
  type: "background" | "primary";
};

const hlsHasLoaded = () => typeof window !== "undefined" && !!window.Hls;

export const Audio = forwardRef<HTMLAudioElement, AudioProps>(
  (
    {
      children,
      type,
      hlsUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
      regularUrl,
      ...props
    },
    ref,
  ) => {
    const hls = useRef<HLS | null>(hlsHasLoaded() ? new window.Hls() : null);
    const mediaRef = useLocalRef<HTMLAudioElement>(ref);

    useEffect(() => {
      loadMedia();
    }, [hlsUrl, policyCookie, keyPairIdCookie, signatureCookie, regularUrl]);

    const loadMedia = useCallback(() => {
      if (
        (mediaRef as MutableRefObject<HTMLAudioElement>).current &&
        (hlsUrl || regularUrl)
      ) {
        const myHls = hlsUrl
          ? signCFUrl(hlsUrl, [
              {
                "CloudFront-Policy": policyCookie,
                "CloudFront-Key-Pair-Id": keyPairIdCookie,
                "CloudFront-Signature": signatureCookie,
              },
            ])
          : "";
        if (
          (myHls &&
            myHls ===
              (mediaRef as MutableRefObject<HTMLAudioElement>).current.src) ||
          (regularUrl &&
            regularUrl ===
              (mediaRef as MutableRefObject<HTMLAudioElement>).current.src)
        )
          return;

        if (hls.current) {
          hls.current.on<Events.ERROR>("hlsError" as Events.ERROR, () => {
            hls.current.detachMedia();
            (
              mediaRef as MutableRefObject<HTMLAudioElement>
            ).current.setAttribute("src", regularUrl);
            (mediaRef as MutableRefObject<HTMLAudioElement>).current.load();
          });
        }

        const currentPlaybackRate = (
          mediaRef as MutableRefObject<HTMLAudioElement>
        ).current.playbackRate;
        if (hlsUrl && window.Hls?.isSupported()) {
          hls.current?.loadSource(myHls);
          hls.current?.attachMedia(
            (mediaRef as MutableRefObject<HTMLAudioElement>).current,
          );
          hls.current?.startLoad();
        } else if (
          hlsUrl &&
          (mediaRef as MutableRefObject<HTMLAudioElement>).current.canPlayType(
            "application/x-mpegURL",
          )
        ) {
          // native HLS support
          (mediaRef as MutableRefObject<HTMLAudioElement>).current.setAttribute(
            "src",
            myHls,
          );
          (mediaRef as MutableRefObject<HTMLAudioElement>).current.load();
        } else if (regularUrl) {
          // no HLS support
          hls.current?.detachMedia();
          (mediaRef as MutableRefObject<HTMLAudioElement>).current.setAttribute(
            "src",
            regularUrl,
          );
          (mediaRef as MutableRefObject<HTMLAudioElement>).current.load();
        } else {
          hls.current?.detachMedia();
          (
            mediaRef as MutableRefObject<HTMLAudioElement>
          ).current.removeAttribute("src");
        }
        (mediaRef as MutableRefObject<HTMLAudioElement>).current.playbackRate =
          currentPlaybackRate;
      }
    }, [
      hlsUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
      regularUrl,
      (mediaRef as MutableRefObject<HTMLAudioElement>)?.current?.src,
    ]);

    return (
      <audio
        key={`hallowAudioElement${type.toUpperCase()}`}
        ref={mediaRef}
        {...props}
      >
        {children}
      </audio>
    );
  },
);

Audio.displayName = "Audio";
