"use client";

import { type ReactNode, useEffect, useState } from "react";

import { useIsDesktopViewport } from "../../../../hooks";
import type { DropdownMenuProps } from "../DropdownMenu";
import { DropdownMenu } from "../DropdownMenu";
import type { FullscreenModalProps } from "../FullscreenModal";
import { FullscreenModal } from "../FullscreenModal";

export type FullscreenModalDropdownMenuProps = Pick<
  FullscreenModalProps,
  "defaultOpen" | "onOpenChange" | "open"
> & {
  children?: ReactNode;
  dropdownMenuProps?: DropdownMenuProps;
  fullscreenModalProps?: FullscreenModalProps;
  trigger?: ReactNode;
};

export const FullscreenModalDropdownMenu = ({
  children,
  defaultOpen = false,
  dropdownMenuProps = {},
  fullscreenModalProps = {},
  onOpenChange,
  open = false,
  trigger,
}: FullscreenModalDropdownMenuProps) => {
  const isDesktopViewport = useIsDesktopViewport();
  const [internalState, setInternalState] = useState(open);

  useEffect(() => {
    setInternalState(open);
  }, [open]);

  if (isDesktopViewport)
    return (
      <DropdownMenu
        {...dropdownMenuProps}
        defaultOpen={defaultOpen}
        open={internalState}
        onOpenChange={
          onOpenChange ? onOpenChange : () => setInternalState(!internalState)
        }
        trigger={dropdownMenuProps.trigger || trigger}
      >
        {dropdownMenuProps.children || children}
      </DropdownMenu>
    );

  return (
    <FullscreenModal
      {...fullscreenModalProps}
      defaultOpen={defaultOpen}
      open={internalState}
      onOpenChange={
        onOpenChange ? onOpenChange : () => setInternalState(!internalState)
      }
      trigger={fullscreenModalProps.trigger || trigger}
    >
      {fullscreenModalProps.children || children}
    </FullscreenModal>
  );
};
