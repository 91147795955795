import { deviceTypes } from "../lib/types/enums/deviceTypes";

export const deviceDetectToDeviceType = (ddType: string): deviceTypes => {
  if (ddType === "browser") {
    return deviceTypes.DESKTOP;
  } else if (ddType === "mobile") {
    return deviceTypes.PHONE;
  }
  return ddType as deviceTypes;
};
