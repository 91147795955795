import type { ReadonlyURLSearchParams } from "next/navigation";

export const checkRedirectQueries = (
  redirect: string,
  queries: ReadonlyURLSearchParams,
  redirectQueries: string[],
) => {
  const redirectWithoutPortalHash = redirect.replace("/#/", "/");
  const url = new URL(redirectWithoutPortalHash);

  for (const query of redirectQueries) {
    if (url.searchParams.has(query) && !queries.get(query)) {
      sessionStorage.setItem("query", url.searchParams.get(query));
    }
  }
};
