"use client";

import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  colors,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { type WithStylexArray } from "../../../../types";
import { determineChildrenInject } from "../../../../utils";
import type { ButtonProps } from "../../_core";
import { Button } from "../../_core";
import { CheckmarkIcon } from "../../icons";
import type { DropdownMenuItemProps } from "../DropdownMenuItem";

const IS_DESKTOP = "@media (min-width: 768px)";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: colors.transparent,
      ":hover": {
        [IS_DESKTOP]: semanticColors.onOverlayLow,
        default: colors.transparent,
      },
      ":active": semanticColors.onOverlayLow,
    },
    borderRadius: radius.m,
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":active": semanticColors.neutralsMedHigh,
    },
    cursor: "pointer",
    display: "inline-flex",
    height: `max(${numericPixels[56]}, min-content)`,
    justifyContent: "space-between",
    maxWidth: spacing.full,
    outline: "none",
    overflow: "hidden",
    padding: spacing.m,
    textAlign: "initial",
    whiteSpace: "revert",
    width: numericPercentages[100],
  },
  selected: {
    backgroundColor: semanticColors.neutralsLowest,
  },
});

export type ModalItemProps = WithStylexArray<
  Omit<ButtonProps, "size" | "color" | "variant">
> & {
  selected?: boolean;
  textValue?: DropdownMenuItemProps["textValue"];
  hideCheckOnSelected?: boolean;
};

export const ModalItem = forwardRef<HTMLButtonElement, ModalItemProps>(
  (
    {
      className,
      children,
      onClick,
      selected = false,
      styleXArray = [],
      onSelect,
      disabled,
      textValue,
      hideCheckOnSelected,
      ...props
    },
    ref,
  ) => {
    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        selected && !hideCheckOnSelected ? <CheckmarkIcon.SimpleOn /> : null,
      ],
      beforeChildrenInject: null,
      children: textValue ? <>{textValue}</> : (children as JSX.Element),
    });

    return (
      <Button
        ref={ref}
        onClick={(e) => {
          if (onSelect) {
            onSelect(e);
          }
          // This onClick is passed on from DialogClose and is used to close the modal when an option is selected
          if (onClick) {
            onClick(e);
          }
        }}
        disabled={disabled}
        variant={"transparentPrimary"}
        styleXArray={[
          styles.base,
          selected ? styles.selected : null,
          styleXArray,
        ]}
        {...props}
      >
        {DeterminedChildren}
      </Button>
    );
  },
);

ModalItem.displayName = "ModalItem";
