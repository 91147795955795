"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import type { WithStylexArray } from "../../../../types";
import { type HallowElement, type HallowElementProps } from "../../../../types";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaQueryMinWidthTablet = "@media (min-width: 768px)";

const Element: HallowElement = "svg";

const styles = stylex.create({
  svg: {
    boxSizing: "content-box",
    height: {
      [cssMediaQueryMinWidthTablet]: 24,
      default: 16,
    },
    width: {
      [cssMediaQueryMinWidthTablet]: 102,
      default: 68,
    },
  },
});

export type HallowLogoTextProps = WithStylexArray<
  Omit<HallowElementProps<typeof Element>, "type">
>;

export const HallowLogoText = forwardRef<any, HallowLogoTextProps>(
  ({ fill = "currentColor", styleXArray = [], ...props }, ref) => (
    <svg
      ref={ref}
      viewBox="0 0 102 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...stylex.props(styles.svg, styleXArray)}
    >
      <g>
        <path
          d="M96.3933 6.22949L93.6392 16.7213H93.4753L90.7868 6.22949H86.0655L83.4753 16.7213H83.3114L80.5573 6.22949H75.377L80.3606 23.6065H85.7376L88.3933 13.082H88.5573L91.213 23.6065H96.5901L101.574 6.22949H96.3933Z"
          fill={fill}
        />
        <path
          d="M14.2295 9.67215H5.34426V0.655762H0V23.6066H5.34426V14.3279H14.2295V23.6066H19.4754V0.655762H14.2295V9.67215Z"
          fill={fill}
        />
        <path
          d="M66.0329 5.77051C60.9181 5.77051 56.8525 9.83608 56.8525 14.8525C56.8525 19.8689 60.9181 24 66.0329 24C71.1476 24 75.1804 19.9344 75.1804 14.8525C75.1804 9.77051 71.0165 5.77051 66.0329 5.77051Z"
          fill={fill}
        />
        <path
          d="M54.0984 18.7869V0H49.082V19.2787C49.082 22.2295 50.6886 23.9016 53.6394 23.9016C54.3279 23.9016 55.0165 23.8033 55.6394 23.6066V19.8361H55.246C54.4919 19.8361 54.0984 19.4426 54.0984 18.7869Z"
          fill={fill}
        />
        <path
          d="M37.5084 18.7213V12.8525C37.5084 8.49182 34.656 5.77051 30.3281 5.77051C25.7051 5.77051 22.492 8.32788 22.2953 11.7377H27.6068C27.7707 10.6558 28.6232 9.90166 29.9674 9.90166C31.3117 9.90166 32.2297 10.8525 32.2297 12.2623V13.0164C31.574 12.9508 30.8855 12.8525 30.2297 12.8525C24.8527 12.8525 21.9019 15.5082 21.9019 18.9836C21.9019 22 24.0986 24 27.3117 24C29.574 24 31.6068 23.0492 32.6232 21.2459H32.7871C33.115 22.9836 34.5576 23.9017 37.115 23.9017C37.8035 23.9017 38.4592 23.8033 39.115 23.6066V19.8361H38.6232C37.8691 19.8361 37.5084 19.4754 37.5084 18.7213ZM32.2297 16.2623C32.2297 18.3279 30.8855 19.7377 29.115 19.7377C28.1314 19.7377 27.5084 19.082 27.5084 18.2295C27.5084 17.0164 28.5248 16.1639 30.3937 16.1639H32.2297V16.2623Z"
          fill={fill}
        />
        <path
          d="M45.8035 18.7869V0H40.7871V19.4754C40.7871 22.4262 42.3937 23.9016 45.3445 23.9016C46.033 23.9016 46.7215 23.8033 47.3445 23.6066V19.8361H46.951C46.1969 19.8361 45.8035 19.4426 45.8035 18.7869Z"
          fill={fill}
        />
        <path
          d="M61.2076 4.41998C62.5312 4.50161 64.2739 4.48096 66.1027 4.32096C67.9319 4.16096 69.6516 3.87899 70.9408 3.5685C72.7768 3.11178 73.167 2.6826 73.1299 2.25801C73.1099 2.02948 72.9621 1.84489 72.7221 1.73407C72.3785 1.56653 71.7732 1.42227 70.7463 1.34752C69.4227 1.26588 67.6801 1.28653 65.8512 1.44653C64.0221 1.60653 62.3024 1.8885 61.0132 2.19899C60.0149 2.4508 59.4444 2.69834 59.1349 2.92293C58.9178 3.07342 58.804 3.28096 58.824 3.50948C58.8611 3.93407 59.3201 4.28883 61.2076 4.41998ZM65.9201 2.23047C69.4149 1.92457 71.5693 2.09834 72.2483 2.33506C71.2106 2.88653 68.6473 3.3085 66.0342 3.53702C63.4211 3.76555 60.8237 3.79539 59.706 3.43243C60.3335 3.08129 62.425 2.53637 65.9201 2.23047Z"
          fill={fill}
        />
      </g>
    </svg>
  ),
);

HallowLogoText.displayName = "HallowLogoText";
