"use client";

import { forwardRef, useState } from "react";

import type { TextProps } from "../../../_base";
import { Text } from "../../../_base";

export type ExpandableTextProps = TextProps & {};

export const ExpandableText = forwardRef<any, ExpandableTextProps>(
  ({ lineClamp, overflow, ...props }, ref) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleOnClick = (e) => setExpanded(!expanded);

    return (
      <Text
        lineClamp={expanded ? null : lineClamp}
        onClick={(e) => handleOnClick(e)}
        overflow={expanded ? null : overflow}
        ref={ref}
        {...props}
      />
    );
  },
);

ExpandableText.displayName = "ExpandableText";
