"use client";

import * as stylex from "@stylexjs/stylex";
import { useId } from "react";

import { stylesScreenReader } from "../../../../../global/stylex/styles";
import {
  colors,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithStylexArray,
} from "../../../../types";

const hallowElement: HallowElement = "input";

const styles = stylex.create({
  label: {
    borderColor: colors.transparent, // prevents element shifting on :checked
    borderStyle: "solid",
    borderWidth: stroke.regular,
    cursor: "pointer",
    height: "fit-content",
    lineHeight: 0,
    margin: "0 auto",
    outline: "none",
    padding: spacing.t,
    width: "fit-content",
    ":hover": {
      borderColor: semanticColors.neutralsVeryLow,
    },
  },
  radioImageLabelFocus: {
    borderColor: semanticColors.accentMedium,
    ":hover": {
      borderColor: semanticColors.accentMedium,
    },
  },
});

export type RadioImageInputProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
> & {
  selected?: boolean;
  label: string;
  onClick?: Function;
  register: any;
  value: string;
};

export const RadioImageInput = ({
  children,
  selected,
  label,
  onClick = null,
  register,
  styleXArray = [],
  value,
  ...props
}) => {
  const id = useId();
  return (
    <>
      <input
        id={id}
        type="radio"
        value={value}
        onClick={(e) => {
          if (onClick)
            onClick(
              typeof value === "string"
                ? e.currentTarget.value
                : e.currentTarget.valueAsNumber,
            );
        }}
        {...register(label)}
        {...props}
        {...stylex.props(stylesScreenReader.only)}
      />
      <label
        htmlFor={id}
        {...stylex.props(
          styles.label,
          styleXArray,
          selected && styles.radioImageLabelFocus,
        )}
      >
        {children}
      </label>
    </>
  );
};
