import * as RadixUISlot from "@radix-ui/react-slot";

import type { AsChild, HallowElement, HallowElementProps } from "../types";

export type DetermineElementFromAsChildProps<
  HallowElementType extends HallowElement,
> = {
  asChild: AsChild;
  hallowElement: HallowElementType;
};

export const determineElementFromAsChild = <
  HallowElementType extends HallowElement,
>({
  asChild,
  hallowElement,
}: DetermineElementFromAsChildProps<HallowElementType>) =>
  (asChild ? RadixUISlot.Slot : hallowElement) as (
    props: HallowElementProps<HallowElementType>,
  ) => any | HallowElement;
