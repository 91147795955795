import { useCurrentQueueItem, usePlayer } from "@packages/media";
import { redirectToSubscribe, useRequestMe } from "@packages/sdk";

import type {
  DropdownMenuItemProps,
  ModalItemProps,
} from "../../../../../components";
import {
  ModalDropdownMenuItem,
  SubscriptionIcon,
} from "../../../../../components";

export const SelectLengthContent = () => {
  const { currentItem } = useCurrentQueueItem();
  const { query: me } = useRequestMe();
  const player = usePlayer();
  const lengths = currentItem.prayer.tracks.filter(
    (t) => t.guide_id === currentItem.selected_audio.guide_id,
  );

  return (
    <>
      {lengths.map((option) => {
        const hasAccess =
          (option.paid && me?.data?.subscription) || !option.paid;
        const iconProps: DropdownMenuItemProps | ModalItemProps = !hasAccess
          ? { startIcon: <SubscriptionIcon /> }
          : {};
        return (
          <ModalDropdownMenuItem
            key={`mediaMenu_selectLength_${option.id}`}
            selected={option.id === currentItem.selected_audio.id}
            onSelect={() =>
              hasAccess ? player?.switchTo(option) : redirectToSubscribe(true)
            }
            dropdownMenuItemProps={iconProps as DropdownMenuItemProps}
            modalItemProps={iconProps as ModalItemProps}
          >
            {option.length}
          </ModalDropdownMenuItem>
        );
      })}
    </>
  );
};
