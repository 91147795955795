import * as stylex from "@stylexjs/stylex";

import { colors, semanticColors, stroke } from "../vars.stylex";

export const stylesOutline = stylex.create({
  base: {
    outlineColor: colors.transparent,
    outlineStyle: "solid",
    outlineWidth: stroke.none,
    transition: "all 150ms ease-out",
  },
  focus: {
    outlineColor: {
      default: colors.transparent,
      ":focus": semanticColors.primary,
    },
    outlineWidth: {
      default: stroke.none,
      ":hover": stroke.heavy,
    },
  },
  focusControlled: {
    outlineColor: semanticColors.primary,
    outlineWidth: stroke.heavy,
  },
  hover: {
    outlineColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLowest,
    },
    outlineWidth: {
      default: stroke.none,
      ":hover": stroke.heavy,
    },
  },
  hoverControlled: {
    outlineColor: semanticColors.neutralsLowest,
    outlineWidth: stroke.heavy,
  },
});
