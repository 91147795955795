"use client";

import { useEffect, useRef } from "react";

import { END_OF_BROWSER_ASYNC_QUEUE } from "../lib";

export type UseScrollToOnLoadOptions = ScrollIntoViewOptions;

export const useScrollToOnLoad = <T extends HTMLElement>(
  options: UseScrollToOnLoadOptions = {},
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(options);
    } else {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView(options);
        }
      }, END_OF_BROWSER_ASYNC_QUEUE);
    }
  }, []);

  return ref;
};
