import stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { HallowElementProps, WithStylexArray } from "../../../../types";
import { Text } from "../../../_base/_core/Text";

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingHorizontal: spacing.xxl,
    paddingVertical: spacing.m,
    width: spacing.full,
  },
  paddingActionRight: {
    paddingRight: spacing.m,
  },
  paddingActionLeft: {
    paddingLeft: spacing.m,
  },
});

export type PanelHeaderProps = WithStylexArray<HallowElementProps<"div">> & {
  title?: string;
  actionRight?: React.ReactNode;
  actionLeft?: React.ReactNode;
};

export const PanelHeader = ({
  title,
  actionRight,
  actionLeft,
  styleXArray,
  ...props
}: PanelHeaderProps) => {
  return (
    <div
      {...stylex.props(
        styles.container,
        actionRight && styles.paddingActionRight,
        actionLeft && styles.paddingActionLeft,
        styleXArray,
      )}
      {...props}
    >
      {actionLeft ? actionLeft : <span />}
      {title && (
        <Text type="title" size="m" as="h1">
          {title}
        </Text>
      )}
      {actionRight ? actionRight : <span />}
    </div>
  );
};
