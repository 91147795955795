"use client";

import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Button,
  ModalDropdownMenuContext,
  ModalDropdownMenuHeader,
  ModalTabContent,
  StopCountdown,
} from "../../../../../components";
import { Time } from "../../../primitives";

const styles = stylex.create({
  button: {
    background: "none",
    border: "none",
    borderRadius: radius.full,
    height: "min-content",
    lineHeight: numericValues[0],
    marginBottom: spacing.m,
    padding: numericValues[0],
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    rowGap: spacing.m,
    width: numericPercentages[100],
  },
  time: {
    color: semanticColors.onOverlayMedHigh,
  },
});

export type SleepTimerTabContentProps = {
  countdownSeconds: number;
  value: string;
};

export const SleepTimerTabContent = ({
  countdownSeconds,
  value,
}: SleepTimerTabContentProps) => {
  const t = useTranslations();
  const [time, setTime] = useState<number>(countdownSeconds);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const player = usePlayer();
  const menu = useContext(ModalDropdownMenuContext);

  const updateTime = useCallback(() => {
    if (player?.sleepTimerTarget && player.sleepTimerTarget > Date.now()) {
      const remaining = Math.ceil(
        (player.sleepTimerTarget - Date.now()) / 1000,
      );
      setTime(remaining);
    } else {
      setTime(0);
      player?.cancelSleepTimer();
    }
  }, [player]);

  useEffect(() => {
    updateTime();
    timer.current = setInterval(updateTime, 1000);

    return () => {
      clearInterval(timer.current ?? undefined);
    };
  }, [updateTime]);

  return (
    <ModalTabContent
      value={value}
      header={
        <ModalDropdownMenuHeader>
          {t("track_settings_timer")}
        </ModalDropdownMenuHeader>
      }
    >
      <div {...stylex.props(styles.container)}>
        <Time timeS={time} size={"xl"} styleXArray={[styles.time]} />
        <Button
          onClick={() => {
            player?.cancelSleepTimer();
            menu.close();
          }}
          styleXArray={[styles.button]}
        >
          <StopCountdown
            total={countdownSeconds}
            remaining={time}
            stroke={semanticColors.onOverlayMedHigh}
          />
        </Button>
      </div>
    </ModalTabContent>
  );
};
