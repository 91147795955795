import { useTranslations } from "@packages/i18n";
import { useAddToQueue } from "@packages/media";
import type { AppQueueContentType } from "@packages/sdk";

import { DropdownMenuItem, PlayLastIcon, PlayNextIcon } from "../../../_base";

export type AddToQueueProps = {
  type: AppQueueContentType;
  id: number;
};

export const AddToQueue = ({ type, id }: AddToQueueProps) => {
  const { handleClickNext, handleClickLast, stringKeyNext, stringKeyLast } =
    useAddToQueue({ type, id });
  const t = useTranslations();

  return (
    <>
      <DropdownMenuItem onClick={handleClickNext} startIcon={<PlayNextIcon />}>
        {t(stringKeyNext)}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={handleClickLast} startIcon={<PlayLastIcon />}>
        {t(stringKeyLast)}
      </DropdownMenuItem>
    </>
  );
};
