"use client";

import * as RadixUIAvatar from "@radix-ui/react-avatar";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import {
  numericPercentages,
  radius,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import type { CSSColorStrict } from "../../../../utils";
import { Skeleton } from "../Skeleton";

const styles = stylex.create({
  base: (props: { color: CSSColorStrict }) => ({
    backgroundColor: props.color,
    display: "flex",
  }),
  imageContainer: {
    height: spacing.full,
    width: spacing.full,
  },
  skeleton: {
    borderRadius: radius.full,
  },
});

const stylesImage = stylex.create({
  base: {
    borderRadius: radius.full,
    display: "block",
    height: numericPercentages[100],
    objectFit: "cover",
    width: numericPercentages[100],
  },
});

const stylesSize = stylex.create({
  xs: {
    borderRadius: 6,
    height: spacing.l,
    padding: spacing.t,
    width: spacing.l,
  },
  m: {
    borderRadius: radius.ms,
    height: spacing.xxxl,
    padding: spacing.xs,
    width: spacing.xxxl,
  },
  xl: {
    borderRadius: "20px",
    height: "116px",
    padding: spacing.ms,
    width: "116px",
  },
});

export type AvatarCommunityProps = WithStylexArray<
  ComponentProps<typeof RadixUIAvatar.Root>
> & {
  color?: CSSColorStrict;
  imgSrc?: string;
  name?: string;
  size?: "xs" | "m" | "xl";
};

export const AvatarCommunity = ({
  color,
  imgSrc = "",
  name = "",
  size = "m",
  styleXArray = [],
  ...props
}: AvatarCommunityProps) => (
  <RadixUIAvatar.Root
    {...stylex.props(styles.base({ color }), stylesSize[size], styleXArray)}
    {...props}
  >
    <div {...stylex.props(styles.imageContainer)}>
      {imgSrc && (
        <RadixUIAvatar.Image
          alt={name}
          src={imgSrc}
          {...stylex.props(stylesImage.base)}
        />
      )}
      <RadixUIAvatar.Fallback asChild>
        <Skeleton stylexArray={[styles.skeleton]} />
      </RadixUIAvatar.Fallback>
    </div>
  </RadixUIAvatar.Root>
);
