"use client";

import { Provider, Viewport } from "@radix-ui/react-toast";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";
import { useMemo } from "react";

import {
  numericPercentages,
  numericPixels,
  spacing,
  zIndices,
} from "../../../global/stylex/vars.stylex";

export type ToastProviderProps = ComponentProps<typeof Provider> & {
  label: string;
  hasPlayer: boolean;
  hasTabBar: boolean;
};

const styles = stylex.create({
  viewport: {
    bottom: spacing.m,
    height: "auto",
    left: numericPercentages[50],
    maxWidth: "400px",
    position: "fixed",
    transform: `translateX(-50%)`,
    transition: "bottom 250ms ease-out",
    width: `calc(100vh - ${spacing.m})`,
    zIndex: zIndices.toast,
  },
  withTabBar: {
    bottom: `calc(${spacing.xxl} + ${spacing.m})`,
  },
  withPlayer: {
    bottom: `calc(${numericPixels[64]} + ${spacing.m})`,
  },
  withBoth: {
    bottom: `calc(${spacing.xxl} + ${numericPixels[64]} + ${spacing.m})`,
  },
});

export const ToastProvider = ({
  children,
  label,
  hasPlayer,
  hasTabBar,
}: ToastProviderProps) => {
  const styleXArray = useMemo(() => {
    const styleXArray: stylex.StyleXStyles[] = [styles.viewport];
    if (hasPlayer && hasTabBar) styleXArray.push(styles.withBoth);
    else if (hasPlayer) styleXArray.push(styles.withPlayer);
    else if (hasTabBar) styleXArray.push(styles.withTabBar);

    return styleXArray;
  }, [hasPlayer, hasTabBar]);

  return (
    <Provider label={label}>
      {children}

      <Viewport label={label} asChild>
        <ol {...stylex.props(styleXArray)} />
      </Viewport>
    </Provider>
  );
};
