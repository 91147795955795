"use client";

import { useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import { useRequestPrayer, useRequestTranscript } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import {
  type MouseEventHandler,
  type UIEvent,
  useCallback,
  useRef,
  useState,
} from "react";

import { themes } from "../../../../../global/stylex/themes.stylex";
import {
  fontSizes,
  numericPercentages,
  numericPixels,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import {
  FullscreenModal,
  IconButton,
  ScrollArea,
  Text,
  TextOnScreenIcon,
} from "../../../../components";
import { stylexDynamics } from "../../../../lib";
import { useTheme } from "../../../../theme";
import type { WithButtonWrapper, WithStylexArray } from "../../../../types";
import { getRGBAFromColor } from "../../../../utils";
import { ReaderThumbnail, TextSettings } from "../../blocks";

const ON_DESKTOP = "@media screen and (min-width: 768px)";

const styles = stylex.create({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    placeContent: "flex-start",
    placeItems: "center",
    position: "relative",
  },
  body: {
    fontFamily: "Hallow Moderat Serif, serif",
    lineHeight: 2,
    marginTop: {
      [ON_DESKTOP]: "280px",
      default: "210px",
    },
    maxWidth: "640px",
    padding: `${spacing.l} ${spacing.l} 120px`,
    transitionDuration: "250ms",
    transitionProperty: "font-size, line-height",
    transitionTimingFunction: "ease-out",
    whiteSpace: "pre-line",
  },
  modal: {
    color: semanticColors.primary,
    padding: 0,
    zIndex: zIndices.transcript,
  },
  fontS: {
    fontSize: fontSizes.tosS,
  },
  fontM: {
    fontSize: fontSizes.tosM,
  },
  fontL: {
    fontSize: fontSizes.tosL,
  },
});

const headerStyles = stylex.create({
  default: {
    background: semanticColors.background,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    padding: {
      [ON_DESKTOP]: "144px 0 0",
      default: "76px 0 0",
    },
    placeContent: "flex-start",
    placeItems: "center",
    pointerEvents: "none",
    position: "fixed",
    transition: "top 250ms ease-out",
    width: numericPercentages[100],
  },
  stage0: {
    top: 0,
  },
  stage1: {
    top: {
      [ON_DESKTOP]: "-84px",
      default: "-80px",
    },
  },
  stage2: {
    top: {
      [ON_DESKTOP]: "-228px",
      default: "-156px",
    },
  },
});

const maskStyles = stylex.create({
  default: {
    position: "absolute",
    transition: "top 250ms ease-out",
  },
  stage0: {
    top: {
      [ON_DESKTOP]: "258px",
      default: "192px",
    },
  },
  stage1: {
    top: {
      [ON_DESKTOP]: "174px",
      default: "112px",
    },
  },
  stage2: {
    top: {
      [ON_DESKTOP]: "32px",
      default: "36px",
    },
  },
});

const headingStyles = stylex.create({
  default: {
    marginTop: spacing.m,
  },
});

// we put a simple z-index here because the layer context of this button is INSIDE the fullscreen-modal,
// so we only need it on top of its parent
const closeButtonStyles = stylex.create({
  default: {
    left: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    opacity: 1,
    pointerEvents: "auto",
    position: "fixed",
    top: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    transition: "opacity 250ms ease-out",
    zIndex: 1,
  },
  stage0: {},
  stage1: {},
  stage2: {
    opacity: 0,
    pointerEvents: "none",
  },
});

const thumbnailContainerStyles = stylex.create({
  default: {
    display: "flex",
    height: numericPixels[80],
    placeContent: "center",
    placeItems: "end",
    width: numericPixels[80],
  },
});

export type TextOnScreenProps = WithStylexArray<
  WithButtonWrapper<{
    prayerId?: number;
    iconColor?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    open: boolean;
    onOpenChange: (open: boolean) => void;
  }>
>;

export const TextOnScreen = ({
  prayerId,
  iconColor,
  Wrapper = ({ children }) => <>{children}</>,
  styleXArray,
  onClick,
  open,
  onOpenChange,
}: TextOnScreenProps) => {
  const {
    query: { data: prayerData },
  } = useRequestPrayer({ id: prayerId });
  const id = prayerData?.tracks?.[0]?.id ?? null;
  const {
    query: { data: transcriptData },
  } = useRequestTranscript({ id });
  const analytics = useAnalytics();
  const t = useTranslations();

  const [immersive, setImmersive] = useState<"immersive" | "default">(
    "default",
  );
  const [scrollStage, setScrollStage] = useState<0 | 1 | 2>(0);
  const lastScrollTop = useRef<number>(0);
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();
  const [fontSize, setFontSize] = useState<"s" | "m" | "l">("m");
  const { theme } = useTheme();

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      // why the typecast? Because UIEvent<T> adds `currentTarget` to the event type as T, but scroll events don't
      // have a currentTarget property
      const scrollTop = (e.target as HTMLDivElement).scrollTop ?? 0;
      if (scrollTop < lastScrollTop.current) {
        if (scrollStage > 0) {
          setScrollStage(0);
        }
      } else if (scrollTop > 400) {
        if (scrollStage < 2) {
          setScrollStage(2);
        }
      } else if (scrollTop > 75) {
        if (scrollStage !== 1) {
          setScrollStage(1);
        }
      } else {
        setScrollStage(0);
      }
      lastScrollTop.current = scrollTop;
    },
    [scrollStage],
  );

  if (!prayerData) return null;
  if (!transcriptData) return null;

  const { title, images } = prayerData;
  const { transcript } = transcriptData;

  const thumbnail = (
    <div {...stylex.props(thumbnailContainerStyles.default)}>
      <ReaderThumbnail
        activated={currentItem?.prayer?.id === prayerId}
        imageSrc={images}
        playing={
          currentItem?.prayer?.id === prayerId && !!player && !player.paused
        }
        scrollStage={scrollStage}
        title={title}
        onClick={() => player?.playNew({ type: "prayer", id: prayerId })}
      />
    </div>
  );

  const body = (
    <article
      {...stylex.props(styles.body, styles[`font${fontSize.toUpperCase()}`])}
    >
      {transcript ?? t("no_transcript_available_text")}
    </article>
  );

  const headingTitle = (
    <Text
      as={"h1"}
      type={"title"}
      size={"m"}
      styleXArray={[headingStyles.default]}
    >
      {title}
    </Text>
  );

  const header = (
    <header
      {...stylex.props(
        headerStyles.default,
        immersive === "immersive"
          ? stylexDynamics.backgroundColorFromRGBAObject(
              getRGBAFromColor({
                color: images.color_hex ?? "rgba(0, 0, 0, 1)",
                format: "object",
              }),
            )
          : null,
        headerStyles[`stage${scrollStage}`],
      )}
    >
      {thumbnail}
      {headingTitle}
      <TextSettings
        fontSize={fontSize}
        setFontSize={setFontSize}
        immersive={immersive}
        setImmersive={setImmersive}
        scrollStage={scrollStage}
      />
    </header>
  );

  const content = (
    <div {...stylex.props(styles.container)}>
      {header}
      {body}
    </div>
  );

  if (!transcript?.length)
    return (
      <Wrapper asChild>
        <IconButton
          variant={"transparentWhite"}
          icon={<TextOnScreenIcon fill={iconColor ? iconColor : null} />}
          disabled={true}
          title={t("no_transcript_available_text")}
        />
      </Wrapper>
    );

  return (
    <FullscreenModal
      trigger={
        <Wrapper asChild>
          <IconButton
            variant={"transparentWhite"}
            icon={<TextOnScreenIcon fill={iconColor ? iconColor : null} />}
            onClick={onClick}
            onClickCapture={() =>
              analytics.track({
                event: "Tapped Text on Screen",
                properties: {
                  prayer: currentItem.prayer.id,
                  has_transcript: !!transcriptData?.transcript,
                },
              })
            }
            title={t("text_on_screen_read_along")}
            tabIndex={0}
          />
        </Wrapper>
      }
      open={open}
      onOpenChange={onOpenChange}
      modal={true}
      contentProps={{
        onInteractOutside: (e) => e.preventDefault(),
      }}
      closeStyleXArray={[
        closeButtonStyles.default,
        closeButtonStyles[`stage${scrollStage}`],
      ]}
      contentStyleXArray={[
        styles.modal,
        immersive === "immersive" ? themes.dark : themes[theme],
        immersive === "immersive"
          ? stylexDynamics.backgroundColorFromRGBAObject(
              getRGBAFromColor({
                color: images.color_hex ?? "#000000",
                format: "object",
              }),
            )
          : null,
        styleXArray,
      ]}
    >
      <ScrollArea
        viewportProps={{ onScroll: handleScroll }}
        maskBottom
        maskTop
        maskBottomProps={{ size: "xl" }}
        maskTopStyleXArray={[
          maskStyles.default,
          maskStyles[`stage${scrollStage}`],
        ]}
        masksColor={
          immersive === "immersive"
            ? ((images.color_hex ?? "#000000") as `#${string}`)
            : undefined
        }
      >
        {content}
      </ScrollArea>
    </FullscreenModal>
  );
};
