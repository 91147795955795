import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import { Text } from "../../_core";

const Element: HallowElement = "div";

export type FullscreenModalItemProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof Element>>
>;

export const FullscreenModalItem = forwardRef<any, FullscreenModalItemProps>(
  ({ asChild = false, children, styleXArray = [], ...props }, ref) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement: Element,
    });

    return (
      <Text asChild={asChild} ref={ref} size="l" type="headline">
        <DeterminedElement {...props} {...stylex.props(styleXArray)}>
          {children}
        </DeterminedElement>
      </Text>
    );
  },
);

FullscreenModalItem.displayName = "FullscreenModalItem";
