"use client";

import { useMedia } from "react-use";

import { colorsRaw, semanticColorsRaw } from "../../global/stylex/vars.stylex";
import { useTheme } from "../theme";
import { Theme } from "../types";
import type { CSSColorStrict } from "../utils";

export type GetStylexColorVarValueProps = {
  color:
    | keyof typeof colorsRaw
    | keyof typeof semanticColorsRaw
    | CSSColorStrict;
};

export const useStylex = () => {
  const isMatchDark = useMedia("(prefers-color-scheme: dark)", false);

  const { theme } = useTheme();

  const getStylexColorVarValue = ({ color }: GetStylexColorVarValueProps) => {
    if (Object.keys(colorsRaw).includes(color)) return colorsRaw[color];

    if (Object.keys(semanticColorsRaw).includes(color)) {
      if (theme === Theme.DARK || isMatchDark)
        return semanticColorsRaw[color]["@media (prefers-color-scheme: dark)"];
      else return semanticColorsRaw[color]["default"];
    }

    return color;
  };

  return { getStylexColorVarValue };
};
