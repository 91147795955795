"use client";

import type { ComponentProps } from "react";
import {
  Line,
  LineChart as LineChartRecharts,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from "recharts";

import { useStylex } from "../../../../../hooks";
import { Skeleton } from "../../../_core";
import { Dot } from "../Dot";
import { Tick } from "../Tick";
import { Tooltip } from "../Tooltip";

export type LineChartProps = ComponentProps<typeof LineChartRecharts> & {
  loading?: boolean;
};

export const LineChart = ({ data, loading, ...props }: LineChartProps) => {
  const { getStylexColorVarValue } = useStylex();

  if (loading) return <Skeleton />;

  return (
    <ResponsiveContainer>
      <LineChartRecharts data={data} {...props}>
        <XAxis
          dataKey="tick"
          interval={0}
          stroke={getStylexColorVarValue({ color: "neutralsLowest" })}
          strokeWidth={2}
          tick={<Tick dataLength={data?.length} />}
          tickLine={false}
        />
        <YAxis
          hide
          dataKey="value"
          domain={[
            (dataMin: number) => dataMin * 0.9,
            (dataMax: number) => dataMax * 1.1,
          ]}
        />
        <Line
          activeDot={false}
          dataKey="value"
          dot={false}
          stroke={getStylexColorVarValue({ color: "accentMedium" })}
          strokeWidth={2}
          type="linear"
        />
        <TooltipRecharts
          content={({ payload }) => (
            <Tooltip
              label={payload[0]?.payload?.label}
              value={payload[0]?.value as string}
            />
          )}
          cursor={{
            stroke: getStylexColorVarValue({ color: "neutralsLowest" }),
            strokeWidth: 2,
          }}
        />
        <Line
          activeDot={({ cx, cy }) => <Dot x={cx} y={cy} />}
          dataKey="value"
          dot={false}
          stroke="none"
        />
      </LineChartRecharts>
    </ResponsiveContainer>
  );
};

LineChart.displayName = "LineChart";
