"use client";

import {
  Close,
  Description,
  Title,
  type ToastProps,
} from "@radix-ui/react-toast";
import * as stylex from "@stylexjs/stylex";
import { type PropsWithChildren } from "react";

import {
  fontSizes,
  fontWeights,
  lineHeights,
  semanticColors,
  spacing,
} from "../../../../global/stylex/vars.stylex";
import type { IconElement } from "../../_base";
import { Button } from "../../_base";
import { Popup } from "../Popup";

const styles = stylex.create({
  title: {
    color: semanticColors.primary,
    fontSize: fontSizes.titleS,
    fontWeight: fontWeights.titleS,
    lineHeight: lineHeights.titleS,
  },
  description: {
    color: semanticColors.neutralsMedium,
    fontSize: fontSizes.detailL,
    fontWeight: fontWeights.detailL,
    lineHeight: lineHeights.detailL,
  },
  textArea: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexWrap: "nowrap",
    rowGap: spacing.xxs,
  },
});

export type SnackProps = PropsWithChildren<{
  title: string;
  buttonText: string;
  type?: ToastProps["type"];
  icon?: IconElement;
}>;

export const Snack = ({
  title,
  children,
  buttonText,
  icon,
  type = "foreground",
}: SnackProps) => {
  return (
    <Popup durationMs={5000} type={type} icon={icon}>
      <div {...stylex.props(styles.textArea)}>
        <Title {...stylex.props(styles.title)}>{title}</Title>
        <Description {...stylex.props(styles.description)}>
          {children}
        </Description>
      </div>
      <Button asChild>
        <Close>{buttonText}</Close>
      </Button>
    </Popup>
  );
};
