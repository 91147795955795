"use client";

import type { MediaElementsProps, MediaElementsRef } from "@packages/media";
import { MediaElementContext } from "@packages/media";
import { uuid } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Script from "next/script";
import {
  forwardRef,
  Fragment,
  type MutableRefObject,
  type SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { numericPercentages } from "../../../../../../global/stylex/vars.stylex";
import { HallowImage } from "../../../../../components";
import { Audio, Video } from "../../../primitives";

const styles = stylex.create({
  image: {
    height: numericPercentages[100],
    objectFit: "cover",
    objectPosition: "center center",
    width: numericPercentages[100],
  },
  video: {
    height: numericPercentages[100],
    objectFit: "contain",
    objectPosition: "center center",
    width: numericPercentages[100],
  },
});

export const MediaElements = forwardRef<MediaElementsRef, MediaElementsProps>(
  (
    {
      imgSrc,
      mediaSrc,
      mediaType,
      mediaTitle,
      bgAudioSrc,
      bgVolume = 0.25,
      onError,
      onBgError,
      shouldPlayBg,
    },
    outsideRef,
  ) => {
    const ref = useRef<HTMLMediaElement | null>(null);
    const background = useRef<HTMLAudioElement>(null);
    const bgPromise = useRef<Promise<void>>(Promise.resolve());
    const fgPromise = useRef<Promise<void>>(Promise.resolve());
    const player = useContext(MediaElementContext);
    const [resetUuid, setResetUuid] = useState<string>(uuid());
    const shouldPlayBgRef = useRef<boolean>(shouldPlayBg);

    useEffect(() => {
      shouldPlayBgRef.current = shouldPlayBg;
    }, [shouldPlayBg]);

    const hlsUrl = mediaSrc?.[0]?.url ?? null;
    const policyCookie =
      mediaSrc?.[0]?.cookies?.[0]?.["CloudFront-Policy"] ?? null;
    const keyPairIdCookie =
      mediaSrc?.[0]?.cookies?.[0]?.["CloudFront-Key-Pair-Id"] ?? null;
    const signatureCookie =
      mediaSrc?.[0]?.cookies?.[0]?.["CloudFront-Signature"] ?? null;
    const regularUrl = mediaSrc?.[1] ?? null;
    const bgHlsUrl = bgAudioSrc?.[0]?.url ?? null;
    const bgPolicyCookie =
      bgAudioSrc?.[0]?.cookies?.[0]?.["CloudFront-Policy"] ?? null;
    const bgKeyPairIdCookie =
      bgAudioSrc?.[0]?.cookies?.[0]?.["CloudFront-Key-Pair-Id"] ?? null;
    const bgSignatureCookie =
      bgAudioSrc?.[0]?.cookies?.[0]?.["CloudFront-Signature"] ?? null;
    const bgRegularUrl = bgAudioSrc?.[1] ?? null;

    /**
     * Allows us to programmatically change the currentTime on the <audio> or <video> element. Without this, we would
     * see the scrubber jump back and forth when controlled by the user, as the audio keeps playing in the background
     * and updating the time state (before the user's control changes it back visually). With this, we can trust a
     * change to be completed before the scrubber resumes updating based on playback.
     */
    const suspendUpdatesUntilTime = useRef<number>(-1);

    useEffect(() => {
      if (background.current) background.current.volume = bgVolume; // default background volume
    }, [background.current]);

    useImperativeHandle(outsideRef, () => ({
      set bgVolume(newVolume: number) {
        if (isFinite(newVolume) && background.current)
          background.current.volume = newVolume * this.volume;
        else throw `Non-finite background volume requested: ${newVolume}`;
      },
      set currentTime(newTime: number) {
        if (ref.current) {
          suspendUpdatesUntilTime.current = newTime;
          ref.current.currentTime = newTime;
        }
      },
      get currentTime() {
        return ref.current?.currentTime ?? 0;
      },
      get duration(): number {
        return ref.current?.duration ?? 0;
      },
      pause: () => {
        if (background.current?.src?.length) {
          bgPromise.current.finally(() => background.current?.pause());
        }
        fgPromise.current.finally(() => ref.current?.pause());
      },
      pauseBackground: () => {
        bgPromise.current.finally(() => background.current?.pause());
      },
      get paused(): boolean {
        return ref.current?.paused ?? true;
      },
      play: async () => {
        if (background.current?.src?.length && shouldPlayBgRef.current) {
          bgPromise.current.then(
            () =>
              (bgPromise.current =
                background.current?.play().catch(onError) ?? Promise.resolve()),
          );
        }
        await fgPromise.current;
        fgPromise.current =
          ref.current?.play().catch(onError) ?? Promise.resolve();
        return fgPromise.current;
      },
      playBackground: async () => {
        if (background.current?.src?.length && shouldPlayBgRef.current) {
          return bgPromise.current.then(
            () =>
              (bgPromise.current =
                background.current?.play().catch(onError) ?? Promise.resolve()),
          );
        }
        return Promise.resolve();
      },
      set playbackRate(newSpeed: number) {
        if (isFinite(newSpeed) && ref.current)
          ref.current.playbackRate = newSpeed;
        else throw `Non-finite playback rate requested: ${newSpeed}`;
      },
      get playbackRate(): number {
        return ref.current?.playbackRate ?? 1;
      },
      set volume(newVolume: number) {
        if (isFinite(newVolume) && ref.current) {
          ref.current.volume = newVolume;
          if (background.current)
            background.current.volume = bgVolume * newVolume;
        } else throw `Non-finite volume requested: ${newVolume}`;
      },
      get volume(): number {
        return ref.current?.volume ?? 1;
      },
      reset() {
        setTimeout(() => setResetUuid(uuid()), 10);
      },
    }));

    const handlePlay = useCallback(() => player?.updatePaused(false), [player]);
    const handlePause = useCallback(() => player?.updatePaused(true), [player]);
    const handleTimeUpdate = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) => {
        if (
          suspendUpdatesUntilTime.current > 0 &&
          Math.abs(
            suspendUpdatesUntilTime.current - e.currentTarget.currentTime,
          ) > 0.5
        )
          return;
        player?.updateTime(e.currentTarget.currentTime);
        if (suspendUpdatesUntilTime.current > 0)
          suspendUpdatesUntilTime.current = -1;
      },
      [player],
    );
    const handleSpeedChange = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) =>
        player?.updateSpeed(e.currentTarget.playbackRate),
      [player],
    );
    const handleVolumeChange = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) =>
        player?.updateVolume(e.currentTarget.volume),
      [player],
    );
    const handleLoad = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) => {
        if (e.currentTarget.src) {
          player?.onLoaded();
        }
      },
      [player],
    );
    const handleMetadataLoad = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) => {
        if (e.currentTarget.src) {
          player?.updateDuration(e.currentTarget.duration);
        }
      },
      [player],
    );
    const handleBgLoad = useCallback(
      (e: SyntheticEvent<HTMLMediaElement>) => {
        if (e.currentTarget.src) {
          player?.onBgLoaded();
        }
      },
      [player],
    );

    const handleEnded = useCallback(() => {
      background.current.pause();
      player?.onEnded();
    }, [player]);

    const img =
      mediaType === "video" || !imgSrc ? null : (
        <HallowImage
          src={imgSrc}
          alt={mediaTitle}
          size={"l"}
          {...stylex.props(styles.image)}
        />
      );

    // we use onLoadedMetadata for duration and onCanPlayThrough for "onLoaded" because
    // the onLoadedData event, normally the most reliable for these checks, is not consistently
    // fired on mobile devices
    const Media = useCallback(
      () =>
        mediaType === "video" ? (
          <Video
            ref={ref as MutableRefObject<HTMLVideoElement>}
            {...stylex.props(styles.video)}
            onPlaying={handlePlay}
            onPause={handlePause}
            onTimeUpdate={handleTimeUpdate}
            onRateChange={handleSpeedChange}
            onVolumeChange={handleVolumeChange}
            onCanPlayThrough={handleLoad}
            onLoadedMetadata={handleMetadataLoad}
            key={`hallowVideoElement_${resetUuid}`}
          />
        ) : (
          <Audio
            ref={ref}
            key={`hallowAudioElement_${resetUuid}`}
            onPlaying={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onError={onError}
            onTimeUpdate={handleTimeUpdate}
            onRateChange={handleSpeedChange}
            onVolumeChange={handleVolumeChange}
            onCanPlayThrough={handleLoad}
            onLoadedMetadata={handleMetadataLoad}
            type={"primary"}
            hlsUrl={hlsUrl}
            regularUrl={regularUrl}
            policyCookie={policyCookie}
            keyPairIdCookie={keyPairIdCookie}
            signatureCookie={signatureCookie}
          />
        ),
      [
        handlePlay,
        handlePause,
        onError,
        handleTimeUpdate,
        handleSpeedChange,
        handleVolumeChange,
        handleLoad,
        handleMetadataLoad,
        handleEnded,
        hlsUrl,
        regularUrl,
        keyPairIdCookie,
        signatureCookie,
        policyCookie,
        resetUuid,
      ],
    );

    const BgAudio = useCallback(
      () => (
        <Audio
          ref={background}
          key={"backgroundAudio"}
          type={"background"}
          hlsUrl={bgHlsUrl}
          regularUrl={bgRegularUrl}
          keyPairIdCookie={bgKeyPairIdCookie}
          policyCookie={bgPolicyCookie}
          signatureCookie={bgSignatureCookie}
          onLoadedData={handleBgLoad}
          onError={onBgError}
        />
      ),
      [
        bgHlsUrl,
        bgRegularUrl,
        bgKeyPairIdCookie,
        bgSignatureCookie,
        bgPolicyCookie,
        onBgError,
      ],
    );

    return (
      <Fragment key={"hallowMediaFragment"}>
        <Script
          src={"https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.js"}
          strategy={"lazyOnload"}
        ></Script>
        {img}
        <Media key={`hallowMediaWrapper`} />
        <BgAudio key="hallowBackgroundWrapper" />
      </Fragment>
    );
  },
);

MediaElements.displayName = "MediaElements";
