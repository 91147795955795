import type { UseMutationResult } from "@packages/sdk";
import {
  redirectToAuth,
  redirectToSubscribe,
  useRequestCollectionFavorite,
  useRequestFavorite,
  useRequestMe,
  useSession,
} from "@packages/sdk";

export type UseFavoriteCollectionActionProps = {
  id: number;
  has_access: boolean;
};

export const useFavoriteCollectionAction = ({
  id,
  has_access,
}: UseFavoriteCollectionActionProps) => {
  const { mutationPost: favoriteCollection } = useRequestCollectionFavorite({
    id,
  });
  return useFavoriteAction({
    has_access,
    updateFavorite: favoriteCollection,
  });
};

export type UseFavoritePrayerActionProps = {
  id: number;
  has_access: boolean;
};

export const useFavoritePrayerAction = ({
  id,
  has_access,
}: UseFavoritePrayerActionProps) => {
  const { mutationPost: favoritePrayer } = useRequestFavorite({ id });
  return useFavoriteAction({
    has_access,
    updateFavorite: favoritePrayer,
  });
};

type UseFavoriteActionProps = {
  has_access: boolean;
  updateFavorite: UseMutationResult<unknown, Error, void, unknown>;
};

const useFavoriteAction = ({
  has_access,
  updateFavorite,
}: UseFavoriteActionProps) => {
  const { status } = useSession();
  const { query: user } = useRequestMe();

  return () => {
    if (status === "authenticated" && (user?.data?.subscription || has_access))
      updateFavorite.mutate();
    else if (status === "authenticated") redirectToSubscribe();
    else redirectToAuth();
  };
};
