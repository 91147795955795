import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { Text } from "../../../_core";
import { QuoteIcon } from "../../../icons";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  actions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  attribution: {
    color: semanticColors.neutralsMedium,
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    width: spacing.full,
  },
  container: {
    alignItems: "center",
    backgroundColor: semanticColors.neutralsLowest,
    borderRadius: radius.ms,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
    paddingHorizontal: {
      [cssMediaMinWidthTablet]: spacing.xxl,
      default: spacing.l,
    },
    paddingVertical: spacing.xl,
    width: spacing.full,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: spacing.xs,
  },
  quoteContainer: {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flex: "1 0 0",
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
  },
  quoteText: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 6,
    display: "-webkit-box",
    maxWidth: spacing.maxWidth,
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    width: spacing.full,
  },
});

export type DailyQuoteProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  actions?: JSX.Element[];
  attribution?: string;
  quote: string;
};

export const DailyQuote = forwardRef<any, DailyQuoteProps>(
  (
    {
      actions,
      asChild = false,
      attribution,
      children,
      quote,
      styleXArray,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <span key="iconContainer" {...stylex.props(styles.iconContainer)}>
          <QuoteIcon />
        </span>,
        <div key="quoteContainer" {...stylex.props(styles.quoteContainer)}>
          {attribution && (
            <Text type="detail" size="l" styleXArray={[styles.attribution]}>
              {attribution}
            </Text>
          )}
          <Text
            type="quote"
            size="xl"
            styleXArray={[styles.quoteText]}
            as={"blockquote"}
          >
            {quote}
          </Text>
        </div>,
        actions?.length > 0 ? (
          <div key="actions" {...stylex.props(styles.actions)}>
            {actions}
          </div>
        ) : null,
      ],
      children: children as JSX.Element,
    });
    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(styles.container, styleXArray)}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

DailyQuote.displayName = "DailyQuote";
