"use client";

import type { NiceModalHandler } from "@ebay/nice-modal-react";
import { useTranslations } from "@packages/i18n";
import * as RadixUIDialog from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";
import type { Dispatch, SetStateAction } from "react";
import {
  type ComponentProps,
  Fragment,
  type ReactElement,
  type ReactNode,
  useState,
} from "react";

import {
  numericPercentages,
  numericPixels,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { createModal, useModal } from "../../../../niceModal";
import type { WithStylexArray } from "../../../../types";
import type { ButtonProps } from "../../_core";
import { Button, IconButton, Text } from "../../_core";
import { CloseIcon } from "../../icons";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";

const styles = stylex.create({
  content: {
    padding: spacing.xl,
    width: numericPercentages[100],
    zIndex: zIndices.modalOverModal,
  },
  overlay: { zIndex: zIndices.overlayOverModal },
  title: {
    flex: 1,
    margin: 0,
  },
  desc: {
    whiteSpace: "pre-line",
  },
  children: {
    marginTop: spacing.m,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  buttonsTwo: {
    justifyContent: "space-between",
  },
  buttonsMany: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  header: {
    alignItems: "center",
    display: "flex",
    gap: 10,
    marginBottom: spacing.xxs,
  },
});

const sizeStyles = stylex.create({
  mButtons: {
    marginTop: spacing.m,
  },
  lButtons: {
    marginTop: spacing.l,
  },
  mContent: {
    maxWidth: numericPixels[360],
  },
  lContent: {
    maxWidth: numericPixels[400],
  },
});

type DialogProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Root>
> & {
  buttons: ReactElement<ButtonProps>[];
  description: ReactNode;
  modalProps?: ModalProps;
  showCloseButton?: boolean;
  size?: "m" | "l";
  title: ReactNode;
};
type Modal = NiceModalHandler<Record<string, unknown>>;

export const Dialog = ({
  buttons,
  children,
  description,
  modalProps,
  onOpenChange,
  open,
  showCloseButton = true,
  size = "m",
  title,
}: DialogProps) => {
  const buttonsCount = buttons.filter((button) => !!button).length;
  const handleOpenChange = (open) => {
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  return (
    <Modal
      open={open}
      onOpenChange={handleOpenChange}
      {...modalProps}
      contentStyleXArray={[
        styles.content,
        sizeStyles[`${size}Content`],
        modalProps?.contentStyleXArray,
      ]}
      overlayStyleXArray={[styles.overlay, modalProps?.overlayStyleXArray]}
    >
      <div {...stylex.props(styles.header)}>
        <RadixUIDialog.Title asChild>
          <Text size="l" type="title" as="h3" styleXArray={[styles.title]}>
            {title}
          </Text>
        </RadixUIDialog.Title>
        {showCloseButton && (
          <IconButton
            icon={<CloseIcon />}
            variant="transparentPrimary"
            onClick={() => handleOpenChange(false)}
          />
        )}
      </div>
      <RadixUIDialog.Description asChild>
        <Text size="m" type="body" styleXArray={[styles.desc]}>
          {description}
        </Text>
      </RadixUIDialog.Description>
      {children && <div {...stylex.props(styles.children)}>{children}</div>}
      {buttonsCount > 0 && (
        <div
          {...stylex.props(
            styles.buttons,
            sizeStyles[`${size}Buttons`],
            buttonsCount === 2 && styles.buttonsTwo,
            buttonsCount > 2 && styles.buttonsMany,
          )}
        >
          {buttons.map((button, index) => (
            <Fragment key={index}>{button}</Fragment>
          ))}
        </div>
      )}
    </Modal>
  );
};

interface BaseDialogProps<T> {
  title?: (props: T) => ReactNode;
  description?: (props: T) => ReactNode;
  modalProps?: ModalProps;
  showCloseButton?: boolean;
}

type State = Record<string, any>;
type StatefulModal = Modal & {
  state: State;
  setState: Dispatch<SetStateAction<State>>;
};
type DialogButton<T> = (
  props: T,
  modal: StatefulModal,
) => ReactElement<ButtonProps>;
interface CreateDialogProps<T> extends BaseDialogProps<T> {
  buttons?: DialogButton<T>[];
  children?: (props: T, modal: StatefulModal) => React.ReactNode;
  onOpenChange?: (open: boolean, props: T, modal: StatefulModal) => void;
}

export function useDialog<T>({
  title,
  description,
  modalProps,
  buttons = [],
  children,
  onOpenChange,
  showCloseButton = true,
}: CreateDialogProps<T>) {
  const modal = createModal<T>((props) => {
    const modal = useModal();
    const [state, setState] = useState<Record<string, any>>({});
    const statefulModal = { state, setState, ...modal };

    return (
      <Dialog
        title={title(props)}
        description={description(props)}
        open={modal.visible}
        onOpenChange={(open) => {
          open ? modal.show() : modal.remove();
          onOpenChange?.(open, props, statefulModal);
        }}
        modalProps={modalProps}
        showCloseButton={showCloseButton}
        buttons={buttons.map((button) => button(props, statefulModal))}
        children={children?.(props, statefulModal)}
      />
    );
  });

  return useModal(modal);
}

type ConfirmButton<T> = (
  onClick: () => void,
  props: T,
) => ReactElement<ButtonProps>;
interface ConfirmDialogProps<T> extends BaseDialogProps<T> {
  cancelButton?: ConfirmButton<T> | string;
  primaryButton: ConfirmButton<T> | string;
  size?: "m" | "l";
}
export interface ConfirmShowProps {
  description?: string;
  title?: string;
}

export function useConfirm<T = ConfirmShowProps>({
  title = (props) => props?.["title"],
  description = (props) => props?.["description"],
  primaryButton,
  cancelButton,
  modalProps,
  size = "m",
  showCloseButton = false,
}: ConfirmDialogProps<T>) {
  const modal = createModal<T>((props) => {
    const modal = useModal();

    const handleCancel = () => {
      modal.hide();
    };

    const handleConfirm = () => {
      modal.resolve(true);
      modal.hide();
    };

    const primaryButtonEl =
      typeof primaryButton === "string" ? (
        <Button onClick={handleConfirm}>{primaryButton}</Button>
      ) : (
        primaryButton(handleConfirm, props)
      );

    const cancelButtonEl =
      typeof cancelButton === "string" ? (
        <Button onClick={handleCancel} variant="outline">
          {cancelButton}
        </Button>
      ) : (
        cancelButton?.(handleCancel, props)
      );

    return (
      <Dialog
        title={title(props)}
        description={description(props)}
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.remove())}
        modalProps={modalProps}
        showCloseButton={showCloseButton}
        buttons={[cancelButtonEl, primaryButtonEl]}
        size={size}
      />
    );
  });

  return useModal(modal);
}

interface AlertDialogProps<T> extends BaseDialogProps<T> {
  button?: ConfirmButton<T> | string;
}

export function useAlert<T = ConfirmShowProps>({
  button,
  ...props
}: AlertDialogProps<T> = {}) {
  const t = useTranslations();
  return useConfirm({
    ...props,
    primaryButton: button ?? t("general_word_ok"),
  });
}
