import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { stylesOpacity } from "../../../../../global/stylex/styles";
import {
  colors,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
} from "../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import { Text } from "../../_core";
import type { IconElement } from "../../icons";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    alignItems: "center",
    backgroundColor: colors.transparent,
    borderRadius: spacing.m,
    color: semanticColors.onOverlayMedHigh,
    cursor: "pointer",
    display: "flex",
    gap: spacing.m,
    padding: spacing.m,
    transition: "all 150ms ease-out",
  },
  disabled: {
    cursor: "pointer",
    opacity: "50%",
  },
  rightActions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
  },
  selected: {
    backgroundColor: semanticColors.onOverlayLow,
    color: semanticColors.onOverlayHighest,
  },
  states: {
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.onOverlayLow,
      ":active": semanticColors.onOverlayLow,
    },
    color: {
      default: semanticColors.onOverlayMedHigh,
      ":active": semanticColors.onOverlayHighest,
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    minWidth: spacing.none,
    width: spacing.full,
  },
});

export type CellProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  disabled?: boolean;
  icon?: IconElement;
  label?: string;
  rightActions?: KeyedElement | KeyedElement[];
  selected?: boolean;
  text?: string;
};

export const Cell = forwardRef<any, CellProps>(
  (
    {
      asChild = false,
      children,
      disabled,
      icon,
      label,
      rightActions,
      selected,
      text,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        icon ? icon : null,
        <div key="textContainer" {...stylex.props(styles.textContainer)}>
          {label && (
            <Text overflow="ellipsis" size="l" type="detail">
              {label}
            </Text>
          )}
          {text && (
            <Text overflow="ellipsis" size="m" type="title">
              {text}
            </Text>
          )}
        </div>,
        rightActions ? (
          <div key="rightActions" {...stylex.props(styles.rightActions)}>
            {rightActions}
          </div>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...deepMergeStyleXProps({
          object1: stylex.props(
            styles.determinedElement,
            stylesOpacity.active,
            stylesOpacity.base,
            disabled ? styles.disabled : null,
            selected ? styles.selected : null,
            !disabled && !selected ? styles.states : null,
          ),
          object2: props,
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

Cell.displayName = "Cell";
