"use client";

import { SearchIcon } from "../../icons";
import type { InputProps } from "../Input";
import { Input } from "../Input";

export type SearchBar = InputProps;

export const SearchBar = ({ ...props }: InputProps) => (
  <Input
    clearable
    closeable
    icon={<SearchIcon />}
    loseFocusOnOutsideClick={false}
    {...props}
  />
);
