"use client";

// adapted from https://github.com/rozek/dragdroptouch-bug-fixed, itself adapted from https://github.com/Bernardo-Castilho/dragdroptouch

import type { Touch } from "react";
import { useEffect } from "react";

type DropEffect = "none" | "copy" | "link" | "move";
type AllowedEffect =
  | "none"
  | "copy"
  | "copyLink"
  | "copyMove"
  | "link"
  | "linkMove"
  | "move"
  | "all"
  | "uninitialized";

type Point = { x: number; y: number };

class DragDropTouch {
  private dataTransfer: DataTransfer;
  private dragSource: Element;
  private img: HTMLElement;
  imgCustom: Element;
  imgOffset: { x: number; y: number };
  private isDragEnabled: boolean;
  private isDropZone: boolean;
  private lastClick: number;
  private lastMovementX: number;
  private lastMovementY: number;
  private lastTarget: EventTarget;
  private lastTouch: TouchEvent;
  private pointDown: Point;
  private pressHoldInterval: NodeJS.Timeout | null;

  // Utility functions
  private shouldHandle: (e: TouchEvent) => boolean = (e) => {
    return e && !e.defaultPrevented && e.touches?.length < 2;
  };
  private shouldHandleMove: (e: TouchEvent) => boolean = (e) => {
    return !DragDropTouch.IS_PRESS_HOLD_MODE && this.shouldHandle(e);
  };
  private shouldHandlePressHoldMove: (e: TouchEvent) => boolean = (e) => {
    return (
      DragDropTouch.IS_PRESS_HOLD_MODE &&
      this.isDragEnabled &&
      e?.touches?.length > 0
    );
  };
  private shouldCancelPressHoldMove: (e: TouchEvent) => boolean = (e) => {
    return (
      DragDropTouch.IS_PRESS_HOLD_MODE &&
      !this.isDragEnabled &&
      this.getDelta(e) > DragDropTouch.PRESS_HOLD_MARGIN
    );
  };
  private shouldStartDragging: (e: TouchEvent) => boolean = (e) => {
    const delta = this.getDelta(e);
    return (
      delta > DragDropTouch.THRESHOLD ||
      (DragDropTouch.IS_PRESS_HOLD_MODE &&
        delta >= DragDropTouch.PRESS_HOLD_THRESHOLD)
    );
  };
  private dispatchEvent: (
    e: TouchEvent | MouseEvent,
    type: string,
    target: EventTarget,
    extras?: any,
  ) => boolean = (e, type, target, extras) => {
    if (e && target) {
      const options: MouseEventInit | DragEventInit = {
        bubbles: true,
        cancelable: true,
        button: 0,
        buttons: 1,
        which: 1,
      };
      if (extras) {
        options.movementX = extras.movementX;
        options.movementY = extras.movementY;
      }
      const touch = (e as TouchEvent)?.touches?.[0] ?? e;

      DragDropTouch.copyProps(
        e as MouseEvent,
        options,
        DragDropTouch.KEYBOARD_PROPS,
      );
      DragDropTouch.copyProps(
        touch as Touch | MouseEvent,
        options,
        DragDropTouch.POINT_PROPS,
      );

      let evt: Event;
      if (
        type.toLowerCase().startsWith("drag") ||
        type.toLowerCase() === "drop"
      ) {
        (options as DragEventInit).dataTransfer = this.dataTransfer;
        evt = new DragEvent(type, options);
      } else {
        evt = new MouseEvent(type, options);
      }

      // const touch = e?.touches?.[0] ?? e;

      target.dispatchEvent(evt);
      return evt.defaultPrevented;
    }
    return false;
  };
  private reset: () => void = () => {
    this.destroyImage();
    this.dragSource = null;
    this.lastTouch = null;
    this.lastTarget = null;
    this.pointDown = null;
    this.isDragEnabled = false;
    this.isDropZone = false;
    this.dataTransfer = new DataTransfer();
    this.lastMovementX = 0;
    this.lastMovementY = 0;
    clearInterval(this.pressHoldInterval);
  };
  private closestDraggable: (el: Element | null) => Element | null = (e) => {
    while ((e = e.parentElement)) {
      if (e.hasAttribute("draggable") && e.getAttribute("draggable")) {
        return e;
      }
    }
    return null;
  };
  private getDelta: (e: TouchEvent) => number = (e) => {
    if (DragDropTouch.IS_PRESS_HOLD_MODE && !this.pointDown) {
      return 0;
    }

    const p = this.getPoint(e);
    return Math.abs(p.x - this.pointDown.x) + Math.abs(p.y - this.pointDown.y);
  };
  private getPoint: (e: MouseEvent | TouchEvent, page?: boolean) => Point = (
    e,
    page,
  ) => {
    if ((e as TouchEvent)?.touches?.length > 0) {
      const touch = (e as TouchEvent).touches[0];
      return {
        x: page ? touch.pageX : touch.clientX,
        y: page ? touch.pageY : touch.clientY,
      };
    } else if (e) {
      const me = e as MouseEvent;
      return {
        x: page ? me.pageX : me.clientX,
        y: page ? me.pageY : me.clientY,
      };
    }
  };
  private getTarget: (e: TouchEvent) => EventTarget = (e) => {
    let pt = this.getPoint(e);
    let el = document.elementFromPoint(pt.x, pt.y);

    while (el !== null && getComputedStyle(el).pointerEvents == "none") {
      el = el.parentElement;
    }
    return el;
  };
  private createImage: (e: TouchEvent) => void = (e) => {
    if (this.img != null) {
      this.destroyImage();
    }

    const src = this.imgCustom || this.dragSource;
    this.img = src.cloneNode(true) as HTMLElement;
    DragDropTouch.copyStyle(src, this.img);
    this.img.style.top = this.img.style.left = "-9999px";

    if (this.imgCustom === null) {
      const rect = src.getBoundingClientRect();
      const point = this.getPoint(e);

      this.imgOffset = { x: point.x - rect.left, y: point.y - rect.top };
      this.img.style.opacity = DragDropTouch.OPACITY.toString();
    }

    this.moveImage(e);
    document.body.appendChild(this.img);
  };
  private moveImage: (e: TouchEvent) => void = (e) => {
    requestAnimationFrame(() => {
      if (this.img != null) {
        const pt = this.getPoint(e, true);
        const s = this.img.style;
        s.position = "absolute";
        s.pointerEvents = "none";
        s.zIndex = "999999";
        s.left = Math.round(pt.x - this.imgOffset.x) + "px";
        s.top = Math.round(pt.y - this.imgOffset.y) + "px";
      }
    });
  };
  private destroyImage: () => void = () => {
    if (this.img?.parentElement) {
      this.img.parentElement.removeChild(this.img);
    }
    this.img = null;
    this.imgCustom = null;
  };

  private static copyProps: (
    src: Touch | MouseEvent,
    dst: EventInit,
    props: Array<string>,
  ) => void = (src, dst, props) => {
    for (const p of props) {
      dst[p] = src[p];
    }
  };

  private static copyStyle: (src: Element, dst: Element) => void = (
    src,
    dst,
  ) => {
    DragDropTouch.REMOVE_ATTRS.forEach((attr: string) => {
      dst.removeAttribute(attr);
    });

    if (src instanceof HTMLCanvasElement) {
      const cSrc = src as HTMLCanvasElement;
      const cDst = dst as HTMLCanvasElement;

      cDst.width = cSrc.width;
      cDst.height = cSrc.height;

      cDst.getContext("2d").drawImage(cSrc, 0, 0);
    }

    const cs = getComputedStyle(src);
    for (const key of Object.keys(cs)) {
      if (key.indexOf("transition") >= 0) continue;
      if (!isNaN(parseInt(key))) continue;
      (dst as HTMLElement).style[key] = cs[key];
    }
    (dst as HTMLElement).style.pointerEvents = "none";

    for (let i = 0; i < src.children.length; i++) {
      DragDropTouch.copyStyle(src.children[i], dst.children[i]);
    }
  };

  // Event handlers
  private touchStart: (e: TouchEvent) => void = (e: TouchEvent) => {
    if (this.shouldHandle(e)) {
      if (Date.now() - this.lastClick < DragDropTouch.DBLCLICK) {
        if (this.dispatchEvent(e, "dblclick", e.target)) {
          e.preventDefault();
          this.reset();
          return;
        }
      }

      this.reset();

      const src = this.closestDraggable(e.target as Element);
      if (src !== null) {
        if (
          !this.dispatchEvent(e, "mousemove", e.target) &&
          !this.dispatchEvent(e, "mousedown", e.target)
        ) {
          this.dragSource = src;
          this.pointDown = this.getPoint(e);
          this.lastTouch = e;
          e.preventDefault();

          setTimeout(() => {
            if (this.dragSource === src && this.img == null) {
              if (this.dispatchEvent(e, "contextmenu", src)) {
                this.reset();
              }
            }
          }, DragDropTouch.CTXMENU);

          if (DragDropTouch.IS_PRESS_HOLD_MODE) {
            this.pressHoldInterval = setTimeout(() => {
              this.isDragEnabled = true;
              this.touchMove(e);
            }, DragDropTouch.PRESS_HOLD_AWAIT);
          }
        }
      }
    }
  };
  private touchMove: (e: TouchEvent) => void = (e: TouchEvent) => {
    if (this.shouldCancelPressHoldMove(e)) {
      this.reset();
      return;
    }

    if (this.shouldHandleMove(e) || this.shouldHandlePressHoldMove(e)) {
      const target = this.getTarget(e);
      if (this.dispatchEvent(e, "mousemove", target)) {
        this.lastTouch = e;
        e.preventDefault();
        return;
      }

      if (!this.lastTouch) {
        this.lastTouch = e;
        return;
      }

      const lastPointOnPage = this.getPoint(this.lastTouch, true);
      const curPointOnPage = this.getPoint(e, true);
      this.lastMovementX = curPointOnPage.x - lastPointOnPage.x;
      this.lastMovementY = curPointOnPage.y - lastPointOnPage.y;
      const Extras = {
        movementX: this.lastMovementX,
        movementY: this.lastMovementY,
      };

      if (this.dragSource && this.img == null && this.shouldStartDragging(e)) {
        this.dispatchEvent(e, "dragstart", this.dragSource, Extras);
        this.createImage(e);
        this.dispatchEvent(e, "dragenter", target, Extras);
      }

      if (this.img != null) {
        this.lastTouch = e;
        e.preventDefault();

        this.dispatchEvent(e, "drag", this.dragSource, Extras);

        if (target != this.lastTarget) {
          this.dispatchEvent(
            this.lastTouch,
            "dragleave",
            this.lastTarget,
            Extras,
          );
          this.dispatchEvent(e, "dragenter", target, Extras);
          this.lastTarget = target;
        }

        this.moveImage(e);
        this.isDropZone = this.dispatchEvent(e, "dragover", target, Extras);
      }
    }
  };
  private touchEnd: (e: TouchEvent) => void = (e: TouchEvent) => {
    if (this.shouldHandle(e)) {
      if (this.dispatchEvent(this.lastTouch, "mouseup", e.target)) {
        e.preventDefault();
        return;
      }

      if (this.img == null) {
        this.dragSource = null;
        this.dispatchEvent(this.lastTouch, "click", e.target);
        this.lastClick = Date.now();
      }

      this.destroyImage();

      if (this.dragSource) {
        let Extras = {
          movementX: this.lastMovementX,
          movementY: this.lastMovementY,
        };

        if (e.type.indexOf("cancel") < 0 && this.isDropZone) {
          this.dispatchEvent(this.lastTouch, "drop", this.lastTarget, Extras);
        }
        this.dispatchEvent(this.lastTouch, "dragend", this.dragSource, Extras);
        this.reset();
      }
    }
  };

  /** pixels to move before drag starts */
  private static THRESHOLD: number = 5;
  /** drag image opacity */
  private static OPACITY = 0.5;
  /** max ms between clicks for it to be a double click */
  private static DBLCLICK = 500;
  /** min ms for a touch-and-hold to be seeking a context menu */
  private static CTXMENU = 900;
  /** indicates whether press & hold mode is used */
  private static IS_PRESS_HOLD_MODE = false;
  /** ms to wait before press & hold is detected */
  private static PRESS_HOLD_AWAIT = 400;
  /** pixels that finger might move while pressing */
  private static PRESS_HOLD_MARGIN = 25;
  /** pixels to move before drag starts after press & hold mode is activated */
  private static PRESS_HOLD_THRESHOLD = 0;

  private static REMOVE_ATTRS = ["id", "class", "style", "draggable"];
  private static KEYBOARD_PROPS = ["altKey", "ctrlKey", "metaKey", "shiftKey"];
  private static POINT_PROPS = [
    "pageX",
    "pageY",
    "clientX",
    "clientY",
    "screenX",
    "screenY",
    "offsetX",
    "offsetY",
  ];

  constructor() {
    this.lastClick = 0;

    // singleton limitation
    if (typeof window !== "undefined" && window.DragDropTouch) {
      throw new Error("DragDropTouch instance already created.");
    }

    // feature-detection: passive event listeners
    let supportsPassive = false;
    if (typeof document !== "undefined") {
      const passiveTest = {
        get passive() {
          supportsPassive = true;
          document.removeEventListener("test", emptyFunc);
          return true;
        },
      };
      const emptyFunc = () => {};

      document.addEventListener("test", emptyFunc, passiveTest);
    }

    if (
      typeof navigator !== "undefined" &&
      typeof document !== "undefined" &&
      navigator.maxTouchPoints > 0
    ) {
      const options = supportsPassive
        ? { passive: false, capture: false }
        : false;

      document.addEventListener("touchstart", this.touchStart, options);
      document.addEventListener("touchmove", this.touchMove, options);
      document.addEventListener("touchend", this.touchEnd);
      document.addEventListener("touchcancel", this.touchEnd);
    }
  }
}

declare global {
  interface Window {
    DragDropTouch: DragDropTouch | null;
  }
}

export const useDragDropTouch = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        window.DragDropTouch = new DragDropTouch();
      } catch (e) {
        // an error here probably means we're trying to instantiate this again, so just log it for reference
        console.error(e);
      }
    }

    return () => {
      if (typeof window !== "undefined") {
        window.DragDropTouch = null;
      }
    };
  }, []);
};
