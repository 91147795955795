"use client";

import { createFullscreenModal } from "../../../modals";
import {
  CampaignSupportForm,
  type CampaignSupportFormProps,
} from "./CampaignSupportForm";

export type CampaignSupportModalProps = CampaignSupportFormProps;

export const CampaignSupportModal = createFullscreenModal(
  (props: CampaignSupportModalProps) => <CampaignSupportForm {...props} />,
);
