"use client";

import stylex from "@stylexjs/stylex";
import { createContext, forwardRef, useId } from "react";

import type { WithStylexArray } from "../../../../types";
import { FormControl } from "./FormControl";
import { FormDescription } from "./FormDescription";
import { FormMessage } from "./FormMessage";

const styles = stylex.create({
  formItem: {
    spaceY: 2,
  },
});

type FormItemContextValue = {
  id: string;
};

export const FormItemContext = createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

export type FormItemProps = WithStylexArray<
  React.HTMLAttributes<HTMLDivElement> & {
    showErrorMessage?: boolean;
    description?: string;
  }
>;

export const FormItem = forwardRef<HTMLDivElement, FormItemProps>(
  (
    { children, styleXArray, showErrorMessage = true, description, ...props },
    ref,
  ) => {
    const id = useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div
          ref={ref}
          {...stylex.props(styles.formItem, styleXArray)}
          {...props}
        >
          <FormControl>{children}</FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          {showErrorMessage && <FormMessage />}
        </div>
      </FormItemContext.Provider>
    );
  },
);

FormItem.displayName = "FormItem";
