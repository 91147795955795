"use client";

import { createContext, useContext } from "react";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";

import type { FormItemProps } from "./FormItem";
import { FormItem, FormItemContext } from "./FormItem";

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

export const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  render,
  showErrorMessage,
  description,
  styleXArray,
  ...props
}: ControllerProps<TFieldValues, TName> &
  Pick<FormItemProps, "showErrorMessage" | "description" | "styleXArray">) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller
        {...props}
        render={(controllerProps) => (
          <FormItem
            showErrorMessage={showErrorMessage}
            description={description}
            styleXArray={styleXArray}
          >
            {render(controllerProps)}
          </FormItem>
        )}
      />
    </FormFieldContext.Provider>
  );
};

FormField.displayName = "FormField";

export const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};
