"use client";

import type { MediaSizes } from "@packages/media";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import { Fragment, useCallback } from "react";

import {
  numericValues,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { Minus10Icon, RewindIcon } from "../../../../../components";
import type { WithButtonWrapper } from "../../../../../types";
import {
  CloseButton,
  ForwardButton,
  MediaButton,
  PlayPause,
} from "../../controls";

const buttonStyles = stylex.create({
  default: {
    height: "auto",
    width: "auto",
  },
  "expanded-mobile": {
    padding: numericValues[0],
  },
  "expanded-desktop": {
    padding: numericValues[0],
  },
});

const containerStyles = stylex.create({
  controlSet: {
    alignContent: "center",
    columnGap: spacing.s,
    display: "grid",
    gridAutoColumns: "min-content",
    gridTemplateAreas: '"back play forward"',
    gridTemplateRows: "1fr",
    placeContent: "end",
  },
  "collapsed-mobile": {
    columnGap: numericValues[0],
    gridTemplateAreas: '"play close"',
  },
  "collapsed-desktop": {
    columnGap: spacing.l,
  },
  "expanded-mobile": {
    justifyContent: "space-between",
  },
  "expanded-desktop": {
    columnGap: spacing.xxl,
  },
});

export type PlaybackControlsProps = WithButtonWrapper<{
  size: MediaSizes;
  onClose: MouseEventHandler<HTMLButtonElement>;
}>;

export const PlaybackControls = ({
  Wrapper = ({ children }) => <Fragment>{children}</Fragment>,
  size,
  onClose,
}: PlaybackControlsProps) => {
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();

  const handleBack = useCallback(
    (e) => {
      e.stopPropagation();
      player?.skipBack();
    },
    [player],
  );

  const canSkipIntro =
    player &&
    player.currentTime < 15 &&
    currentItem?.selected_audio.intro_ends_at &&
    player.currentTime < currentItem.selected_audio.intro_ends_at / 1000;

  return (
    <div {...stylex.props(containerStyles.controlSet, containerStyles[size])}>
      {size === "collapsed-mobile" ? null : (
        <Wrapper asChild>
          <MediaButton
            largeIcon={
              currentItem?.selected_audio?.show_music_controls ? (
                <RewindIcon.XL />
              ) : (
                <Minus10Icon.XL />
              )
            }
            smallIcon={
              currentItem?.selected_audio?.show_music_controls ? (
                <RewindIcon.Small />
              ) : (
                <Minus10Icon.Medium />
              )
            }
            styleXArray={[buttonStyles.default, buttonStyles[size]]}
            onClick={handleBack}
            tabIndex={0}
          />
        </Wrapper>
      )}
      <Wrapper asChild>
        <PlayPause tabIndex={0} />
      </Wrapper>
      {size === "collapsed-mobile" ? (
        <CloseButton
          onClick={onClose}
          styleXArray={[buttonStyles.default, buttonStyles[size]]}
        />
      ) : (
        <ForwardButton
          canSkipIntro={canSkipIntro}
          Wrapper={Wrapper}
          forwardStyleXArray={[buttonStyles.default, buttonStyles[size]]}
        />
      )}
    </div>
  );
};
