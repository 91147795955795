"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useState } from "react";
import { useFocus, useHover, usePress } from "react-aria";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
} from "../../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { HallowImage } from "../../../../blocks";
import { Text } from "../../../_core";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
    paddingRight: spacing.m,
    width: spacing.full,
  },
  illustrationImg: {
    "-webkit-user-drag": "none",
    borderBottomLeftRadius: spacing.s,
    borderTopRightRadius: spacing.s,
    bottom: spacing.none,
    height: spacing.xl,
    left: spacing.none,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    userSelect: "none",
    width: spacing.xl,
  },
  img: (props: { color: string }) => ({
    borderColor: props.color,
    borderRadius: radius.s,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    boxSizing: "border-box",
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
  }),
  imgContainer: {
    position: "relative",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.xxs,
    minWidth: spacing.none,
  },
  infoTopContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.t,
  },
  metadata: {
    color: semanticColors.neutralsMedium,
  },
  rightActionsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
  },
});

const stylesSize = stylex.create({
  imgContainers: {
    height: 72,
  },
  imgContainerm: {
    height: 80,
  },
  imgs: {
    height: 72,
    width: 72,
  },
  imgm: {
    height: 80,
    width: 80,
  },
});

export type CampaignSmallProps = WithAsChild<
  HallowElementProps<typeof hallowElement>
> & {
  activeState?: boolean;
  color: string;
  description: string;
  focusState?: boolean;
  hoverState?: boolean;
  illustrationImgSrc: string;
  imgSrc: Images;
  metadata: string;
  rightActions?: KeyedElement | KeyedElement[];
  size?: "s" | "m";
  title: string;
};

export const CampaignSmall = forwardRef<any, CampaignSmallProps>(
  (
    {
      activeState = true,
      asChild = false,
      children,
      color,
      description,
      hoverState = true,
      focusState = true,
      illustrationImgSrc,
      imgSrc,
      metadata,
      rightActions,
      size = "m",
      title,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    const { isPressed: active, pressProps: activeProps } = usePress({});
    const { focusProps } = useFocus({
      onFocusChange: (focus) => setFocus(focus),
    });
    const { isHovered: hover, hoverProps } = useHover({});

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div
          key="imgContainer"
          {...stylex.props(
            styles.imgContainer,
            stylesSize[`imgContainer${size}`],
          )}
        >
          <HallowImage
            alt={title}
            src={imgSrc}
            size={"s"}
            {...stylex.props(
              styles.img({ color }),
              stylesOutline.base,
              stylesSize[`img${size}`],
              focus && focusState ? stylesOutline.focusControlled : null,
              hover && hoverState ? stylesOutline.hoverControlled : null,
            )}
          />
          <img
            alt={title}
            src={illustrationImgSrc}
            {...stylex.props(styles.illustrationImg)}
          />
        </div>,
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          <div {...stylex.props(styles.infoTopContainer)}>
            <Text
              overflow="ellipsis"
              size={size === "s" ? "m" : "l"}
              type="title"
            >
              {title}
            </Text>
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          </div>
          <Text
            overflow="ellipsis"
            size="l"
            styleXArray={[styles.metadata]}
            type="detail"
          >
            {metadata}
          </Text>
        </div>,
        rightActions ? (
          <div
            key="rightActions"
            {...stylex.props(styles.rightActionsContainer)}
          >
            {rightActions}
          </div>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...activeProps}
        {...focusProps}
        {...hoverProps}
        {...deepMergeStyleXProps({
          object1: stylex.props(
            styles.determinedElement,
            activeState ? stylesOpacity.active : null,
            activeState ? stylesOpacity.base : null,
            activeState && hoverState ? stylesScale.base : null,
            activeState && hoverState ? stylesScale.l : null,
          ),
          object2: props,
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

CampaignSmall.displayName = "CampaignSmall";
