"use client";

import { useFormatter, useTranslations } from "@packages/i18n";
import type { TriviaGame, TriviaQuestion } from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../../types";
import type { CSSColor } from "../../../../../utils";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import type { ButtonProps } from "../../../_core";
import { Text } from "../../../_core";
import { CheckmarkIcon, CloseIcon } from "../../../icons";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  actions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
    zIndex: 1,
  },
  container: {
    alignItems: "center",
    color: semanticColors.onColorHighest,
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
    width: spacing.fit,
  },
  iconContainer: (props: { size: number }) => ({
    borderRadius: radius.full,
    height: props.size,
    minHeight: props.size,
    minWidth: props.size,
    width: props.size,
  }),
  iconContainerCorrect: {
    backgroundColor: "#00BB8C",
  },
  iconContainerIncorrect: {
    backgroundColor: "#FF4762",
  },
  scoresContainer: {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    gap: "15px",
    justifyContent: "center",
  },
  summaryContainer: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "space-around",
    paddingHorizontal: "15px",
    width: spacing.full,
  },
  summaryStatisticContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    textAlign: "center",
  },
  summaryStatisticLabel: {
    color: semanticColors.onColorVeryHigh,
    whiteSpace: "nowrap",
  },
});

export type TriviaScoreProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  actions?: JSX.Element[];
  color?: CSSColor;
  questions: Pick<TriviaQuestion, "user_answer" | "answers" | "id">[];
  shareButtonProps?: ButtonProps;
  size?: "m" | "l";
  summary: TriviaGame["summary"];
};

interface TriviaAnswerScoreProps {
  correct: boolean;
  questionId: number;
  index: number;
  size: TriviaScoreProps["size"];
}

const TriviaAnswerScore = ({
  correct,
  questionId,
  index,
  size,
}: TriviaAnswerScoreProps) => {
  const Icon = correct ? CheckmarkIcon.SimpleOn : CloseIcon;
  return (
    <div
      key={`${questionId}${index}`}
      {...stylex.props(
        styles.iconContainer({ size: size === "l" ? 48 : 32 }),
        correct ? styles.iconContainerCorrect : styles.iconContainerIncorrect,
      )}
    >
      <Icon
        size={size === "l" ? 48 : 32}
        fill={semanticColors.onColorHighest}
      />
    </div>
  );
};

export const TriviaScore = ({
  actions,
  asChild = false,
  children,
  color,
  questions,
  size = "m",
  styleXArray,
  summary,
  ...props
}: TriviaScoreProps) => {
  const format = useFormatter();
  const t = useTranslations();

  const userAnswers = questions.map((question) => {
    const correctAnswer = question.answers.find((answer) => answer.correct);
    const userAnswer = question.user_answer;

    return {
      correct: correctAnswer?.id === userAnswer?.answer_id,
      questionId: question.id,
    };
  });

  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  const DeterminedChildren = determineChildrenInject({
    afterChildrenInject: [
      <Text
        key="text"
        type={size === "l" ? "headline" : "title"}
        size={size === "l" ? "m" : "l"}
      >
        {t("trivia_your_score")}
      </Text>,
      <div key="scoresContainer" {...stylex.props(styles.scoresContainer)}>
        {userAnswers.map((userAnswer, index) => (
          <TriviaAnswerScore {...userAnswer} index={index} size={size} />
        ))}
      </div>,
      <div key="summaryContainer" {...stylex.props(styles.summaryContainer)}>
        {[
          {
            label: t("trivia_summary_seconds"),
            value: format.number(summary.total_seconds_elapsed),
          },
          {
            label: t("trivia_current_streak"),
            value: format.number(summary.streak),
          },
          {
            label: t("trivia_max_streak"),
            value: format.number(summary.longest_streak),
          },
        ].map((statistic) => (
          <div
            key={statistic.label}
            {...stylex.props(styles.summaryStatisticContainer)}
          >
            <Text
              type={size === "l" ? "headline" : "title"}
              size={size === "l" ? "m" : "l"}
            >
              {statistic.value}
            </Text>
            <Text
              type="detail"
              size="xl"
              styleXArray={[styles.summaryStatisticLabel]}
            >
              {statistic.label}
            </Text>
          </div>
        ))}
      </div>,
      actions?.length > 0 ? (
        <div key="actions" {...stylex.props(styles.actions)}>
          {actions}
        </div>
      ) : null,
    ],
    children: children as JSX.Element,
  });

  return (
    <DeterminedElement
      {...props}
      {...stylex.props(styles.container, styleXArray)}
    >
      {DeterminedChildren}
    </DeterminedElement>
  );
};
