"use client";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import type { FlaggableType } from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import { spacing, zIndices } from "../../../../../global/stylex/vars.stylex";
import { FullscreenModalModal } from "../../../_base";
import { FlagRecordForm } from "../FlagRecordForm";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  viewport: {
    height: {
      [cssMediaMinWidthDesktop]: "auto",
      default: `calc(100vh - 148px)`,
    },
    maxHeight: {
      [cssMediaMinWidthDesktop]: "100vh",
    },
    maxWidth: "unset",
    padding: { [cssMediaMinWidthDesktop]: spacing.xl },
    width: "unset",
  },
});

const stylesModal = stylex.create({
  content: {
    width: "fit-content",
    zIndex: zIndices.modalOverModal,
  },
  overlay: { zIndex: zIndices.overlayOverModal },
});

type FlagRecordModalProps = {
  flaggableId: number;
  flaggableType: FlaggableType;
};

export const FlagRecordModal = NiceModal.create(
  ({ flaggableId, flaggableType }: FlagRecordModalProps) => {
    const modal = useModal();
    return (
      <FullscreenModalModal
        fullScreenModalProps={{
          showCloseButton: true,
        }}
        modalProps={{
          contentStyleXArray: [stylesModal.content],
          overlayStyleXArray: [stylesModal.overlay],
        }}
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.hide())}
        styleXArray={[styles.viewport]}
      >
        <FlagRecordForm
          flaggableId={flaggableId}
          flaggableType={flaggableType}
        />
      </FullscreenModalModal>
    );
  },
);
