"use client";

import {
  getCookiesHlwConsent,
  getValidatedCookiesHlwConsent,
  setCookiesHlwConsent,
  useRequestMe,
  useSession,
} from "@packages/sdk";
import { ConsentModal, CookieManager } from "@packages/ui";
import { useContext, useEffect, useState } from "react";

import { analytics, Consent } from "../lib";
import { consentContext } from "./ConsentProvider/ConsentProvider";

export function Analytics() {
  const { status } = useSession();

  // current user consent
  const { mutationPut: updateMe } = useRequestMe();
  const { consent: consentLocal, setConsent: setConsentLocal } =
    useContext(consentContext);
  const consentCookie = getValidatedCookiesHlwConsent();

  // state
  const [consentModalVisible, setConsentModalVisible] = useState(false);

  useEffect(() => {
    if (consentCookie) {
      const analyticsConsent = consentCookie.includes(Consent.ANALYTICS);

      if (analyticsConsent) {
        loadAnalytics(consentCookie);
      } else {
        setConsentModalVisible(true);
      }
    } else {
      const fetchIpDetails = async () => {
        let ipDetails = null;

        try {
          const ipResponse = await fetch(
            `https://ipwhois.pro/json/?key=${process.env.NEXT_PUBLIC_WHOIS_KEY}`,
          );
          ipDetails = await ipResponse.json();
        } catch (err) {
          console.error(err);
        }

        if (showModal(ipDetails)) {
          // if required, show ConsentModal
          setConsentModalVisible(true);
          // else load analytics
        } else {
          loadAnalytics(consentCookie);
        }
      };

      fetchIpDetails();
    }

    // ensure localStorage and cookie consent values match
    // if both exist and do not match, defer to cookie
    if (
      (consentCookie && consentLocal && consentCookie !== consentLocal) ||
      (consentCookie && !consentLocal) ||
      (!consentCookie && consentLocal)
    ) {
      if (consentCookie) setConsentLocal(consentCookie);
      else setCookiesHlwConsent(JSON.stringify(consentLocal));
    }
  }, []);

  // temporary self-healing for users with consent = "1,2,3" rather than "[1,2,3]"
  useEffect(() => {
    if (getCookiesHlwConsent() === "1,2,3")
      setCookiesHlwConsent(JSON.stringify([1, 2, 3]));
  }, [consentCookie, consentLocal]);

  // determine whether to show ConsentModal
  const showModal = (ipDetails) => {
    let showModal = true;

    // if we received ipDetails, check our locale
    if (ipDetails && ipDetails.success) {
      const { continent_code, continent, country_code, country, region } =
        ipDetails;

      if (
        !(
          continent_code?.toLowerCase() === "eu" ||
          continent?.toLowerCase() === "europe" ||
          ((country_code?.toLowerCase() === "us" ||
            country?.toLowerCase() === "united states") &&
            region?.toLowerCase() === "california") ||
          ["brazil", "south africa"].includes(country?.toLowerCase())
        )
      )
        showModal = false;
    }

    return showModal;
  };

  const loadAnalytics = (consent: number[]) => {
    if (consent?.includes(Consent.ANALYTICS)) {
      analytics.load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string,
      });
    }
  };

  const saveConsent = (value: number[]) => {
    setCookiesHlwConsent(JSON.stringify(value));
    setConsentLocal(value);

    if (status === "authenticated") updateMe.mutate({ consent: value });
    loadAnalytics(value);
  };

  return (
    <>
      <CookieManager
        saveConsent={saveConsent}
        setConsentModalVisible={setConsentModalVisible}
      />
      {consentModalVisible && (
        <ConsentModal
          open={consentModalVisible}
          saveConsent={saveConsent}
          setOpen={setConsentModalVisible}
        />
      )}
    </>
  );
}
