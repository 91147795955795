import * as stylex from "@stylexjs/stylex";
import { forwardRef, Fragment } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { ArrowRightIcon } from "../../icons";
import { Text } from "../Text";

const hallowElement: HallowElement = "a";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: semanticColors.secondary,
      ":hover": semanticColors.neutralsLowest,
      ":focus": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderRadius: radius.m,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    justifyContent: "space-between",
    opacity: {
      default: numericValues[1],
      ":focus": numericValues[0.6],
      ":active": numericValues[0.6],
    },
    padding: spacing.m,
    width: numericPercentages[100],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    width: spacing.t,
  },
});

export type ListLinkProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  description?: string;
  icon?: IconElement;
  title: string;
};

export const ListLink = forwardRef<any, ListLinkProps>(
  (
    {
      asChild = false,
      children,
      description,
      icon = <ArrowRightIcon />,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <span key="content" {...stylex.props(styles.content)}>
          <Text overflow="ellipsis" size="m" type="title">
            {title}
          </Text>
          {description && (
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          )}
        </span>,
        <Fragment key="icon">{icon}</Fragment>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...stylex.props(styles.base, styleXArray)}
        {...props}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListLink.displayName = "ListLink";
