"use client";

import { Label as RadixUiDropdownMenuLabel } from "@radix-ui/react-dropdown-menu";
import stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import {
  numericPixels,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import { Text } from "../../_core";

const styles = stylex.create({
  base: {
    alignItems: "center",
    display: "flex",
    height: numericPixels[56],
    justifyContent: "center",
    minWidth: numericPixels[300],
    padding: spacing.m,
    width: spacing.full,
  },
});

export type DropdownMenuHeaderProps = WithStylexArray<
  ComponentProps<typeof RadixUiDropdownMenuLabel>
>;

export const DropdownMenuHeader = ({
  children,
  styleXArray = [],
  ...props
}: DropdownMenuHeaderProps) => {
  return (
    <Text key={`dropdown-menu-header`} asChild size="m" type="title">
      <RadixUiDropdownMenuLabel
        key={`dropdown-menu-header`}
        {...props}
        {...stylex.props(styles.base, styleXArray)}
      >
        {children}
      </RadixUiDropdownMenuLabel>
    </Text>
  );
};
