"use client";

import type * as RadixUIDialog from "@radix-ui/react-dialog";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps, ReactNode } from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import type { WithStylexArray } from "../../../../types";
import type { FullscreenModalProps } from "../FullscreenModal";
import { FullscreenModal } from "../FullscreenModal";
import type { OverlayModalProps } from "../OverlayModal";
import { OverlayModal } from "../OverlayModal";

const styles = stylex.create({
  content: {
    padding: spacing.none,
  },
});

type FullscreenModalOverlayModalProps = WithStylexArray<
  ComponentProps<typeof RadixUIDialog.Root>
> & {
  isSlotModal?: boolean;
  trigger?: ReactNode;
  fullScreenModalProps?: FullscreenModalProps;
  overlayModalProps?: OverlayModalProps;
};

export const FullscreenModalOverlayModal = ({
  open,
  onOpenChange,
  children,
  trigger,
  fullScreenModalProps = {},
  overlayModalProps = {},
}: FullscreenModalOverlayModalProps) => {
  const isDesktopViewport = useIsDesktopViewport();

  const handleOpenChange = (open) => {
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  if (isDesktopViewport)
    return (
      <OverlayModal
        open={open}
        onOpenChange={handleOpenChange}
        trigger={trigger}
        {...overlayModalProps}
      >
        {children}
      </OverlayModal>
    );

  return (
    <FullscreenModal
      open={open}
      trigger={trigger}
      onOpenChange={handleOpenChange}
      modal={false}
      contentStyleXArray={[styles.content]}
      showCloseButton={false}
      {...fullScreenModalProps}
    >
      {children}
    </FullscreenModal>
  );
};
