import { semanticColors } from "../../../../../global/stylex/vars.stylex";

export type ProgressCircleProps = {
  backgroundStroke?: string;
  circleRadius: number;
  halfSvgWidth: number;
  progress: number;
  stroke?: string;
};

export const ProgressCircle = ({
  halfSvgWidth,
  circleRadius,
  progress,
  backgroundStroke = semanticColors.onOverlayLow,
  stroke = semanticColors.onOverlayMedium,
}: ProgressCircleProps) => {
  /**
   * Note: we want the starting point to be the top of the circle instead of the positive x-axis intercept, so we always
   * subtract 90 degrees from every progress point to make that happen
   * @param {number} percentProgress 0-1
   * @returns {number} Theta around the unit circle in radians
   */
  const progressInRadians = (percentProgress: number): number => {
    // eslint-disable-next-line no-magic-numbers
    return (360 * percentProgress - 90) * (Math.PI / 180);
  };

  const progressEndpoint = (
    percentProgress: number,
  ): { x1: number; y1: number } => {
    if (percentProgress === 1) percentProgress = 0.999; // this fixes a weird mathematical bug with 100% progress

    const theta = progressInRadians(percentProgress);
    const x1 = halfSvgWidth + circleRadius * Math.cos(theta);
    const y1 = halfSvgWidth + circleRadius * Math.sin(theta);
    return {
      x1: x1,
      y1: y1,
    };
  };

  const { x1, y1 } = progressEndpoint(progress);
  const largeArcFlag: 0 | 1 = progress > 0.5 ? 1 : 0;

  const offset = halfSvgWidth - circleRadius;

  return (
    <>
      <circle
        cx={halfSvgWidth}
        cy={halfSvgWidth}
        r={circleRadius}
        stroke={backgroundStroke}
        strokeWidth="2"
      />
      <path
        d={`M${halfSvgWidth} ${offset}A${circleRadius} ${circleRadius} 0 ${largeArcFlag} 1 ${x1} ${y1}`}
        strokeWidth="2"
        strokeLinecap={"round"}
        stroke={stroke}
      />
    </>
  );
};
