"use client";

import * as stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
  zIndices,
} from "../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../types";
import { determineElementFromAsChild } from "../../../utils";
import { Text } from "../../_base";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElement: {
    backgroundColor: semanticColors.accentMedium,
    color: semanticColors.fixedLightenHighest,
    paddingBottom: spacing.s,
    paddingLeft: {
      [cssMediaMinWidthTablet]: spacing.xxl,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthTablet]: spacing.xxl,
      default: spacing.l,
    },
    paddingTop: spacing.s,
    position: "relative",
    zIndex: zIndices.menuBar,
  },
});

export type BannerProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
>;

export const Banner = ({
  asChild = false,
  children,
  styleXArray,
  ...props
}: BannerProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  return (
    <Text asChild type="title">
      <DeterminedElement
        {...props}
        {...stylex.props(styles.determinedElement, styleXArray)}
      >
        {children}
      </DeterminedElement>
    </Text>
  );
};
