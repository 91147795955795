import Bugsnag from "@bugsnag/js";
import type { z } from "zod";

export type ValidateLocalStorageSchemaProps<SchemaType> = {
  key: string;
  schema: z.ZodSchema<SchemaType>;
};

export const validateLocalStorageSchema = <SchemaType>({
  key,
  schema,
}: ValidateLocalStorageSchemaProps<SchemaType>) => {
  if (typeof localStorage !== "undefined") {
    let val = localStorage.getItem(key);
    try {
      val = JSON.parse(val);
    } catch (e) {
      Bugsnag.notify(`${e}: ${val}`);
      return null;
    }
    if (process.env.NODE_ENV === "production") {
      const safeParse = schema.safeParse(val);

      if (safeParse.error)
        Bugsnag.notify(
          `[@packages/sdk] ${safeParse.error.name}: ${safeParse.error.toString()}`,
        );

      return safeParse.data;
    } else {
      try {
        return schema.parse(val);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }
  return null;
};
