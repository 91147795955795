"use client";

import { SPEEDS } from "../lib";
import { usePlayer } from "./usePlayer";

export const usePlayerSpeedIndex = () => {
  const player = usePlayer();
  const currentSpeed = player?.speed ?? 1;
  const currentIdx = SPEEDS.findIndex((s) => Math.abs(currentSpeed - s) < 0.01);
  return { currentIdx, player };
};
