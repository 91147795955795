"use client";

import { useModal } from "@ebay/nice-modal-react";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

import type { HallowElement, HallowElementProps } from "../../../../types";
import type { ShareModalProps } from "../../../_base";
import { ShareModal } from "../../../_base";

const Element: HallowElement = "button";

export type ShareCampaignButtonProps = HallowElementProps<typeof Element> & {
  shareOptions: ShareModalProps;
};

export const ShareCampaignButton = forwardRef<any, ShareCampaignButtonProps>(
  ({ shareOptions, onClick, children, ...props }, ref) => {
    const shareModal = useModal(ShareModal);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (onClick) onClick(event);
      shareModal.show(shareOptions);
    };

    return (
      <button onClick={handleClick} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);

ShareCampaignButton.displayName = "ShareCampaignButton";
