"use client";

import type { Dispatch } from "react";
import { useRef, useState } from "react";

export type UseDebouncedStateProps<T = any> = {
  defaultValue?: T;
  timeout?: number;
};

export const useDebouncedState = <T = any>({
  defaultValue,
  timeout = 200,
}: UseDebouncedStateProps<T>) => {
  const timeoutRef = useRef<null | number>(null);
  const [state, setState] = useState<T>(defaultValue);

  const resetTimeout = () => window.clearTimeout(timeoutRef.current);

  const setStateDebounced = (newState: T) => {
    resetTimeout();
    timeoutRef.current = window.setTimeout(() => {
      setState(newState);
    }, timeout);
  };

  return [state, setStateDebounced] as [T, Dispatch<T>];
};
