import * as stylex from "@stylexjs/stylex";

import { stylesScreenReader } from "../../../../../global/stylex/styles";
import {
  colors,
  numericPercentages,
  numericPixels,
  numericValues,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { CheckboxIcon, ChevronUpIcon } from "../../../_base";
import { Text } from "../../../_base";
import { AccordionItem } from "../../../refactor";

const styles = stylex.create({
  accordionRow: {
    alignItems: "center",
    borderBottom: `solid 1px ${colors.grey30}`,
    display: "flex",
    flexDirection: "row",
    height: numericPixels[64],
    justifyContent: "space-between",
    width: numericPercentages[100],
  },
  checkboxContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "15px 30px 15px 3px",
  },
  description: {
    padding: spacing.ml,
  },
  disabled: {
    opacity: numericValues["0.5"],
  },
  label: {
    color: colors.purple60,
    flexShrink: 1,
    paddingLeft: spacing.ms,
    textWrap: "nowrap",
  },
  trigger: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    marginRight: spacing.ml,
  },
});

type ConsentCheckboxProps = {
  description: string;
  disabled?: boolean;
  id: string;
  isChecked: boolean;
  onCheck: (isChecked: boolean, value: number) => void;
  label: string;
  value: number;
};

export const ConsentCheckbox = ({
  description,
  disabled = false,
  id,
  isChecked,
  onCheck,
  label,
  value,
}: ConsentCheckboxProps) => {
  const toggleCheck = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled) onCheck(!isChecked, value);
  };

  return (
    <AccordionItem
      asChild={true}
      value={id}
      trigger={
        <div {...stylex.props(styles.accordionRow)}>
          <div
            {...stylex.props(
              styles.checkboxContainer,
              disabled && styles.disabled,
            )}
            onClick={(e) => toggleCheck(e)}
            // clicking this element checks the <input>, it does not trigger the AccordionItem
            data-disabled
          >
            <input {...stylex.props(stylesScreenReader.only)} id={id} />
            {isChecked ? (
              <CheckboxIcon.Checked fill={colors.purple60} size={40} />
            ) : (
              <CheckboxIcon.Unchecked fill={colors.purple60} size={40} />
            )}
            <Text asChild size="m" type="button">
              <label {...stylex.props(styles.label)} htmlFor={id}>
                {label}
              </label>
            </Text>
          </div>
          <div {...stylex.props(styles.trigger)}>
            <ChevronUpIcon />
          </div>
        </div>
      }
    >
      <div {...stylex.props(styles.description)}>{description}</div>
    </AccordionItem>
  );
};
