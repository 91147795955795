import * as stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    margin: "0 auto",
    maxWidth: "100vw",
    width: spacing.full,
  },
  maxWidths: {
    maxWidth: `min(100vw, ${spacing.maxWidthWithPaddingS})`,
  },
  maxWidthm: {
    maxWidth: `min(100vw, ${spacing.maxWidthWithPaddingM})`,
  },
  maxWidthl: {
    maxWidth: 1070,
  },
  minHeight: {
    minHeight: `calc(100vh - ${spacing.xxxl})`,
  },
  paddingX: {
    paddingLeft: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
  },
  paddingY: {
    paddingBottom: spacing.l,
    paddingTop: spacing.l,
  },
});

export type ContainerProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  maxWidth?: boolean;
  minHeight?: boolean;
  paddingX?: boolean;
  paddingY?: boolean;
  size?: "s" | "m" | "l";
};

export const Container = ({
  asChild = false,
  children,
  maxWidth = false,
  minHeight = false,
  paddingX = false,
  paddingY = false,
  size = "l",
  styleXArray,
  ...props
}: ContainerProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  return (
    <DeterminedElement
      {...props}
      {...stylex.props(
        styles.base,
        maxWidth ? styles[`maxWidth${size}`] : null,
        minHeight ? styles.minHeight : null,
        paddingX ? styles.paddingX : null,
        paddingY ? styles.paddingY : null,
        styleXArray,
      )}
    >
      {children}
    </DeterminedElement>
  );
};
