import { mockDataContent } from "./mockDataContent";

export const mockDataNextUp = {
  color_hex: mockDataContent.color,
  deeplink: "hallow://prayers/1601",
  description: "Section Next Up Description",
  image_url: mockDataContent.imgSrc.large,
  reason_label: "Section Next Up Tag",
  title: "Section Next Up Title",
};
