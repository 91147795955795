"use client";

import { useModal } from "@ebay/nice-modal-react";
import { useTranslations } from "@packages/i18n";
import type { BasePrayer, Images } from "@packages/sdk";
import {
  redirectToAuth,
  useRequestFavorite,
  useRequestPrayer,
  useSession,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  colors,
  spacing,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import { ThemeContainer } from "../../../../../theme";
import type { WithStylexArray } from "../../../../../types";
import { Theme } from "../../../../../types";
import { IconButton } from "../../../_core";
import { MoreIcon, ShareIcon, StarIcon } from "../../../icons";
import { DropdownMenu, DropdownMenuItem, ShareModal } from "../../../modals";
import ContentMoreMenuHeader from "./ContentMoreMenuHeader";

const styles = stylex.create({
  dropdownMenuContent: { paddingTop: spacing.l },
  modalZ: {
    zIndex: zIndices.modalOverQueue,
  },
  noBackground: {
    background: colors.transparent,
  },
  modalPadding: {
    paddingTop: spacing.l,
  },
});

export type MediaMoreMenuProps = WithStylexArray<{
  prayer: BasePrayer;
  imageSrc: Images;
}>;

export const MediaMoreMenu = ({
  prayer,
  imageSrc,
  styleXArray,
}: MediaMoreMenuProps) => {
  const { mutationPost: updateFavorite } = useRequestFavorite({
    id: prayer?.id,
  });
  const { query: prayerQuery } = useRequestPrayer({
    id: prayer?.id,
  });
  const { status } = useSession();
  const shareModal = useModal(ShareModal);
  const t = useTranslations();

  const onClickFavorite = () => {
    if (status === "authenticated") updateFavorite.mutate();
    else redirectToAuth();
  };

  return (
    <ThemeContainer
      theme={Theme.DARK}
      as="div"
      onClick={(e) => e.stopPropagation()}
      styleXArray={[styles.noBackground]}
    >
      <DropdownMenu
        trigger={
          <IconButton
            icon={<MoreIcon />}
            onClick={(e) => {
              // The stopImmediatePropagation call prevents next top loader from firing unnecessarily
              e.nativeEvent.stopImmediatePropagation();
              e.preventDefault();
            }}
            variant="transparentPrimary"
          />
        }
        align="end"
        contentStyleXArray={[
          styles.dropdownMenuContent,
          themes.dark,
          styles.modalZ,
          styleXArray,
        ]}
        modal={false}
      >
        <ContentMoreMenuHeader
          title={prayer?.title}
          subtitle={prayer?.label_desc}
          metadata={prayer?.lengths}
          imageSrc={imageSrc}
        />
        <DropdownMenuItem
          startIcon={
            prayerQuery.data?.is_favorite ? (
              <StarIcon.Off />
            ) : (
              <StarIcon.Outline />
            )
          }
          onClick={onClickFavorite}
        >
          {prayerQuery.data?.is_favorite
            ? t("dialog_prayer_options_unfavorite")
            : t("dialog_prayer_options_favorite")}
        </DropdownMenuItem>
        <DropdownMenuItem
          startIcon={<ShareIcon />}
          onClick={() =>
            shareModal.show({
              description: prayer?.label_desc,
              color: prayer?.images?.color_hex ?? "#000000",
              imgSrc:
                prayer?.images?.large ??
                prayer?.images?.medium ??
                prayer?.images?.small,
              link: prayer?.shareable_url,
              styleXArray: [themes.dark, styles.modalZ, styles.modalPadding],
              title: prayer?.title,
            })
          }
        >
          {t("dialog_prayer_options_share")}
        </DropdownMenuItem>
      </DropdownMenu>
    </ThemeContainer>
  );
};
