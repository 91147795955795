import type { MediaSizes } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";

import {
  numericPercentages,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { CollapseButton } from "../../controls";

// we put a simple z-index here because the layer context of this button is INSIDE the fullscreen-modal,
// so we only need it on top of its parent
const collapseHeaderStyles = stylex.create({
  "collapsed-desktop": {
    display: "none",
  },
  "collapsed-mobile": {
    display: "none",
  },
  "expanded-desktop": {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gridArea: "header",
    justifyContent: "flex-end",
    zIndex: 1,
  },
  "expanded-mobile": {
    display: "flex",
    gridColumn: "collapse",
    gridRowEnd: "media-start",
    gridRowStart: "collapse-start",
    padding: spacing.l,
    placeContent: "flex-end",
    placeItems: "center",
    width: numericPercentages[100],
    zIndex: 1,
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {
    display: "none",
  },
  "hidden-mobile": {
    display: "none",
  },
});

export type MediaHeaderProps = {
  size: MediaSizes;
  onCollapse: MouseEventHandler<HTMLButtonElement>;
};

export const MediaHeader = ({ size, onCollapse }: MediaHeaderProps) => {
  return (
    <div key={"mediaHeader"} {...stylex.props(collapseHeaderStyles[size])}>
      <CollapseButton onClick={onCollapse} />
    </div>
  );
};
