"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useState } from "react";
import { useFocus, useHover, usePress } from "react-aria";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { Thumbnail } from "../../../../blocks";
import { Text } from "../../../_core";
import { InlineIcon, SubscriptionIcon } from "../../../icons";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  determinedElementActive: {
    opacity: {
      default: 1,
      ":active": 0.5,
    },
  },
  determinedElement: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
    paddingRight: spacing.m,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.xs,
    width: spacing.none,
  },
  img: {
    borderRadius: radius.s,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
  },
  metadata: {
    color: semanticColors.neutralsMedium,
  },
  rightActions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
});

export type ContentSmallProps = WithAsChild<
  WithStylexArray<Omit<HallowElementProps<typeof hallowElement>, "disabled">>
> & {
  activeState?: boolean;
  description?: string;
  focusState?: boolean;
  hoverState?: boolean;
  imgOverlay?: JSX.Element;
  imgSrc?: Images;
  metadata?: string;
  rightActions?: JSX.Element[];
  subscription?: boolean;
  title?: string;
  disabled?: boolean;
  size?: "s" | "xs";
};

export const ContentSmall = forwardRef<any, ContentSmallProps>(
  (
    {
      activeState = true,
      asChild = false,
      children,
      description,
      focusState = true,
      hoverState = true,
      imgOverlay,
      imgSrc,
      metadata,
      rightActions,
      subscription,
      styleXArray,
      title,
      disabled,
      size = "s",
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    const { isPressed: active, pressProps: activeProps } = usePress({});
    const { focusProps } = useFocus({
      onFocusChange: (focus) => setFocus(focus),
    });
    const { isHovered: hover, hoverProps } = useHover({});

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <Thumbnail
          title={title}
          key="img"
          imageSrc={imgSrc}
          imageOverlay={imgOverlay}
          size={size}
          styleXArray={[
            styles.img,
            stylesOutline.base,
            focus && focusState ? stylesOutline.focusControlled : null,
            hover && hoverState ? stylesOutline.hoverControlled : null,
          ]}
        />,
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          <div {...stylex.props(styles.textContainer)}>
            <div {...stylex.props(styles.titleContainer)}>
              {subscription && <InlineIcon icon={<SubscriptionIcon.Small />} />}
              <Text overflow="ellipsis" size="l" type="title">
                {title}
              </Text>
            </div>
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          </div>
          {metadata && (
            <Text
              overflow="ellipsis"
              size="l"
              styleXArray={[styles.metadata]}
              type="detail"
            >
              {metadata}
            </Text>
          )}
        </div>,
        rightActions?.length > 0 ? (
          <div key="rightActions" {...stylex.props(styles.rightActions)}>
            {rightActions}
          </div>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...(disabled ? {} : activeProps)}
        {...(disabled ? {} : focusProps)}
        {...(disabled ? {} : hoverProps)}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          !disabled && activeState ? stylesOpacity.active : null,
          !disabled && activeState ? stylesOpacity.base : null,
          !disabled && activeState && hoverState ? stylesScale.base : null,
          !disabled && activeState && hoverState ? stylesScale.l : null,
          styleXArray,
        )}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ContentSmall.displayName = "ContentSmall";
