import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem } from "@packages/media";

import { LengthIcon, ModalTab } from "../../../../../components";

export const SelectLengthTab = () => {
  const t = useTranslations();
  const { currentItem } = useCurrentQueueItem();

  return (
    <ModalTab
      key={"lengthSelectMenuTab"}
      value={"lengthSelectMenu"}
      startIcon={<LengthIcon />}
      label={t("track_settings_length")}
    >
      {currentItem.selected_audio.length}
    </ModalTab>
  );
};
