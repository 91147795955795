"use client";

import * as stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../../global/stylex/vars.stylex";
import type { CarouselApi } from "../../../../../../hooks";
import { useCarousel } from "../../../../../../hooks";
import type { CarouselProps } from "../../../../../blocks";
import { Carousel } from "../../../../../blocks";

const styles = stylex.create({
  paddingBottom: {
    paddingBottom: spacing.m,
  },
  paddingTop: {
    paddingTop: spacing.m,
  },
});

export type SectionCarouselProps = CarouselProps & {
  size?: CarouselApi["size"];
};

export const SectionCarousel = ({
  children,
  size,
  ...props
}: SectionCarouselProps) => {
  const carouselApi = useCarousel({ size });

  return (
    <Carousel
      carouselApi={carouselApi}
      containerStylexArray={[styles.paddingBottom, styles.paddingTop]}
      {...props}
    >
      {children}
    </Carousel>
  );
};
