import { createTheme } from "@stylexjs/stylex";

import type { Theme } from "../../src";
import {
  colors,
  constantSemanticColorsRaw,
  semanticColors,
  shades,
  tints,
} from "./vars.stylex";

export const matchMode = createTheme(semanticColors, semanticColors);

export const darkMode = createTheme(semanticColors, {
  accentHigh: colors.purple80,
  accentHigher: colors.purple90,
  accentHighest: colors.purple99,
  accentLow: colors.purple40,
  accentLower: colors.purple30,
  accentLowest: colors.purple10,
  accentMedHigh: colors.purple70,
  accentMedLow: colors.purple50,
  accentMedium: colors.purple60,
  accentVeryHigh: colors.purple95,
  accentVeryLow: colors.purple20,
  background: colors.blackOLED,
  errorError: colors.red60,
  errorErrorContainer: colors.red20,
  errorOnError: colors.black,
  errorOnErrorContainer: colors.red80,
  higherContrastHigh: tints.tint60,
  higherContrastHigher: tints.tint70,
  higherContrastHighest: tints.tint90,
  higherContrastLow: tints.tint20,
  higherContrastLower: tints.tint15,
  higherContrastLowest: tints.tint5,
  higherContrastMed: tints.tint40,
  higherContrastMedHigh: tints.tint50,
  higherContrastMedLow: tints.tint30,
  higherContrastVeryHigh: tints.tint80,
  higherContrastVeryLow: tints.tint10,
  lowerContrastHigh: shades.shade60,
  lowerContrastHigher: shades.shade70,
  lowerContrastHighest: shades.shade90,
  lowerContrastLow: shades.shade20,
  lowerContrastLower: shades.shade15,
  lowerContrastLowest: shades.shade5,
  lowerContrastMed: shades.shade40,
  lowerContrastMedHigh: shades.shade50,
  lowerContrastMedLow: shades.shade30,
  lowerContrastVeryHigh: shades.shade80,
  lowerContrastVeryLow: shades.shade10,
  neutralsHigh: colors.grey80,
  neutralsHigher: colors.grey90,
  neutralsHighest: colors.grey99,
  neutralsLow: colors.grey40,
  neutralsLower: colors.grey30,
  neutralsLowest: colors.grey10,
  neutralsMedHigh: colors.grey70,
  neutralsMedLow: colors.grey50,
  neutralsMedium: colors.grey60,
  neutralsVeryHigh: colors.grey95,
  neutralsVeryLow: colors.grey20,
  onOverlayBackground: colors.grey20,
  onOverlayHighest: colors.white,
  onOverlayLow: colors.grey30,
  onOverlayLowest: colors.grey20,
  onOverlayMedHigh: colors.grey99,
  onOverlayMedLow: colors.grey50,
  onOverlayMedium: colors.grey80,
  primary: colors.white,
  secondary: colors.black,
  successOnSuccess: colors.black,
  successOnSuccessContainer: colors.green80,
  successSuccess: colors.green60,
  successSuccessContainer: colors.green20,
  // NOTE: All variables in a variable group must be overridden when creating a theme.
  // This choice has been to help catch accidental omissions. Even at the cost of occasional tedium.
  // https://stylexjs.com/docs/learn/theming/creating-themes
  ...constantSemanticColorsRaw,
});

export const lightMode = createTheme(semanticColors, {
  accentHigh: colors.purple40,
  accentHigher: colors.purple30,
  accentHighest: colors.purple10,
  accentLow: colors.purple80,
  accentLower: colors.purple90,
  accentLowest: colors.purple99,
  accentMedHigh: colors.purple50,
  accentMedLow: colors.purple70,
  accentMedium: colors.purple60,
  accentVeryHigh: colors.purple20,
  accentVeryLow: colors.purple95,
  background: colors.white,
  errorError: colors.red60,
  errorErrorContainer: colors.red95,
  errorOnError: colors.white,
  errorOnErrorContainer: colors.red40,
  higherContrastHigh: shades.shade60,
  higherContrastHigher: shades.shade70,
  higherContrastHighest: shades.shade90,
  higherContrastLow: shades.shade20,
  higherContrastLower: shades.shade15,
  higherContrastLowest: shades.shade5,
  higherContrastMed: shades.shade40,
  higherContrastMedHigh: shades.shade50,
  higherContrastMedLow: shades.shade30,
  higherContrastVeryHigh: shades.shade80,
  higherContrastVeryLow: shades.shade10,
  lowerContrastHigh: tints.tint60,
  lowerContrastHigher: tints.tint70,
  lowerContrastHighest: tints.tint90,
  lowerContrastLow: tints.tint20,
  lowerContrastLower: tints.tint15,
  lowerContrastLowest: tints.tint5,
  lowerContrastMed: tints.tint40,
  lowerContrastMedHigh: tints.tint50,
  lowerContrastMedLow: tints.tint30,
  lowerContrastVeryHigh: tints.tint80,
  lowerContrastVeryLow: tints.tint10,
  neutralsHigh: colors.grey40,
  neutralsHigher: colors.grey30,
  neutralsHighest: colors.grey10,
  neutralsLow: colors.grey80,
  neutralsLower: colors.grey90,
  neutralsLowest: colors.grey99,
  neutralsMedHigh: colors.grey50,
  neutralsMedLow: colors.grey70,
  neutralsMedium: colors.grey60,
  neutralsVeryHigh: colors.grey20,
  neutralsVeryLow: colors.grey95,
  onOverlayBackground: colors.white,
  onOverlayHighest: colors.black,
  onOverlayLow: colors.grey99,
  onOverlayLowest: colors.white,
  onOverlayMedHigh: colors.grey30,
  onOverlayMedLow: colors.grey90,
  onOverlayMedium: colors.grey60,
  primary: colors.black,
  secondary: colors.white,
  successOnSuccess: colors.white,
  successOnSuccessContainer: colors.green40,
  successSuccess: colors.green60,
  successSuccessContainer: colors.green95,
  ...constantSemanticColorsRaw,
});

export const themes: Record<
  Theme,
  typeof darkMode | typeof lightMode | typeof matchMode
> = {
  dark: darkMode,
  light: lightMode,
  match: matchMode,
};
