import * as stylex from "@stylexjs/stylex";
import { forwardRef, Fragment } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { ChevronRightIcon } from "../../icons";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: semanticColors.background,
      ":hover": semanticColors.neutralsLowest,
      ":focus": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderRadius: radius.m,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    justifyContent: "space-between",
    opacity: {
      default: numericValues[1],
      ":focus": numericValues[0.6],
      ":active": numericValues[0.6],
    },
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
    width: numericPercentages[100],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    width: spacing.t,
  },
  disabled: {
    backgroundColor: semanticColors.background,
    cursor: "not-allowed",
    opacity: numericValues[0.5],
  },
  image: {
    borderRadius: radius.full,
    height: spacing.xxxl,
    objectFit: "cover",
    width: spacing.xxxl,
  },
});

export type ListCategoryProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  description?: string;
  disabled?: boolean;
  icon?: IconElement;
  imageSrc?: string;
  title: string;
};

export const ListCategory = forwardRef<any, ListCategoryProps>(
  (
    {
      asChild = false,
      children,
      description,
      disabled,
      icon = <ChevronRightIcon />,
      imageSrc,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <img
          alt={description}
          key="img"
          src={imageSrc}
          {...stylex.props(styles.image)}
        />,
        <span key="content" {...stylex.props(styles.content)}>
          <Text overflow="ellipsis" type="title">
            {title}
          </Text>
          {description && (
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          )}
        </span>,
        <Fragment key="icon">{icon}</Fragment>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...stylex.props(
          styles.base,
          disabled ? styles.disabled : null,
          styleXArray,
        )}
        {...{ disabled, ...props }}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListCategory.displayName = "ListCategory";
