import { useTranslations } from "@packages/i18n";

import {
  ModalDropdownMenuHeader,
  ModalTabContent,
} from "../../../../../components";
import { SelectGuideContent } from "./SelectGuideContent";

export const SelectGuideTabContent = () => {
  const t = useTranslations();

  return (
    <ModalTabContent
      value={"guideSelectMenu"}
      key={"guideSelectMenu"}
      header={
        <ModalDropdownMenuHeader>
          {t("track_settings_guide")}
        </ModalDropdownMenuHeader>
      }
    >
      <SelectGuideContent />
    </ModalTabContent>
  );
};
